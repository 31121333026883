// src/popups/PopupAddFeed.js

import React, { useState, useRef, useEffect } from 'react'; 
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import Select from 'react-select';
import styles from './css/PopupAddFeed.module.css';
import {
  FaSpinner,
  FaCheckCircle,
  FaExclamationCircle,
  FaTimesCircle,
  FaInfoCircle,
  FaExclamationTriangle,
  FaLink,
  FaUsers,
  FaDollarSign,
} from 'react-icons/fa';

const PopupAddFeed = ({ token, onClose, onFeedCreated, existingFeedData = null }) => {
  // Determine if we are in edit mode
  const isEditing = existingFeedData !== null;

  // State for Feed Name
  const [feedName, setFeedName] = useState(existingFeedData?.client_name || '');
  const [feedNameError, setFeedNameError] = useState('');
  const [feedSuccessMessage, setFeedSuccessMessage] = useState('');
  const [isCheckingFeedName, setIsCheckingFeedName] = useState(false);
  const [isFeedNameValid, setIsFeedNameValid] = useState(isEditing ? true : false); // Assume valid in edit mode
  const [creditBalance, setCreditBalance] = useState(existingFeedData?.credit_balance?.toString() || '');
  const [creditBalanceError, setCreditBalanceError] = useState('');

  const [isEditModeValidating, setIsEditModeValidating] = useState(false);

  // State for Data Type Selection
  const [selectedOption, setSelectedOption] = useState(existingFeedData?.data_type || '');
  const [selectedPim, setSelectedPim] = useState(existingFeedData?.pim_system || '');

  // New States for User Assignment
  const [availableUsers, setAvailableUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(
    existingFeedData?.assigned_users
      ? existingFeedData.assigned_users.map(user => ({ value: user, label: user }))
      : []
  );
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [assignError, setAssignError] = useState('');
  const [assignSuccess, setAssignSuccess] = useState('');

  // State for File and Link
  const [file, setFile] = useState(null);
  const [link, setLink] = useState(existingFeedData?.data_link || '');

  // State for Mapping File (if needed)
  const [mappingFile, setMappingFile] = useState(null);
  const [mappingFileError, setMappingFileError] = useState('');

  // Submission States
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isMappingUploading, setIsMappingUploading] = useState(false);
  const [generalError, setGeneralError] = useState('');

  // Data Provision States
  const [dataSuccessMessage, setDataSuccessMessage] = useState('');
  const [dataErrorMessage, setDataErrorMessage] = useState('');
  const [idColumnSuccessMessage, setIdColumnSuccessMessage] = useState('');
  const [idColumnErrorMessage, setIdColumnErrorMessage] = useState('');

  const [isDataProvided, setIsDataProvided] = useState(isEditing ? true : false);
  const [isCheckingLink, setIsCheckingLink] = useState(false);

  const [isFileUploaded, setIsFileUploaded] = useState(isEditing ? true : false); // Tracks if file/link is uploaded

  // State for XML Tag
  const [tag, setTag] = useState(existingFeedData?.xml_item_tag || 'item'); // Default for XML

  // States for CSV-specific Inputs
  const [headerRow, setHeaderRow] = useState(existingFeedData?.header_row || 1);
  const [idColumn, setIdColumn] = useState(existingFeedData?.id_column || '');

  // States for Unique Columns/Sub-tags
  const [uniqueSubTags, setUniqueSubTags] = useState(existingFeedData?.unique_sub_tags || []);
  const [uniqueColumns, setUniqueColumns] = useState(existingFeedData?.unique_columns || []);
  const [uniqueFields, setUniqueFields] = useState(existingFeedData?.unique_fields || []);
  const [isJSONValid, setIsJSONValid] = useState(isEditing ? true : false);

  // Mappings and Missing Fields
  const [mappings, setMappings] = useState(existingFeedData?.mappings || {});

  // New State to Handle Duplicate IDs
  const [duplicateCount, setDuplicateCount] = useState(existingFeedData?.duplicate_count || 0);

  // New State for CSV Counts
  const [uniqueIds, setUniqueIds] = useState(existingFeedData?.unique_ids || 0);
  const [totalIds, setTotalIds] = useState(existingFeedData?.total_ids || 0);

  // Validation States
  const [isXMLValid, setIsXMLValid] = useState(isEditing ? true : false);
  const [isCSVValid, setIsCSVValid] = useState(isEditing ? true : false);

  // New State to Track Data Provider ('file' or 'link')
  const [dataProvider, setDataProvider] = useState(existingFeedData?.file_or_link_provided || null); // 'file' or 'link'

  // New State for XML and JSON ID Field
  const [idField, setIdField] = useState(existingFeedData?.id_field || '');
  const [idFieldSuccessMessage, setIdFieldSuccessMessage] = useState('');
  const [idFieldErrorMessage, setIdFieldErrorMessage] = useState('');

  // Refs for File Inputs
  const fileInputRef = useRef(null);
  const mappingFileInputRef = useRef(null);

  // Predefined Fields to Map
  const predefinedFields = [
    'item_group_id',
    'id',
    'title',
    'description',
    'product_type',
    'brand',
    'color',
    'gender',
    'pattern',
    'material',
    'age_group',
    'size',
    'product_detail',
    'google_product_category',
    'product_highlight',
    'image_link',
    'additional_image_link',
    'lifestyle_image_link',
  ];

  // Field Descriptions for Tooltips
  const fieldDescriptions = {
    item_group_id: 'The ID for grouping items with similar variants.',
    id: 'Unique identifier for the item.',
    title: 'Title of the item.',
    description: 'Detailed description of the item.',
    product_type: 'Category of the product.',
    brand: 'Brand name of the item.',
    color: 'Color of the item.',
    gender: 'Intended gender audience for the item.',
    pattern: 'Pattern or design of the item.',
    material: 'Material composition of the item.',
    age_group: 'Intended age group for the item.',
    size: 'Size of the item.',
    product_detail: 'Additional product details.',
    google_product_category: 'Google’s predefined category for the product.',
    product_highlight: 'Key highlights of the product.',
    image_link: 'URL of the main image.',
    additional_image_link: 'URLs of additional images.',
    lifestyle_image_link: 'URLs of lifestyle images.',
  };

  // Debounce for Feed Name Validation
  const validationTimeout = useRef(null);

  const isValidClientName = (name) => {
    const pattern = /^[a-z0-9_\-@]+$/; // Case-insensitive
    return pattern.test(name);
  };

  // **New State Added:** dataTypeChanged Flag
  const [dataTypeChanged, setDataTypeChanged] = useState(false);

  // **New State Added:** hasFetchedEditData to prevent multiple fetches
  const [hasFetchedEditData, setHasFetchedEditData] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoadingUsers(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/users`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        // Assuming response.data is an array of user objects with 'username'
        setAvailableUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
        setAssignError('Failed to fetch users for assignment.');
      } finally {
        setIsLoadingUsers(false);
      }
    };

    fetchUsers();
  }, [token]);

  useEffect(() => {
    if (isEditing) {
      // If editing, assume feed name is valid
      setIsFeedNameValid(true);
      setFeedNameError('');
      setFeedSuccessMessage('Editing existing feed.');
    } else {
      // If creating, validate feed name
      if (!feedName.trim()) {
        setFeedNameError('Please enter a feed name.');
        setIsFeedNameValid(false);
        setFeedSuccessMessage('');
        return;
      }

      // Frontend validation for allowed characters and no spaces
      if (!isValidClientName(feedName)) {
        setFeedNameError('Only lowercase letters, numbers, underscores, hyphens, and "@" are allowed. No spaces are permitted.');
        setIsFeedNameValid(false);
        setFeedSuccessMessage('');
        return;
      }

      // Clear existing timer
      if (validationTimeout.current) {
        clearTimeout(validationTimeout.current);
      }

      // Set new debounce timer
      validationTimeout.current = setTimeout(() => {
        validateFeedName(feedName);
      }, 500); // 500ms debounce

      // Cleanup on unmount or feedName change
      return () => {
        if (validationTimeout.current) {
          clearTimeout(validationTimeout.current);
        }
      };
    }
  }, [feedName, isEditing]);

  const isMappingCompleted = () => {
    if (
      selectedOption === 'XML' &&
      isXMLValid &&
      idField.trim() !== ''
    ) {
      return true;
    }
    if (
      (selectedOption === 'CSV' || selectedOption === 'GoogleSheets') &&
      isCSVValid &&
      idColumn.trim() !== ''
    ) {
      return true;
    }
    if (
      selectedOption === 'JSON' &&
      isJSONValid &&
      idField.trim() !== ''
    ) {
      return true;
    }
    if (
      selectedOption === 'PIM' &&
      selectedPim.trim() !== ''
    ) {
      return true;
    }
    return false;
  };

  const validateFeedName = async (name) => {
    setIsCheckingFeedName(true);
    setFeedNameError('');
    setFeedSuccessMessage('');
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/check-client-name`,
        { clientName: name },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.isTaken && !isEditing) {
        setFeedNameError('This feed name is already taken.');
        setIsFeedNameValid(false);
        setFeedSuccessMessage('');
      } else {
        setFeedSuccessMessage('Feed name is available!');
        setIsFeedNameValid(true);
      }
    } catch (error) {
      // Display specific error message from backend if available
      if (error.response && error.response.data && error.response.data.message) {
        setFeedNameError(error.response.data.message);
      } else {
        setFeedNameError('Error checking feed name.');
      }
      console.error('Error checking feed name:', error);
      setIsFeedNameValid(false);
      setFeedSuccessMessage('');
    } finally {
      setIsCheckingFeedName(false);
    }
  };

  const validateJSON = async (jsonLink, idFieldName) => {
    if (!jsonLink || !idFieldName) {
      setIdFieldErrorMessage('Link and ID field are required for JSON validation.');
      setIsJSONValid(false);
      setIsDataProvided(false);
      return;
    }
  
    setIsCheckingLink(true);
    setIdFieldErrorMessage('');
    setIdFieldSuccessMessage('');
    setMappings({}); // Reset mappings
    setDuplicateCount(0); // Reset duplicate count
    setUniqueIds(0); // Reset unique IDs
    setTotalIds(0); // Reset total IDs
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/check-json`,
        { link: jsonLink, idField: idFieldName },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      if (response.data.isValidJSON) {
        const count = response.data.itemCount;
        const fields = response.data.uniqueFields || [];
        setUniqueFields(fields); // Update uniqueFields if needed
        setIdFieldSuccessMessage(`Valid JSON. Found ${count} items.`);
        setIsDataProvided(true);
        setIsJSONValid(true); // Mark JSON as valid
        setIsFileUploaded(true); // Set isFileUploaded to true upon successful JSON validation
  
        // Set duplicate counts
        setDuplicateCount(response.data.duplicateCount || 0);
        setUniqueIds(response.data.uniqueIds || 0);
        setTotalIds(response.data.totalIds || 0);
  
        // Initialize mappings with auto-mapped fields
        const initialMappings = { ...mappings };
        predefinedFields.forEach((field) => {
          // Auto-map if there's an exact match (case-insensitive)
          const matchedField = fields.find(
            (f) => f.toLowerCase() === field.toLowerCase()
          );
          // Preserve existing idField selection in mappings
          if (isEditing && field === 'id') {
            initialMappings[field] = idField || matchedField || '';
          } else {
            initialMappings[field] = matchedField || '';
          }
        });
        setMappings(initialMappings);
      } else {
        setIdFieldErrorMessage(response.data.message || 'The JSON is not valid.');
        setIsDataProvided(false);
        setIsJSONValid(false);
        setIsFileUploaded(false); // Ensure isFileUploaded is false on failed validation
      }
    } catch (error) {
      setIdFieldErrorMessage(
        error.response?.data?.message || 'An error occurred while checking the JSON link.'
      );
      setIsDataProvided(false);
      setIsJSONValid(false);
      setIsFileUploaded(false); // Ensure isFileUploaded is false on error
      console.error('Error validating JSON:', error);
    } finally {
      setIsCheckingLink(false);
    }
  };
  
  const validateXML = async (xmlLink, xmlTag, idFieldName) => {
    if (!xmlLink || !xmlTag || !idFieldName) {
      setIdFieldErrorMessage('Link, tag, and ID field are required for XML validation.');
      setIsXMLValid(false);
      setIsDataProvided(false);
      return;
    }
  
    setIsCheckingLink(true);
    setIdFieldErrorMessage('');
    setIdFieldSuccessMessage('');
    setMappings({}); // Reset mappings
    setDuplicateCount(0); // Reset duplicate count
    setUniqueIds(0); // Reset unique IDs
    setTotalIds(0); // Reset total IDs
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/check-xml`,
        { link: xmlLink, tag: xmlTag, idField: idFieldName },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      if (response.data.isValidXML) {
        const count = response.data.tagCount;
        const subTags = response.data.uniqueSubTags || [];
        setUniqueSubTags(subTags); // Update uniqueSubTags if needed
        setIdFieldSuccessMessage(`Valid XML. Found ${count} <${xmlTag}> tags.`);
        setIsDataProvided(true);
        setIsXMLValid(true); // Mark XML as valid
        setIsFileUploaded(true); // Set isFileUploaded to true upon successful XML validation
  
        // Set duplicate counts if editing
        if (!isEditing) {
          setDuplicateCount(response.data.duplicateCount || 0);
          setUniqueIds(response.data.uniqueIds || 0);
          setTotalIds(response.data.totalIds || 0);
        }
  
        // Initialize mappings with auto-mapped fields
        const initialMappings = { ...mappings };
        predefinedFields.forEach((field) => {
          // Auto-map if there's an exact match (case-insensitive)
          const matchedSubTag = subTags.find(
            (subTag) => subTag.toLowerCase() === field.toLowerCase()
          );
          // Preserve existing idField selection in mappings
          if (isEditing && field === 'id') {
            initialMappings[field] = idField || matchedSubTag || '';
          } else {
            initialMappings[field] = matchedSubTag || '';
          }
        });
        setMappings(initialMappings);
      } else {
        setIdFieldErrorMessage(response.data.message || 'The XML is not valid.');
        setIsDataProvided(false);
        setIsXMLValid(false);
        setIsFileUploaded(false); // Ensure isFileUploaded is false on failed validation
      }
    } catch (error) {
      setIdFieldErrorMessage(
        error.response?.data?.message || 'An error occurred while checking the XML link.'
      );
      setIsDataProvided(false);
      setIsXMLValid(false);
      setIsFileUploaded(false); // Ensure isFileUploaded is false on error
      console.error('Error validating XML:', error);
    } finally {
      setIsCheckingLink(false);
    }
  };

  const validateCSV = async (csvLink, idColumnName, headerRowNumber) => {
    if (!csvLink || !idColumnName || !headerRowNumber) {
      setIdColumnErrorMessage('Link, ID column, and header row are required for CSV validation.');
      setIsCSVValid(false);
      setIsDataProvided(false);
      return;
    }

    setIsCheckingLink(true);
    setIdColumnErrorMessage('');
    setIdColumnSuccessMessage('');
    setMappings({}); // Reset mappings
    setDuplicateCount(0); // Reset duplicate count
    setUniqueIds(0); // Reset unique IDs
    setTotalIds(0); // Reset total IDs

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/check-csv`,
        { link: csvLink, idColumn: idColumnName, headerRow: headerRowNumber },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.isValidCSV) {
        const count = response.data.rowCount;
        const columns = response.data.uniqueColumns || [];
        setUniqueColumns(columns); // Update uniqueColumns if needed
        setIdColumnSuccessMessage(`Valid CSV. Found ${count} rows.`);
        setIsDataProvided(true);
        setIsCSVValid(true); // Mark CSV as valid
        setIsFileUploaded(true); // Set isFileUploaded to true upon successful CSV validation

        // Set duplicate counts
        setDuplicateCount(response.data.duplicateCount || 0);
        setUniqueIds(response.data.uniqueIds || 0);
        setTotalIds(response.data.totalIds || 0);

        // Initialize mappings with auto-mapped fields
        const initialMappings = { ...mappings };
        predefinedFields.forEach((field) => {
          // Auto-map if there's an exact match (case-insensitive)
          const matchedColumn = columns.find(
            (col) => col.toLowerCase() === field.toLowerCase()
          );
          // Preserve existing idColumn selection in mappings
          if (isEditing && field === 'id') {
            initialMappings[field] = idColumn || matchedColumn || '';
          } else {
            initialMappings[field] = matchedColumn || '';
          }
        });
        setMappings(initialMappings);
      } else {
        setIdColumnErrorMessage(response.data.message || 'The CSV is not valid.');
        setIsDataProvided(false);
        setIsCSVValid(false);
        setIsFileUploaded(false); // Ensure isFileUploaded is false on failed validation
      }
    } catch (error) {
      setIdColumnErrorMessage(
        error.response?.data?.message || 'An error occurred while checking the CSV link.'
      );
      setIsDataProvided(false);
      setIsCSVValid(false);
      setIsFileUploaded(false); // Ensure isFileUploaded is false on error
      console.error('Error validating CSV:', error);
    } finally {
      setIsCheckingLink(false);
    }
  };

  const extractCSVColumns = async (csvLink, headerRowNumber) => {
    if (!csvLink) {
      setDataErrorMessage('Link is required for extracting CSV columns.');
      setIsDataProvided(false);
      return;
    }

    setIsCheckingLink(true);
    setDataErrorMessage('');
    setDataSuccessMessage('');
    setUniqueColumns([]); // Reset unique columns
    setIdColumn(''); // Reset ID column selection
    setIsCSVValid(false); // Reset CSV validation state
    setDuplicateCount(0); // Reset duplicate count
    setUniqueIds(0); // Reset unique IDs
    setTotalIds(0); // Reset total IDs

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/extract-csv-columns`,
        { link: csvLink, headerRow: headerRowNumber },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.isExtracted) {
        const columns = response.data.uniqueColumns || [];
        setUniqueColumns(columns);
        setDataSuccessMessage('Columns extracted successfully. Please select the ID column.');
        setIsDataProvided(true);
        setIsCSVValid(false); // Will be set to true after validation
        setIsFileUploaded(true); // Set isFileUploaded to true upon successful extraction

        // Initialize mappings with auto-mapped fields
        const initialMappings = { ...mappings };
        predefinedFields.forEach((field) => {
          // Auto-map if there's an exact match (case-insensitive)
          const matchedColumn = columns.find(
            (column) => column.toLowerCase() === field.toLowerCase()
          );
          // Preserve existing idColumn selection in mappings
          if (isEditing && field === 'id') {
            initialMappings[field] = idColumn || matchedColumn || '';
          } else {
            initialMappings[field] = matchedColumn || '';
          }
        });
        setMappings(initialMappings);
      } else {
        setDataErrorMessage(response.data.message || 'Failed to extract columns.');
        setIsDataProvided(false);
        setIsFileUploaded(false); // Ensure isFileUploaded is false on failed extraction
      }
    } catch (error) {
      setDataErrorMessage(
        error.response?.data?.message || 'An error occurred while extracting columns.'
      );
      setIsDataProvided(false);
      setIsCSVValid(false); // Ensure isCSVValid is false on error
      setIsFileUploaded(false); // Ensure isFileUploaded is false on error
      console.error('Error extracting CSV columns:', error);
    } finally {
      setIsCheckingLink(false);
    }
  };

  const extractJSONFields = async (jsonLink) => {
    if (!jsonLink) {
      setDataErrorMessage('Link is required for JSON field extraction.');
      setIsDataProvided(false);
      return;
    }
  
    setIsCheckingLink(true);
    setDataErrorMessage('');
    setDataSuccessMessage('');
    setUniqueFields([]); // Reset unique fields
    setMappings({}); // Reset mappings
    setDuplicateCount(0); // Reset duplicate count
    setUniqueIds(0); // Reset unique IDs
    setTotalIds(0); // Reset total IDs
    setIdField(''); // Reset ID field
    setIdFieldSuccessMessage('');
    setIdFieldErrorMessage('');
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/extract-json-fields`,
        { link: jsonLink },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      if (response.data.isValidJSON) {
        const fields = response.data.uniqueFields || [];
        setUniqueFields(fields);
        setDataSuccessMessage('JSON fields extracted successfully.');
        setIsDataProvided(true);
        setIsJSONValid(false); // Will be set to true after validation
        setIsFileUploaded(true); // Set isFileUploaded to true after extraction
  
        // Set duplicate counts if editing
        if (!isEditing) {
          setDuplicateCount(response.data.duplicateCount || 0);
          setUniqueIds(response.data.uniqueIds || 0);
          setTotalIds(response.data.totalIds || 0);
        }
  
        // Initialize mappings with auto-mapped fields
        const initialMappings = { ...mappings };
        predefinedFields.forEach((field) => {
          // Auto-map if there's an exact match (case-insensitive)
          const matchedField = fields.find(
            (f) => f.toLowerCase() === field.toLowerCase()
          );
          // Preserve existing idField selection in mappings
          if (isEditing && field === 'id') {
            initialMappings[field] = idField || matchedField || '';
          } else {
            initialMappings[field] = matchedField || '';
          }
        });
        setMappings(initialMappings);
      } else {
        setDataErrorMessage(response.data.message || 'Failed to extract JSON fields.');
        setIsDataProvided(false);
        setIsJSONValid(false);
        setIsFileUploaded(false); // Ensure isFileUploaded is false on failed extraction
      }
    } catch (error) {
      setDataErrorMessage(
        error.response?.data?.message || 'An error occurred while extracting JSON fields.'
      );
      setIsDataProvided(false);
      setIsJSONValid(false);
      setIsFileUploaded(false); // Ensure isFileUploaded is false on error
      console.error('Error extracting JSON fields:', error);
    } finally {
      setIsCheckingLink(false);
    }
  };
  
  const extractXMLSubTags = async (xmlLink, xmlTag) => {
    if (!xmlLink || !xmlTag) {
      setDataErrorMessage('Link and tag are required for XML sub-tag extraction.');
      setIsDataProvided(false);
      return;
    }
  
    setIsCheckingLink(true);
    setDataErrorMessage('');
    setDataSuccessMessage('');
    setUniqueSubTags([]); // Reset unique sub-tags
    setMappings({}); // Reset mappings
    setDuplicateCount(0); // Reset duplicate count
    setUniqueIds(0); // Reset unique IDs
    setTotalIds(0); // Reset total IDs
    setIdField(''); // Reset ID field
    setIdFieldSuccessMessage('');
    setIdFieldErrorMessage('');
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/extract-xml-subtags`,
        { link: xmlLink, tag: xmlTag },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      if (response.data.isValidXML) {
        const subTags = response.data.uniqueSubTags || [];
        setUniqueSubTags(subTags);
        setDataSuccessMessage('XML sub-tags extracted successfully.');
        setIsDataProvided(true);
        setIsXMLValid(false); // Will be set to true after validation
        setIsFileUploaded(true); // Set isFileUploaded to true after extraction
  
        // Set duplicate counts if editing
        if (!isEditing) {
          setDuplicateCount(response.data.duplicateCount || 0);
          setUniqueIds(response.data.uniqueIds || 0);
          setTotalIds(response.data.totalIds || 0);
        }
  
        // Initialize mappings with auto-mapped fields
        const initialMappings = { ...mappings };
        predefinedFields.forEach((field) => {
          // Auto-map if there's an exact match (case-insensitive)
          const matchedSubTag = subTags.find(
            (subTag) => subTag.toLowerCase() === field.toLowerCase()
          );
          // Preserve existing idField selection in mappings
          if (isEditing && field === 'id') {
            initialMappings[field] = idField || matchedSubTag || '';
          } else {
            initialMappings[field] = matchedSubTag || '';
          }
        });
        setMappings(initialMappings);
      } else {
        setDataErrorMessage(response.data.message || 'Failed to extract XML sub-tags.');
        setIsDataProvided(false);
        setIsXMLValid(false);
        setIsFileUploaded(false); // Ensure isFileUploaded is false on failed extraction
      }
    } catch (error) {
      setDataErrorMessage(
        error.response?.data?.message || 'An error occurred while extracting XML sub-tags.'
      );
      setIsDataProvided(false);
      setIsXMLValid(false);
      setIsFileUploaded(false); // Ensure isFileUploaded is false on error
      console.error('Error extracting XML sub-tags:', error);
    } finally {
      setIsCheckingLink(false);
    }
  };

  // **Helper function to include existing ID field in options**
  const getIdFieldOptions = (uniqueFields, currentIdField) => {
    if (currentIdField && !uniqueFields.includes(currentIdField)) {
      return [...uniqueFields, currentIdField];
    }
    return uniqueFields;
  };

  // **Handle Option Selection with Comprehensive State Reset and dataTypeChanged Flag**
  const handleOptionSelect = (option) => {
    if (!isFeedNameValid && !isEditing) return; // Prevent selection if feed name is invalid
    setSelectedOption(option);
    setDataTypeChanged(true); // Indicate data type change
    setSelectedPim('');
    setGeneralError('');

    // Reset data provision states
    setFile(null);
    setLink('');
    setDataSuccessMessage('');
    setDataErrorMessage('');
    setIdColumnSuccessMessage('');
    setIdColumnErrorMessage('');
    setIsDataProvided(false);
    setIsFileUploaded(false); // Reset file upload state
    setIsXMLValid(false); // Reset XML validation state
    setIsCSVValid(false); // Reset CSV validation state
    setIsJSONValid(false); // Reset JSON validation state
    setUniqueSubTags([]); // Reset unique sub-tags
    setUniqueColumns([]); // Reset unique columns
    setUniqueFields([]); // Reset unique fields for JSON
    setMappings({}); // Reset mappings
    setDuplicateCount(0); // Reset duplicate count
    setUniqueIds(0); // Reset unique IDs
    setTotalIds(0); // Reset total IDs
    setDataProvider(null); // Reset data provider
    setIdField(''); // Reset ID field for XML and JSON
    setIdFieldSuccessMessage('');
    setIdFieldErrorMessage('');

    // Reset file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    // Reset mapping file if any
    setMappingFile(null);
    setMappingFileError('');
    if (mappingFileInputRef.current) {
      mappingFileInputRef.current.value = '';
    }

    // Reset Tag and CSV-specific states based on option
    if (option === 'XML') {
      setTag('item'); // Set default value for XML
    } else if (option === 'CSV' || option === 'GoogleSheets') {
      setHeaderRow(1); // Set default Header Row
      setIdColumn(''); // Reset ID Column
    } else if (option === 'JSON') {
      // No additional states to reset
    } else {
      setTag(''); // Clear Tag for other data types
      setHeaderRow(1); // Reset Header Row
      setIdColumn(''); // Reset ID Column
    }
  };

  // Handle PIM Selection
  const handlePimSelect = (pim) => {
    if (!isFeedNameValid && !isEditing) return; // Prevent selection if feed name is invalid
    setSelectedPim(pim);
    setGeneralError('');

    // Reset data provision states
    setDataSuccessMessage('');
    setDataErrorMessage('');
    setIdColumnSuccessMessage('');
    setIdColumnErrorMessage('');
    setIsDataProvided(true); // PIM systems might manage their own data
    setIsFileUploaded(true); // Assume data is managed by PIM

    // Reset mapping file if any
    setMappingFile(null);
    setMappingFileError('');
    if (mappingFileInputRef.current) {
      mappingFileInputRef.current.value = '';
    }

    // Reset unique sub-tags and mappings
    setUniqueSubTags([]);
    setUniqueColumns([]); // Reset unique columns for PIM
    setMappings(existingFeedData?.mappings || {});
    setDuplicateCount(existingFeedData?.duplicate_count || 0); // Existing data
    setUniqueIds(existingFeedData?.unique_ids || 0); // Existing data
    setTotalIds(existingFeedData?.total_ids || 0); // Existing data
    setDataProvider(null); // Reset data provider
    setIdField(''); // Reset ID field for XML and JSON
    setIdFieldSuccessMessage('');
    setIdFieldErrorMessage('');
  };

  // Handle Header Row Change
  const handleHeaderRowChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 1) {
      setHeaderRow(value);
      setIsCSVValid(false); // Reset CSV validation state on change
      setUniqueColumns([]); // Reset columns
      setIdColumn(''); // Reset ID column
      setDuplicateCount(0); // Reset duplicate count
      setUniqueIds(0); // Reset unique IDs
      setTotalIds(0); // Reset total IDs
    }
  };

  // Handle File Change
  const handleFileChange = async (e) => {
    if (!isFeedNameValid && !isEditing) return; // Prevent action if feed name is invalid
    setDataErrorMessage(''); // Clear previous error
    setDataSuccessMessage('');
    setIdColumnSuccessMessage('');
    setIdColumnErrorMessage('');
    setDuplicateCount(0); // Reset duplicate count
    setUniqueIds(0); // Reset unique IDs
    setTotalIds(0); // Reset total IDs
    setDataProvider(null); // Reset data provider before setting
    setIdField(''); // Reset ID field for XML and JSON
    setIdFieldSuccessMessage('');
    setIdFieldErrorMessage('');
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 400 * 1024 * 1024) { // 400MB limit
        setDataErrorMessage('File size should not exceed 400MB.');
        setFile(null);
        return;
      }
      setFile(selectedFile);
      setDataProvider('file'); // Set data provider to 'file'
      setIsSubmitting(true);
      try {
        const data = new FormData();
        data.append('file', selectedFile);
        data.append('client_name', feedName);
        // Include tag if data type is XML
        if (selectedOption === 'XML') {
          data.append('tag', tag);
        }
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/upload-feed-file`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        if (response.status === 200) {
          const gcsLink = response.data.link;
          setLink(gcsLink);
          setDataSuccessMessage('File uploaded successfully!');
          // Proceed to extract sub-tags for XML
          if (selectedOption === 'XML') {
            await extractXMLSubTags(gcsLink, tag);
            setDataProvider('file'); // Set data provider to 'file' after successful extraction
          } else if (selectedOption === 'CSV') {
            await extractCSVColumns(gcsLink, headerRow);
          } else if (selectedOption === 'GoogleSheets') {
            await extractCSVColumns(gcsLink, headerRow);
          } else if (selectedOption === 'JSON') {
            await extractJSONFields(gcsLink);
          } else {
            setIsDataProvided(true);
            setIsFileUploaded(true);
          }
          // **Reset dataTypeChanged Flag** since new data is provided
          setDataTypeChanged(false);
        } else {
          setDataErrorMessage('An error occurred while uploading the file.');
        }
      } catch (error) {
        setDataErrorMessage('An error occurred while uploading the file.');
        console.error('Error uploading file:', error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  // Handle Link Change
  const handleLinkChange = (e) => {
    setLink(e.target.value);
    setDataSuccessMessage('');
    setDataErrorMessage('');
    setIdColumnSuccessMessage('');
    setIdColumnErrorMessage('');
    setIsDataProvided(false);
    setIsFileUploaded(false);
    setIsXMLValid(false); // Reset XML validation when link changes
    setIsCSVValid(false); // Reset CSV validation when link changes
    setUniqueColumns([]); // Reset columns
    setIdColumn(''); // Reset ID column
    setDuplicateCount(0); // Reset duplicate count
    setUniqueIds(0); // Reset unique IDs
    setTotalIds(0); // Reset total IDs
    setDataProvider(null); // Reset data provider
    setIdField(''); // Reset ID field for XML and JSON
    setIdFieldSuccessMessage('');
    setIdFieldErrorMessage('');
  };

  // Handle Link Blur
  const handleLinkBlur = async () => {
    if (!link.trim()) {
      setDataErrorMessage('Please enter a link.');
      setDataSuccessMessage('');
      setIsDataProvided(false);
      return;
    }
    if (!isFeedNameValid && !isEditing) {
      setDataErrorMessage('Please enter a valid feed name before providing a link.');
      setDataSuccessMessage('');
      setIsDataProvided(false);
      return;
    }
  
    setIsCheckingLink(true);
    setDataErrorMessage('');
    setDataSuccessMessage('');
    setIdColumnSuccessMessage('');
    setIdColumnErrorMessage('');
    setDuplicateCount(0); // Reset duplicate count
    setUniqueIds(0); // Reset unique IDs
    setTotalIds(0); // Reset total IDs
  
    try {
      if (selectedOption === 'XML') {
        // XML handling
        await extractXMLSubTags(link, tag);
        setDataProvider('link'); // Set data provider to 'link' after successful extraction
      } else if (selectedOption === 'CSV' || selectedOption === 'GoogleSheets') {
        // CSV or Google Sheets handling
        await extractCSVColumns(link, headerRow);
        setDataProvider('link'); // Set data provider to 'link' after successful extraction
      } else if (selectedOption === 'JSON') {
        // JSON handling
        await extractJSONFields(link);
        setDataProvider('link'); // Set data provider to 'link' after successful extraction
      } else {
        // Handle other data types if any
        setDataErrorMessage('Unsupported data type selected.');
        setIsDataProvided(false);
        setDataProvider(null);
      }
      // **Reset dataTypeChanged Flag** since new data is provided
      setDataTypeChanged(false);
    } catch (error) {
      // Error handling based on data type
      if (selectedOption === 'XML') {
        setDataErrorMessage(
          error.response?.data?.message || 'An error occurred while checking the XML link.'
        );
      } else if (selectedOption === 'CSV' || selectedOption === 'GoogleSheets') {
        setDataErrorMessage(
          error.response?.data?.message || 'An error occurred while extracting columns.'
        );
      } else if (selectedOption === 'JSON') {
        setDataErrorMessage(
          error.response?.data?.message || 'An error occurred while checking the JSON link.'
        );
      } else {
        setDataErrorMessage('An error occurred while checking the link.');
      }
      setIsDataProvided(false);
      console.error(`Error checking ${selectedOption} link:`, error);
      setDataProvider(null);
    } finally {
      setIsCheckingLink(false);
    }
  };

  // Handle ID Column Selection
  const handleIdColumnChange = async (e) => {
    const selectedIdColumn = e.target.value;
    setIdColumn(selectedIdColumn);
    setIsCSVValid(false); // Reset CSV validation state
    setDuplicateCount(0); // Reset duplicate count
    setUniqueIds(0); // Reset unique IDs
    setTotalIds(0); // Reset total IDs
  
    if (selectedIdColumn) {
      setIsEditModeValidating(true); // Start manual validation
      await validateCSV(link, selectedIdColumn, headerRow);
      setIsEditModeValidating(false); // End manual validation
    }
  };

  // Handle ID Field Change for XML and JSON
  const handleIdFieldChange = async (e) => {
    const selectedIdField = e.target.value;
    setIdField(selectedIdField);
    setIsXMLValid(false); // Reset XML validation state
    setIsJSONValid(false); // Reset JSON validation state
    setDuplicateCount(0); // Reset duplicate count
    setUniqueIds(0); // Reset unique IDs
    setTotalIds(0); // Reset total IDs
  
    if (selectedIdField) {
      if (selectedOption === 'XML') {
        // Validate XML with the selected ID field
        await validateXML(link, tag, selectedIdField);
      } else if (selectedOption === 'JSON') {
        // Validate JSON with the selected ID field
        await validateJSON(link, selectedIdField);
      }
    }
  };
  
  // Handle Mapping Change
  const handleMappingChange = (field, value) => {
    setMappings((prevMappings) => ({
      ...prevMappings,
      [field]: value,
    }));
  };

  // Handle Submit
  const handleSubmit = async () => {
    // Basic Validations
    if (!feedName.trim()) {
      setFeedNameError('Please enter a feed name.');
      return;
    }

    if (!isFeedNameValid && !isEditing) {
      setGeneralError('Please enter a valid feed name.');
      return;
    }

    if (!selectedOption) {
      setGeneralError('Please select a data type.');
      return;
    }

    if (selectedOption === 'PIM' && !selectedPim) {
      setGeneralError('Please select a PIM system.');
      return;
    }

    if (!isDataProvided && selectedOption !== 'PIM') {
      setGeneralError('Please provide your data.');
      return;
    }


    if ((selectedOption === 'CSV' || selectedOption === 'GoogleSheets') && !isCSVValid) {
      setGeneralError('Please wait until the CSV validation is complete.');
      return;
    }

    // Validate Credit Balance
    if (!creditBalance.trim()) {
      setCreditBalanceError('Please enter a credit balance.');
      return;
    }

    if (!/^\d+$/.test(creditBalance)) {
      setCreditBalanceError('Credit balance must be a non-negative integer.');
      return;
    }

    // Prepare Payload
    const payload = {
      clientName: feedName,
      dataType: selectedOption,
      pimSystem: selectedPim || null,
      dataLink: link || null,
      dataProvider: dataProvider, // Include dataProvider
      mappings:
        selectedOption === 'XML' ||
        selectedOption === 'CSV' ||
        selectedOption === 'GoogleSheets' ||
        selectedOption === 'JSON'
          ? mappings
          : null,
      // removed missingFields
      idColumn:
        selectedOption === 'CSV' || selectedOption === 'GoogleSheets'
          ? idColumn
          : null,
      headerRow:
        selectedOption === 'CSV' || selectedOption === 'GoogleSheets'
          ? headerRow
          : null,
      duplicateCount:
        selectedOption === 'XML' || selectedOption === 'CSV' || selectedOption === 'GoogleSheets' || selectedOption === 'JSON'
          ? duplicateCount
          : null,
      uniqueIds:
        selectedOption === 'XML' || selectedOption === 'CSV' || selectedOption === 'GoogleSheets' || selectedOption === 'JSON'
          ? uniqueIds
          : null,
      totalIds:
        selectedOption === 'XML' || selectedOption === 'CSV' || selectedOption === 'GoogleSheets' || selectedOption === 'JSON'
          ? totalIds
          : null,
      idField: (selectedOption === 'XML' || selectedOption === 'JSON') ? idField : null, // Include idField for XML and JSON
      tag: selectedOption === 'XML' ? tag : null, // Include tag for XML
      credit_balance: creditBalance ? parseInt(creditBalance, 10) : null, // Include credit_balance
      assigned_users: selectedUsers.map(user => user.value), // Include assigned users
      // Include other necessary fields as required
    };

    setIsSubmitting(true);
    setGeneralError('');
    setAssignError('');
    setAssignSuccess('');

    try {
      if (isEditing) {
        // Call update endpoint
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/update-feed`,
          payload,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.status === 200) {
          // Handle successful update
          setAssignSuccess('Feed updated successfully.');
          if (onFeedCreated) {
            onFeedCreated();
          }
          // Close the popup after a short delay to show success message
          setTimeout(() => {
            onClose();
          }, 1500);
        } else {
          setGeneralError(response.data.error || 'An error occurred while updating the feed.');
        }
      } else {
        // Call create endpoint
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/create-feed`,
          payload,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.status === 201) {
          // Handle successful creation
          // Assign the Client to Selected Users (if any)
          if (selectedUsers.length > 0) {
            const assignPromises = selectedUsers.map((user) =>
              axios.post(
                `${process.env.REACT_APP_API_URL}/admin/assign-client`,
                {
                  username: user.value,
                  client_name: feedName,
                },
                { headers: { Authorization: `Bearer ${token}` } }
              )
            );

            await Promise.all(assignPromises);
            setAssignSuccess('Client assigned to selected users successfully.');
          }

          // Invoke the refresh function to update tables
          if (onFeedCreated) {
            onFeedCreated();
          }

          // Close the popup after a short delay to show success message
          setTimeout(() => {
            onClose();
          }, 1500);
        } else {
          setGeneralError(response.data.error || 'An error occurred while creating the feed.');
        }
      }
    } catch (error) {
      // Handle errors from feed creation or update
      setGeneralError(
        error.response?.data?.error || `An error occurred while ${isEditing ? 'updating' : 'creating'} the feed.`
      );
      console.error(`Error ${isEditing ? 'updating' : 'creating'} feed:`, error);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  // Get Accepted File Types Based on Selected Option
  const getAcceptFileTypes = () => {
    switch (selectedOption) {
      case 'XML':
        return '.xml';
      case 'CSV':
        return '.csv,.tsv,.xls,.xlsx';
      case 'JSON':
        return '.json';
      case 'GoogleSheets':
        return ''; // No file upload for Google Sheets
      case 'PIM':
        return ''; // PIM might have its own data provision method
      default:
        return '';
    }
  };

  // Handle Backdrop Click to Close Modal
  const handleBackdropClick = (e) => {
    // If an input is focused, do not close the popup
    if (
      document.activeElement &&
      (document.activeElement.tagName === 'INPUT' ||
        document.activeElement.tagName === 'TEXTAREA' ||
        document.activeElement.tagName === 'SELECT')
    ) {
      return;
    }
    onClose();
  };
  
  // Function to determine if a mapping is duplicated
  const isMappingDuplicated = (value) => {
    if (!value) return false;
    const count = Object.values(mappings).filter(val => val === value).length;
    return count > 1;
  };

  // **Updated useEffect to prevent multiple backend calls in edit mode**
  useEffect(() => {
    if (isEditing && !hasFetchedEditData && link) { // Only fetch if not fetched yet
      const fetchData = async () => {
        if (selectedOption === 'XML' && tag && idField) {
          await extractXMLSubTags(link, tag);
          await validateXML(link, tag, idField);
        } else if (
          (selectedOption === 'CSV' || selectedOption === 'GoogleSheets') &&
          headerRow &&
          idColumn
        ) {
          await extractCSVColumns(link, headerRow);
          await validateCSV(link, idColumn, headerRow);
        } else if (selectedOption === 'JSON' && idField) {
          await extractJSONFields(link);
          await validateJSON(link, idField);
        }
        // Mark as fetched to prevent further calls
        setHasFetchedEditData(true);
      };
      fetchData();
    }
  }, [isEditing, hasFetchedEditData, link, selectedOption, tag, idField, idColumn, headerRow]);

  // **Removed dataTypeChanged from dependencies to prevent loops**

  // **Removed dataTypeChanged from handleOptionSelect and fetchData useEffect to prevent unwanted fetches**

  // **Optionally, check if in edit mode, and avoid resetting certain states**

  // **New useEffect to reset dataTypeChanged flag after data is provided**
  useEffect(() => {
    if (dataTypeChanged && (file || link)) {
      setDataTypeChanged(false);
      // Uncomment the next line for debugging
      // console.log('dataTypeChanged flag reset to false after data provision');
    }
  }, [file, link, dataTypeChanged]);

  return (
    <div
      className={styles.modalBackdrop}
      onClick={handleBackdropClick}
      role="dialog"
      aria-modal="true"
      aria-labelledby="add-feed-modal-title"
    >
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <h2 id="add-feed-modal-title" className={styles.modalTitle}>
          {isEditing ? 'Modify Feed' : 'Add New Feed'}
        </h2>

        {/* Step 1: Feed Name */}
        <div className={styles.stepContainer}>
          <div className={styles.stepHeader}>
            <div className={styles.stepNumber}>1</div>
            <h3 className={styles.stepTitle}>Enter Feed Name</h3>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="feedName" className={styles.label}>
              Feed Name (e.g. client_market / qfe_de):
              <div className={styles.tooltip}>
                <FaInfoCircle />
                <span className={styles.tooltiptext}>
                  Enter a unique name for your feed. Only letters, numbers, underscores, hyphens, and "@" are allowed. No spaces are permitted.
                </span>
              </div>
            </label>
            <div className={styles.inputWithFeedback}>
              <input
                type="text"
                id="feedName"
                value={feedName}
                onChange={(e) => {
                  const lowercaseValue = e.target.value.toLowerCase();
                  setFeedName(lowercaseValue);
                  if (!isEditing) {
                    setIsFeedNameValid(false);
                    setFeedSuccessMessage('');
                  }
                }}
                className={styles.inputField}
                placeholder="Enter feed name"
                disabled={isEditing} // Disable editing if desired
              />
              {!isEditing && isCheckingFeedName && <FaSpinner className={styles.spinner} />}
            </div>
            <div className={styles.feedbackMessage}>
              {!isEditing && feedNameError && (
                <p className={styles.errorMessage}>{feedNameError}</p>
              )}
              {!isEditing && feedSuccessMessage && isFeedNameValid && (
                <p className={styles.successMessage}>{feedSuccessMessage}</p>
              )}
              {isEditing && (
                <p className={styles.successMessage}>Editing feed: {feedName}</p>
              )}
            </div>
          </div>
        </div>

        {/* Step 2: Select Data Type */}
        <div className={styles.stepContainer}>
          <div className={styles.stepHeader}>
            <div className={styles.stepNumber}>2</div>
            <h3 className={styles.stepTitle}>Select Data Type</h3>
          </div>

          <div
            className={`${styles.iconGroup} ${
              !isFeedNameValid ? styles.disabledSection : ''
            }`}
          >
            <div
              className={`${styles.iconOption} ${
                selectedOption === 'XML' ? styles.selectedOption : ''
              }`}
              onClick={() => isFeedNameValid ? handleOptionSelect('XML') : null}
            >
              <img
                src="https://storage.googleapis.com/quantum-feed-engine/workbench/icons/xml-icon.png"
                alt="XML"
                className={styles.dataTypeIcon}
              />
              <span>XML</span>
            </div>
            <div
              className={`${styles.iconOption} ${
                selectedOption === 'CSV' ? styles.selectedOption : ''
              }`}
              onClick={() => isFeedNameValid ? handleOptionSelect('CSV') : null}
            >
              <img
                src="https://storage.googleapis.com/quantum-feed-engine/workbench/icons/csv-icon.png"
                alt="CSV/TSV/Excel"
                className={styles.dataTypeIcon}
              />
              <span>CSV/TSV/Excel</span>
            </div>
            <div
              className={`${styles.iconOption} ${
                selectedOption === 'JSON' ? styles.selectedOption : ''
              }`}
              onClick={() => isFeedNameValid ? handleOptionSelect('JSON') : null}
            >
              <img
                src="https://storage.googleapis.com/quantum-feed-engine/workbench/icons/json-icon.png"
                alt="JSON"
                className={styles.dataTypeIcon}
              />
              <span>JSON</span>
            </div>
            <div
              className={`${styles.iconOption} ${
                selectedOption === 'GoogleSheets' ? styles.selectedOption : ''
              }`}
              onClick={() => isFeedNameValid ? handleOptionSelect('GoogleSheets') : null}
            >
              <img
                src="https://storage.googleapis.com/quantum-feed-engine/workbench/icons/google-sheets-icon.png"
                alt="Google Sheets"
                className={styles.dataTypeIcon}
              />
              <span>Google Sheets</span>
            </div>
            <div
              className={`${styles.iconOption} ${
                selectedOption === 'PIM' ? styles.selectedOption : ''
              }`}
              onClick={() => isFeedNameValid ? handleOptionSelect('PIM') : null}
            >
              <img
                src="https://storage.googleapis.com/quantum-feed-engine/workbench/icons/pim-icon.png"
                alt="PIM System"
                className={styles.dataTypeIcon}
              />
              <span>PIM System</span>
            </div>
          </div>
        </div>

        {/* Step 3: Provide Data or Select PIM */}
        <div className={styles.stepContainer}>
          <div className={styles.stepHeader}>
            <div className={styles.stepNumber}>3</div>
            <h3 className={styles.stepTitle}>
              {selectedOption === 'PIM' ? 'Select PIM System' : 'Provide Data'}
            </h3>
          </div>

          {/* For non-PIM options */}
          {selectedOption && selectedOption !== 'PIM' && (isFeedNameValid || isEditing) && (
            <>
              {/* Conditional Inputs for XML, CSV, Google Sheets, and JSON */}
              {selectedOption === 'XML' && (
                <>
                  <div className={styles.formGroup}>
                    <label htmlFor="tag" className={styles.label}>
                      Item tag:
                    </label>
                    <input
                      type="text"
                      id="tag"
                      value={tag}
                      onChange={(e) => {
                        setTag(e.target.value);
                        if (!isEditing) {
                          setIsXMLValid(false); // Reset XML validation state on change
                        }
                      }}
                      onBlur={() => {
                        if (selectedOption === 'XML' && link.trim() && tag.trim()) {
                          extractXMLSubTags(link, tag);
                        }
                      }}
                      className={styles.inputField}
                      placeholder="Tag"
                      required
                      disabled={false} // Optionally disable editing tag in edit mode
                    />
                  </div>

                  {/* File Upload or Link for XML */}
                  <div className={styles.dataOptions}>
                    {/* Upload File Section */}
                    <div className={styles.formGroup}>
                      <label className={styles.label}>Upload File:</label>
                      <div className={styles.uploadContainer}>
                        <input
                          type="file"
                          accept={getAcceptFileTypes()}
                          onChange={handleFileChange}
                          className={styles.inputField}
                          ref={fileInputRef}
                          disabled={false} // Optionally disable file upload in edit mode
                        />
                      </div>
                    </div>
                    <div className={styles.orDivider}>
                      <span>OR</span>
                    </div>
                    {/* Provide Link Section */}
                    <div className={styles.formGroup}>
                      <label className={styles.label}>Provide Link:</label>
                      <input
                        type="text"
                        value={link}
                        onChange={handleLinkChange}
                        onBlur={handleLinkBlur}
                        className={styles.inputField}
                        placeholder="Enter data link"
                        disabled={false} // Optionally disable link input in edit mode
                      />
                      {isCheckingLink && <FaSpinner className={styles.spinner} />}
                    </div>
                  </div>

                  {/* Note for Uploaded Files */}
                  {isFileUploaded && dataProvider === 'file' && (
                    <div className={styles.infoMessage}>
                      <FaInfoCircle className={styles.infoIcon} />
                      <span>
                        Note: Uploaded files are static and will not auto-update. To keep your data up-to-date, consider providing a link instead.
                      </span>
                    </div>
                  )}

                  {/* Display Success or Error Message */}
                  <div className={styles.messageContainer}>
                    {dataErrorMessage && (
                      <p className={styles.errorMessage}>{dataErrorMessage}</p>
                    )}
                    {dataSuccessMessage && isDataProvided && (
                      <p className={styles.successMessage}>{dataSuccessMessage}</p>
                    )}
                  </div>

                  {/* Select ID Field for XML */}
                  {uniqueSubTags.length > 0 && isDataProvided && (
                    <div className={styles.formGroup}>
                      <label htmlFor="idField" className={styles.label}>
                        Select ID Field:
                        <div className={styles.tooltip}>
                          <FaInfoCircle />
                          <span className={styles.tooltiptext}>
                            Choose the sub-tag that uniquely identifies each product.
                          </span>
                        </div>
                      </label>
                      <select
                        id="idField"
                        value={idField}
                        onChange={handleIdFieldChange}
                        className={styles.inputField}
                        // disabled={isEditing} Optionally disable ID field selection in edit mode
                      >
                        <option value="">Select ID Field</option>
                        {/* **Use Current idField State Instead of existingFeedData.id_field** */}
                        {getIdFieldOptions(uniqueSubTags, idField).map((subTag) => (
                          <option key={subTag} value={subTag}>
                            {subTag}
                          </option>
                        ))}
                      </select>
                      <div className={styles.feedbackMessage}>
                        {idFieldErrorMessage && (
                          <p className={styles.errorMessage}>{idFieldErrorMessage}</p>
                        )}
                        {idFieldSuccessMessage && (
                          <p className={styles.successMessage}>{idFieldSuccessMessage}</p>
                        )}
                      </div>
                    </div>
                  )}

                  {/* Duplicate ID Warning */}
                  {isXMLValid && duplicateCount > 0 && (
                    <div className={styles.duplicateWarning}>
                      <FaExclamationTriangle className={styles.warningIcon} />
                      <div>
                        <p>
                          There are <strong>{duplicateCount}</strong> duplicate ID(s) in your XML file.
                        </p>
                        <p>You can continue, but unique IDs are recommended for accurate processing.</p>
                      </div>
                    </div>
                  )}
                </>
              )}

              {(selectedOption === 'CSV' || selectedOption === 'GoogleSheets') && (
                <>
                  {/* Header Row Number Input */}
                  <div className={styles.formGroup}>
                    <label htmlFor="headerRow" className={styles.label}>
                      Header Row Number:
                      <div className={styles.tooltip}>
                        <FaInfoCircle />
                        <span className={styles.tooltiptext}>
                          Specify the row number where the column headers are located. Default is 1.
                        </span>
                      </div>
                    </label>
                    <input
                      type="number"
                      id="headerRow"
                      value={headerRow}
                      onChange={handleHeaderRowChange}
                      className={styles.inputField}
                      placeholder="Row Number"
                      min="1"
                      required
                      disabled={false} // Optionally disable header row change in edit mode
                    />
                  </div>

                  {/* File Upload or Link Section */}
                  <div className={styles.dataOptions}>
                    {/* Conditionally Render Upload File Only for CSV */}
                    {selectedOption === 'CSV' && (
                      <>
                        <div className={styles.formGroup}>
                          <label className={styles.label}>Upload File:</label>
                          <div className={styles.uploadContainer}>
                            <input
                              type="file"
                              accept={getAcceptFileTypes()}
                              onChange={handleFileChange}
                              className={styles.inputField}
                              ref={fileInputRef}
                              disabled={false} // Optionally disable file upload in edit mode
                            />
                          </div>
                        </div>
                        <div className={styles.orDivider}>
                          <span>OR</span>
                        </div>
                      </>
                    )}
                    {/* Provide Link Section for Both CSV and Google Sheets */}
                    <div className={styles.formGroup}>
                      <label className={styles.label}>Provide Link:</label>
                      <input
                        type="text"
                        value={link}
                        onChange={handleLinkChange}
                        onBlur={handleLinkBlur}
                        className={styles.inputField}
                        placeholder="Enter data link"
                        // disabled={isEditing}
                      />
                      {isCheckingLink && <FaSpinner className={styles.spinner} />}
                    </div>
                  </div>

                  {/* Display Success or Error Message */}
                  <div className={styles.messageContainer}>
                    {dataErrorMessage && (
                      <p className={styles.errorMessage}>{dataErrorMessage}</p>
                    )}
                    {dataSuccessMessage && isDataProvided && (
                      <p className={styles.successMessage}>{dataSuccessMessage}</p>
                    )}
                  </div>

                  {/* ID Column Selection */}
                  {uniqueColumns.length > 0 && isDataProvided && (
                    <div className={styles.formGroup}>
                      <label htmlFor="idColumn" className={styles.label}>
                        Select ID Column:
                        <div className={styles.tooltip}>
                          <FaInfoCircle />
                          <span className={styles.tooltiptext}>
                            Choose the column that uniquely identifies each product.
                          </span>
                        </div>
                      </label>
                      <select
                        id="idColumn"
                        value={idColumn}
                        onChange={handleIdColumnChange}
                        className={styles.inputField}
                      >
                        <option value="">Select ID Column</option>
                        {/* **Use Current idColumn State Instead of existingFeedData.id_column** */}
                        {getIdFieldOptions(uniqueColumns, idColumn).map((column) => (
                          <option key={column} value={column}>
                            {column}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  {/* Separate Success/Error Messages for ID Column Selection */}
                  <div className={styles.messageContainer}>
                    {idColumnErrorMessage && (
                      <div className={styles.errorMessage}>
                        <FaTimesCircle className={styles.errorIcon} />
                        <span>{idColumnErrorMessage}</span>
                      </div>
                    )}
                    {idColumnSuccessMessage && isCSVValid && (
                      <div className={styles.successMessage}>
                        <FaCheckCircle className={styles.successIcon} />
                        <span>{idColumnSuccessMessage}</span>
                      </div>
                    )}
                  </div>

                  {/* Duplicate ID Warning */}
                  {isCSVValid && duplicateCount > 0 && (
                    <div className={styles.duplicateWarning}>
                      <FaExclamationTriangle className={styles.warningIcon} />
                      <div>
                        <p>
                          There are <strong>{duplicateCount}</strong> duplicate ID(s) in your {selectedOption === 'CSV' ? 'CSV' : 'Google Sheets'}.
                        </p>
                        <p>You can continue, but unique IDs are recommended for accurate processing.</p>
                      </div>
                    </div>
                  )}

                  {/* Note for Uploaded Files */}
                  {isFileUploaded && dataProvider === 'file' && (
                    <div className={styles.infoMessage}>
                      <FaInfoCircle className={styles.infoIcon} />
                      <span>
                        Note: Uploaded files are static and will not auto-update. To keep your data up-to-date, consider providing a link instead.
                      </span>
                    </div>
                  )}
                </>
              )}

              {/* Handling JSON Data Type */}
              {selectedOption === 'JSON' && (isFeedNameValid || isEditing) && (
                <>
                  {/* File Upload or Link for JSON */}
                  <div className={styles.dataOptions}>
                    {/* Upload File Section */}
                    <div className={styles.formGroup}>
                      <label className={styles.label}>Upload File:</label>
                      <div className={styles.uploadContainer}>
                        <input
                          type="file"
                          accept={getAcceptFileTypes()}
                          onChange={handleFileChange}
                          className={styles.inputField}
                          ref={fileInputRef}
                          disabled={false} // Optionally disable file upload in edit mode
                        />
                      </div>
                    </div>
                    <div className={styles.orDivider}>
                      <span>OR</span>
                    </div>
                    {/* Provide Link Section */}
                    <div className={styles.formGroup}>
                      <label className={styles.label}>Provide Link:</label>
                      <input
                        type="text"
                        value={link}
                        onChange={handleLinkChange}
                        onBlur={handleLinkBlur}
                        className={styles.inputField}
                        placeholder="Enter data link"
                      //  disabled={isEditing} // Optionally disable link input in edit mode
                      />
                      {isCheckingLink && <FaSpinner className={styles.spinner} />}
                    </div>
                  </div>

                  {/* Note for Uploaded Files */}
                  {isFileUploaded && dataProvider === 'file' && (
                    <div className={styles.infoMessage}>
                      <FaInfoCircle className={styles.infoIcon} />
                      <span>
                        Note: Uploaded files are static and will not auto-update. To keep your data up-to-date, consider providing a link instead.
                      </span>
                    </div>
                  )}

                  {/* Display Success or Error Message */}
                  <div className={styles.messageContainer}>
                    {dataErrorMessage && (
                      <p className={styles.errorMessage}>{dataErrorMessage}</p>
                    )}
                    {dataSuccessMessage && isDataProvided && (
                      <p className={styles.successMessage}>{dataSuccessMessage}</p>
                    )}
                  </div>

                  {/* Select ID Field for JSON */}
                  {uniqueFields.length > 0 && isDataProvided && (
                    <div className={styles.formGroup}>
                      <label htmlFor="idField" className={styles.label}>
                        Select ID Field:
                        <div className={styles.tooltip}>
                          <FaInfoCircle />
                          <span className={styles.tooltiptext}>
                            Choose the field that uniquely identifies each product.
                          </span>
                        </div>
                      </label>
                      <select
                        id="idField"
                        value={idField}
                        onChange={handleIdFieldChange}
                        className={styles.inputField}
                        disabled={isEditing} // Optionally disable ID field selection in edit mode
                      >
                        <option value="">Select ID Field</option>
                        {/* **Use Current idField State Instead of existingFeedData.id_field** */}
                        {getIdFieldOptions(uniqueFields, idField).map((field) => (
                          <option key={field} value={field}>
                            {field}
                          </option>
                        ))}
                      </select>
                      <div className={styles.feedbackMessage}>
                        {idFieldErrorMessage && (
                          <p className={styles.errorMessage}>{idFieldErrorMessage}</p>
                        )}
                        {idFieldSuccessMessage && (
                          <p className={styles.successMessage}>{idFieldSuccessMessage}</p>
                        )}
                      </div>
                    </div>
                  )}

                  {/* Duplicate ID Warning */}
                  {isJSONValid && duplicateCount > 0 && (
                    <div className={styles.duplicateWarning}>
                      <FaExclamationTriangle className={styles.warningIcon} />
                      <div>
                        <p>
                          There are <strong>{duplicateCount}</strong> duplicate ID(s) in your JSON data.
                        </p>
                        <p>You can continue, but unique IDs are recommended for accurate processing.</p>
                      </div>
                    </div>
                  )}
                </>
              )}

              {/* For PIM options */}
              {selectedOption === 'PIM' && (isFeedNameValid || isEditing) && (
                <div className={styles.iconGroup}>
                  <div
                    className={`${styles.iconOption} ${
                      selectedPim === 'Shopify' ? styles.selectedOption : ''
                    }`}
                    onClick={() => isFeedNameValid || isEditing ? handlePimSelect('Shopify') : null}
                  >
                    <img
                      src="https://storage.googleapis.com/quantum-feed-engine/workbench/icons/shopify-icon.png"
                      alt="Shopify"
                      className={styles.pimLogo}
                    />
                    <span>Shopify</span>
                  </div>
                  <div
                    className={`${styles.iconOption} ${
                      selectedPim === 'Magento' ? styles.selectedOption : ''
                    }`}
                    onClick={() => isFeedNameValid || isEditing ? handlePimSelect('Magento') : null}
                  >
                    <img
                      src="https://storage.googleapis.com/quantum-feed-engine/workbench/icons/magento-icon.png"
                      alt="Magento"
                      className={styles.pimLogo}
                    />
                    <span>Magento</span>
                  </div>
                  {/* Add other PIM options similarly */}
                </div>
              )}
            </>
          )}

          {/* Step 4: Mapping */}
          {(
          (selectedOption === 'XML' && isXMLValid) ||
          ((selectedOption === 'CSV' || selectedOption === 'GoogleSheets') && isCSVValid) ||
          (selectedOption === 'JSON' && isJSONValid) ||
          (selectedOption === 'PIM' && selectedPim)
          ) &&
            (isFeedNameValid || isEditing) && (
              <div className={styles.stepContainer}>
                <div className={styles.stepHeader}>
                  <div className={styles.stepNumber}>4</div>
                  <h3 className={styles.stepTitle}>Mapping</h3>
                </div>

                {/* Mapping Form */}
                {(
                  (selectedOption === 'XML' && uniqueSubTags.length > 0) ||
                  ((selectedOption === 'CSV' || selectedOption === 'GoogleSheets') && uniqueColumns.length > 0) ||
                  (selectedOption === 'JSON' && uniqueFields.length > 0)
                ) && (
                  <div className={styles.formGroup}>
                    <div className={styles.mappingHeader}>
                      <label className={styles.label}>
                        Map {selectedOption === 'XML' ? 'XML Tags' : selectedOption === 'JSON' ? 'JSON Fields' : 'Columns'}:
                      </label>
                    </div>
                    <table className={styles.mappingTable}>
                      <thead>
                        <tr>
                          <th>Field</th>
                          <th>Mapping</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {predefinedFields.map((field) => {
                          const mappingValue = mappings[field];
                          const isMapped = !!mappingValue;
                          const isDuplicated = isMappingDuplicated(mappingValue);
                          let StatusIcon;

                          if (!isMapped) {
                            StatusIcon = <FaTimesCircle className={styles.statusIconError} />;
                          } else if (isDuplicated) {
                            StatusIcon = <FaExclamationTriangle className={styles.statusIconWarning} />;
                          } else {
                            StatusIcon = <FaCheckCircle className={styles.statusIconSuccess} />;
                          }

                          return (
                            <tr key={field}>
                              <td className={styles.mappingField}>
                                {field}
                                {fieldDescriptions[field] && (
                                  <div className={styles.tooltip}>
                                    <FaInfoCircle />
                                    <span className={styles.tooltiptext}>
                                      {fieldDescriptions[field]}
                                    </span>
                                  </div>
                                )}
                              </td>
                              <td>
                                <select
                                  value={mappings[field] || ''}
                                  onChange={(e) =>
                                    handleMappingChange(field, e.target.value)
                                  }
                                  className={styles.mappingDropdown}
                                  disabled={false} // Optionally disable mapping changes in edit mode
                                >
                                  <option value="">
                                    Select {selectedOption === 'XML' ? 'XML Tag' : selectedOption === 'JSON' ? 'JSON Field' : 'Column'}
                                  </option>
                                  {(selectedOption === 'XML'
                                    ? uniqueSubTags
                                    : selectedOption === 'JSON'
                                    ? uniqueFields
                                    : uniqueColumns
                                  ).map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>
                                {StatusIcon}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}

                {/* Duplicate ID Warning already handled in Step 3 */}
              </div>
          )}

          {/* Step 5: Define Credit Balance and Assign Users */}
          {isMappingCompleted() && (
            <div className={styles.stepContainer}>
              <div className={styles.stepHeader}>
                <div className={styles.stepNumber}>5</div>
                <h3 className={styles.stepTitle}>Define Credit Balance and Assign Users</h3>
              </div>

              {/* Credit Balance Card */}
              <div className={styles.creditBalanceCard}>
                <div className={styles.formGroup}>
                  <label htmlFor="creditBalance" className={styles.label}>
                    Credit Balance:
                    <div className={styles.tooltip}>
                      <FaInfoCircle />
                      <span className={styles.tooltiptext}>
                        Specify the initial credit balance for the client. This should be a non-negative integer.
                      </span>
                    </div>
                  </label>
                  <input
                    type="number"
                    id="creditBalance"
                    value={creditBalance}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || /^\d+$/.test(value)) {
                        setCreditBalance(value);
                        setCreditBalanceError('');
                      } else {
                        setCreditBalanceError('Credit balance must be a non-negative integer.');
                      }
                    }}
                    className={styles.inputField}
                    placeholder="Enter credit balance"
                    min="0"
                  />
                  {creditBalanceError && (
                    <p className={styles.errorMessage}>{creditBalanceError}</p>
                  )}
                </div>
              </div>

              {/* Assign Users Card */}
              <div className={styles.assignUsersCard}>
                <div className={styles.formGroup}>
                  <label htmlFor="assignUsers" className={styles.label}>
                    Assign to Users:
                    <div className={styles.tooltip}>
                      <FaInfoCircle />
                      <span className={styles.tooltiptext}>
                        Select users to assign this client to.
                      </span>
                    </div>
                  </label>
                  <Select
                    id="assignUsers"
                    isMulti
                    options={availableUsers.map(user => ({
                      value: user.username,
                      label: user.username,
                    }))}
                    value={selectedUsers}
                    onChange={setSelectedUsers}
                    isLoading={isLoadingUsers}
                    className={styles.selectInput}
                    classNamePrefix="react-select"
                    placeholder="Select users to assign"
                    aria-label="Select Users"
                    isDisabled={false} // Optionally disable user assignment in edit mode
                  />
                </div>

                {/* Display Assign Error or Success */}
                {assignError && <p className={styles.errorMessage}>{assignError}</p>}
                {assignSuccess && <p className={styles.successMessage}>{assignSuccess}</p>}
              </div>
            </div>
          )}

          {/* General Error */}
          {generalError && <p className={styles.errorMessage}>{generalError}</p>}

          {/* Buttons */}
          <div className={styles.buttonGroup}>
            <button onClick={onClose} className={styles.cancelButton}>
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className={styles.nextButton}
              disabled={
                isSubmitting ||
                isMappingUploading ||
                !isDataProvided ||
                !dataProvider ||
                (isEditing && false) // Optionally disable certain conditions in edit mode
              }
            >
              {isSubmitting || isMappingUploading ? 'Submitting...' : isEditing ? 'Update Feed' : 'Create Feed'}
            </button>
          </div>

          {/* Loading Indicator */}
          {(isSubmitting || isMappingUploading || isCheckingLink) && (
            <div className={styles.loadingOverlay}>
              <ClipLoader size={50} color={'#4758EB'} loading={true} />
              <p>
                {isSubmitting
                  ? isEditing ? 'Updating Feed...' : 'Uploading...'
                  : isMappingUploading
                  ? 'Uploading Mapping...'
                  : isCheckingLink && selectedOption === 'XML'
                  ? 'Validating XML...'
                  : isCheckingLink && (selectedOption === 'CSV' || selectedOption === 'GoogleSheets')
                  ? 'Processing CSV/Google Sheets...'
                  : isCheckingLink && selectedOption === 'JSON'
                  ? 'Processing JSON...'
                  : 'Checking Link...'}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PopupAddFeed;
