// src/components/CustomAttributes.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './css/CustomAttributes.module.css';
import { FaSignOutAlt, FaArrowLeft } from 'react-icons/fa';

// Import icons for the navigation menu
import { MdDashboard, MdDeveloperMode } from 'react-icons/md';

// Import sub-pages
import AggregatedValues from './CustomAttributesPages/AggregatedValues';
import TBA1 from './CustomAttributesPages/TBA1';

// Import Select from 'react-select'
import Select from 'react-select';

// Import the Aggregated Functions API URL from environment variables
const API_URL_AGG_FUNCTIONS = process.env.REACT_APP_API_URL_AGG_FUNCTIONS;

const CustomAttributes = ({ token, clientNameProp, isAdmin }) => {
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState('AggregatedValues');

  // State for linked clients, selected client, and loading state
  const [linkedClients, setLinkedClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [isLoading, setIsLoading] = useState(true); // Added loading state

  // Custom styles for the Select component
  const customStyles = {
    control: (provided) => ({
      ...provided,
      color: '#292B3D',
      borderColor: '#E0E1EB',
      boxShadow: 'none',
      fontFamily: 'DM Sans, sans-serif',
      '&:hover': {
        borderColor: '#E0E1EB',
      },
      zIndex: 2,
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#fff' : '#292B3D',
      backgroundColor: state.isSelected
        ? '#4758EB'
        : state.isFocused
        ? '#E0E1EB'
        : '#fff',
      fontFamily: 'DM Sans, sans-serif',
      '&:hover': {
        backgroundColor: state.isSelected ? '#4758EB' : '#E0E1EB',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#292B3D',
      fontFamily: 'DM Sans, sans-serif',
    }),
    menu: (provided) => ({
      ...provided,
      fontFamily: 'DM Sans, sans-serif',
      zIndex: 1000,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 1000 }),
  };

  useEffect(() => {
    const fetchLinkedClients = async () => {
      setIsLoading(true); // Start loading
      try {
        const response = await axios.get(`${API_URL_AGG_FUNCTIONS}/client-data`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.status === 200) {
          const data = response.data;
          const clientOptions = data.linked_clients.map((client) => ({
            value: client,
            label: client,
          }));
          setLinkedClients(clientOptions);
        } else {
          console.error('Failed to fetch linked clients');
          setLinkedClients([]); // Ensure linkedClients is empty on failure
        }
      } catch (error) {
        console.error('Error fetching linked clients:', error);
        setLinkedClients([]); // Ensure linkedClients is empty on error
      } finally {
        setIsLoading(false); // End loading
      }
    };

    fetchLinkedClients();
  }, [API_URL_AGG_FUNCTIONS]);

  const handleClientChange = (selectedOption) => {
    setSelectedClient(selectedOption.value);
    console.log('Selected client:', selectedOption.value); // Log the selected client
  };

  const handleLogout = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/logout`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log('Logout successful');
    } catch (err) {
      console.error('Error logging out', err);
    }
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handleBack = () => {
    navigate('/client'); // Navigate to client dashboard
  };

  const renderContent = () => {
    if (!selectedClient) {
      return (
        <div className={styles.selectClientMessage}>
          Please select a client to proceed.
        </div>
      );
    }

    switch (activePage) {
      case 'AggregatedValues':
        return <AggregatedValues client={selectedClient} />;
      case 'TBA1':
        return <TBA1 client={selectedClient} />;
      default:
        return <AggregatedValues client={selectedClient} />;
    }
  };

  // Determine placeholder based on loading state and available clients
  const getPlaceholder = () => {
    if (isLoading) {
      return 'Loading clients...';
    } else if (!isLoading && linkedClients.length === 0) {
      return 'No linked clients available';
    } else {
      return 'Select Client';
    }
  };

  return (
    <div>
      {/* Top Bar */}
      <div className={styles.topBar}>
        {/* Back Button */}
        <button
          onClick={() => navigate(isAdmin ? '/admin' : '/client')}
          className={styles.backButton}
          title="Back"
          aria-label="Back"
        >
          <FaArrowLeft size={20} />
        </button>

        {/* Logout Button */}
        <button
          onClick={handleLogout}
          className={styles.logoutBtn}
          title="Logout"
          aria-label="Logout"
        >
          <FaSignOutAlt size={20} />
        </button>

        {/* Header Text and Dropdown Container */}
        <div className={styles.headerAndDropdown}>
          {/* Header Text Container */}
          <div className={styles.headerTextContainer}>
            <span className={styles.feedViewerVersion}>
              Quantum Feed Engine - Custom Attributes
            </span>
            <span className={styles.headerSubtitle}>
              Manage your custom attributes
            </span>
          </div>

          {/* Client Selection Dropdown Container */}
          <div className={styles.dropdownContainer}>
            <Select
              options={linkedClients}
              onChange={handleClientChange}
              styles={customStyles}
              className={styles.clientDropdown}
              placeholder={getPlaceholder()}
              isSearchable
              isLoading={isLoading} // Show spinner when loading
              menuPortalTarget={document.body}
              menuPosition="fixed"
              noOptionsMessage={() =>
                !isLoading ? 'No linked clients available' : 'Loading...'
              } // Optional: Customize no options message
            />
          </div>
        </div>

        {/* Header Image */}
        <img
          src="https://storage.googleapis.com/quantum-feed-engine/workbench/application-images/3_down.png"
          alt="Header"
          className={styles.headerImage}
        />
      </div>

      {/* Navigation Menu */}
      <div className={styles.navMenu}>
        <button
          className={`${styles.navBtn} ${
            activePage === 'AggregatedValues' ? styles.active : ''
          }`}
          onClick={() => setActivePage('AggregatedValues')}
        >
          <MdDashboard size={20} className={styles.icon} /> Aggregated Values
        </button>
        <button
          className={`${styles.navBtn} ${
            activePage === 'TBA1' ? styles.active : ''
          }`}
          onClick={() => setActivePage('TBA1')}
          title="TBA 1 is currently unavailable."
        >
          <MdDeveloperMode size={20} className={styles.icon} /> TBA 1 (In Dev)
        </button>
      </div>

      {/* Content Area */}
      <div className={styles.contentArea}>{renderContent()}</div>
    </div>
  );
};

export default CustomAttributes;
