import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaSignOutAlt, FaSearchPlus, FaPlus, FaTimes} from 'react-icons/fa';
import Select from 'react-select';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import styles from './css/ImageGenerationScheduler.module.css';

const API_URL_IMAGE = process.env.REACT_APP_API_URL_SCHEDULER;
const API_URL = process.env.REACT_APP_API_URL; // Assuming this is defined for logout

const ImageGenerationScheduler = ({
  isAdmin,
  token,
  selectedClient,
  setSelectedClient,
}) => {
  const navigate = useNavigate();

  // State variables for client selection
  const [linkedClients, setLinkedClients] = useState([]);
  const [isClientsLoading, setIsClientsLoading] = useState(false);
  const [clientError, setClientError] = useState(null);

  // State variables for Import and Processing Updates
  const [importUpdates, setImportUpdates] = useState([]);
  const [isImportUpdatesLoading, setIsImportUpdatesLoading] = useState(false);
  const [importUpdatesError, setImportUpdatesError] = useState(null);

  const [processingUpdates, setProcessingUpdates] = useState([]);
  const [isProcessingUpdatesLoading, setIsProcessingUpdatesLoading] = useState(false);
  const [processingUpdatesError, setProcessingUpdatesError] = useState(null);

  // State variables for selected update details
  const [selectedImportUpdateDetails, setSelectedImportUpdateDetails] = useState(null);
  const [selectedProcessingUpdateDetails, setSelectedProcessingUpdateDetails] = useState(null);

  // State variables for modals
  const [isImportDetailsModalOpen, setIsImportDetailsModalOpen] =
    useState(false);
  const [isProcessingDetailsModalOpen, setIsProcessingDetailsModalOpen] =
    useState(false);

  const [jobType, setJobType] = useState(null);

  const jobTypeOptions = [
    { value: 'uncropper', label: 'Uncropper' },
    { value: 'background_manipulator', label: 'Background Manipulator' },
    { value: 'format_transformer', label: 'Format Transformer' },
    { value: 'importer', label: 'Importer' },
    { value: 'selector', label: 'Selector' },
  ];

  // New State Variables for Feed Dates
  const [feedDates, setFeedDates] = useState({
    image_feed_import_date: null,
    feed_processing_date: null,
    feed_update_date: null,
  });
  const [isFeedDatesLoading, setIsFeedDatesLoading] = useState(false);
  const [feedDatesError, setFeedDatesError] = useState(null);

  // State variables for Scheduling Settings
  const [schedulerImageAutoImportFeed, setSchedulerImageAutoImportFeed] = useState(false);
  const [schedulerImageAutoRunJobs, setSchedulerImageAutoRunJobs] = useState(false);
  const [schedulerImageAutoExportFeed, setSchedulerImageAutoExportFeed] = useState(false);
  const [schedulerImageRunScheduleEvery, setSchedulerImageRunScheduleEvery] = useState('off');
  const [isSchedulingSettingsLoading, setIsSchedulingSettingsLoading] = useState(false);
  const [schedulingSettingsError, setSchedulingSettingsError] = useState(null);


  // New State Variables for Processing Jobs
  const [jobs, setJobs] = useState([]);
  const [isJobsLoading, setIsJobsLoading] = useState(false);
  const [jobsError, setJobsError] = useState(null);

  const [isCreateJobModalOpen, setIsCreateJobModalOpen] = useState(false);
  const [newJobName, setNewJobName] = useState('');
  const [actionLoading, setActionLoading] = useState({});

  const [imageColumns, setImageColumns] = useState([]);
  const [imageTypes, setImageTypes] = useState([]);
  
  // Generate Image Columns
  useEffect(() => {
    const generateImageColumns = () => {
      const padNumber = (num) => (num < 10 ? `0${num}` : `${num}`);
      const additional = Array.from({ length: 10 }, (_, i) => `additional_image_link_${padNumber(i + 1)}`);
      const lifestyle = Array.from({ length: 10 }, (_, i) => `lifestyle_image_link_${padNumber(i + 1)}`);
      const custom = Array.from({ length: 10 }, (_, i) => `custom_image_link_${padNumber(i + 1)}`);
      return ['image_link', ...additional, ...lifestyle, ...custom];
    };
  
    setImageColumns(generateImageColumns());
  }, []);
  
  // Set Image Types
  useEffect(() => {
    const types = [
      'primary',
      'uncropped',
      'background',
      'upload',
      'import',
    ];
    setImageTypes(types);
  }, []);

  
  const [formatTransformerFields, setFormatTransformerFields] = useState({
    format_transformer_selectedColumns: [],
    format_transformer_selectedTypes: [],
    format_transformer_transformFormat: '',
    activateAfterProcessing: false, // New Field
    priority: 1, // New Field (default priority as 1)
  });

// Corrected fetchJobs function
const fetchJobs = async (clientName) => {
  setIsJobsLoading(true);
  setJobsError(null);
  try {
    const response = await fetch(
      `${API_URL_IMAGE}/api/get-processing-jobs?client_name=${encodeURIComponent(clientName)}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.ok) {
      const data = await response.json();
      
      // Sort the jobs: active first, then by created_at descending
      const sortedJobs = data.jobs.sort((a, b) => {
        // First, sort by active status
        if (a.active && !b.active) return -1;
        if (!a.active && b.active) return 1;
        
        // If both are active or inactive, sort by created_at descending
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB - dateA;
      });

      setJobs(sortedJobs);
    } else {
      console.error('Failed to fetch processing jobs');
      setJobsError('Failed to load processing jobs.');
      setJobs([]);
    }
  } catch (error) {
    console.error('Error fetching processing jobs:', error);
    setJobsError('An error occurred while loading processing jobs.');
    setJobs([]);
  } finally {
    setIsJobsLoading(false);
  }
};




// Function to open Create Job Modal
const openCreateJobModal = () => {
  setIsCreateJobModalOpen(true);
};

// Function to close Create Job Modal
const closeCreateJobModal = () => {
  setIsCreateJobModalOpen(false);
  resetNewJobForm();
};

// Function to reset new job form
const resetNewJobForm = () => {
  setNewJobName('');
};

// Function to handle Create Job form submission
const handleCreateJobSubmit = async (e) => {
  e.preventDefault();

  // Basic validation
  if (!newJobName.trim()) {
    alert('Job name is required.');
    return;
  }

  if (!jobType) {
    alert('Job type is required.');
    return;
  }

  // Additional validation for "format_transformer"
  if (jobType.value === 'format_transformer') {
    const {
      format_transformer_selectedColumns,
      format_transformer_selectedTypes,
      format_transformer_transformFormat,
      priority,
    } = formatTransformerFields;

    if (format_transformer_selectedColumns.length === 0) {
      alert('Please select at least one image column.');
      return;
    }

    if (format_transformer_selectedTypes.length === 0) {
      alert('Please select at least one image type.');
      return;
    }

    if (!format_transformer_transformFormat) {
      alert('Please select a transformation format.');
      return;
    }

    if (!priority) {
      alert('Please enter a priority.');
      return;
    }
  }

  // Prepare the job data with default values for other fields
  const jobData = {
    job_name: newJobName,
    job_type: jobType.value, // Include the selected job type
    activate_after_processing: jobType.value !== 'selector' ? formatTransformerFields.activateAfterProcessing : undefined,
    priority: formatTransformerFields.priority || (jobType.value !== 'selector' ? 1 : undefined),
    activated_columns: [], // Default value
    select_prompt: '', // Default value
    human_review: false, // Default value
    created_at: new Date().toISOString(),
    active: true, // Default value
  };

  // If job type is format_transformer, include additional fields
  if (jobType.value === 'format_transformer') {
    jobData.format_transformer_selectedColumns = formatTransformerFields.format_transformer_selectedColumns;
    jobData.format_transformer_selectedTypes = formatTransformerFields.format_transformer_selectedTypes;
    jobData.format_transformer_transformFormat = formatTransformerFields.format_transformer_transformFormat;
    jobData.format_transformer_activateAfterProcessing = formatTransformerFields.activateAfterProcessing;
    jobData.format_transformer_priority = formatTransformerFields.priority;
  }

  try {
    const response = await fetch(`${API_URL_IMAGE}/api/create-job`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        client_name: selectedClient,
        ...jobData,
      }),
    });

    if (response.ok) {
      alert('Job created successfully.');
      closeCreateJobModal();
      fetchJobs(selectedClient);
    } else {
      const errorData = await response.json();
      console.error('Failed to create job:', errorData);
      alert('Failed to create job.');
    }
  } catch (error) {
    console.error('Error creating job:', error);
    alert('An error occurred while creating the job.');
  }
};


// Function to handle Priority input change

  // Function to fetch linked clients
  const fetchLinkedClients = async () => {
    setIsClientsLoading(true);
    setClientError(null);
    try {
      const response = await fetch(`${API_URL_IMAGE}/client-data`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const clientOptions = data.linked_clients.map((client) => ({
          value: client,
          label: client,
        }));
        setLinkedClients(clientOptions);
      } else {
        console.error('Failed to fetch linked clients');
        setClientError('Failed to load clients.');
      }
    } catch (error) {
      console.error('Error fetching linked clients:', error);
      setClientError('An error occurred while loading clients.');
      setLinkedClients([]);
    } finally {
      setIsClientsLoading(false);
    }
  };

  // Function to handle client selection change
  const handleClientChange = (selectedOption) => {
    setSelectedClient(selectedOption.value); // Uses prop
  };

  // Fetch Import Updates
  const fetchImportUpdates = async (clientName) => {
    setIsImportUpdatesLoading(true);
    setImportUpdatesError(null);
    try {
      const response = await fetch(
        `${API_URL_IMAGE}/api/get-import-updates?client_name=${encodeURIComponent(
          clientName
        )}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.ok) {
        const data = await response.json();
        setImportUpdates(data.updates);
      } else {
        console.error('Failed to fetch import updates');
        setImportUpdatesError('Failed to load import updates.');
        setImportUpdates([]);
      }
    } catch (error) {
      console.error('Error fetching import updates:', error);
      setImportUpdatesError('An error occurred while loading import updates.');
      setImportUpdates([]);
    } finally {
      setIsImportUpdatesLoading(false);
    }
  };


  // Function to handle toggling job active status
  const handleToggleActive = async (jobId, newStatus) => {
    // Set loading state for toggle
    setActionLoading((prev) => ({ ...prev, [jobId]: { ...prev[jobId], toggle: true } }));
    
    try {
      const response = await fetch(`${API_URL_IMAGE}/api/update-processing-job-status`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          client_name: selectedClient,
          job_id: jobId,
          active: newStatus,
        }),
      });
  
      if (response.ok) {
        // Optimistically update the UI
        setJobs((prevJobs) =>
          prevJobs.map((job) =>
            job.id === jobId ? { ...job, active: newStatus } : job
          )
        );
      } else {
        const errorData = await response.json();
        console.error('Failed to update job status:', errorData);
        alert('Failed to update job status.');
      }
    } catch (error) {
      console.error('Error updating job status:', error);
      alert('An error occurred while updating job status.');
    } finally {
      // Reset loading state for toggle
      setActionLoading((prev) => ({ ...prev, [jobId]: { ...prev[jobId], toggle: false } }));
    }
  };
  


// Function to handle deleting a job
const handleDeleteJob = async (jobId) => {
  const confirmDelete = window.confirm('Are you sure you want to delete this job? This action cannot be undone.');

  if (!confirmDelete) return;

  // Set loading state for delete
  setActionLoading((prev) => ({ ...prev, [jobId]: { ...prev[jobId], delete: true } }));

  try {
    const response = await fetch(`${API_URL_IMAGE}/api/delete-processing-job`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        client_name: selectedClient,
        job_id: jobId,
      }),
    });

    if (response.ok) {
      // Optimistically remove the job from the UI
      setJobs((prevJobs) => prevJobs.filter((job) => job.id !== jobId));
    } else {
      const errorData = await response.json();
      console.error('Failed to delete job:', errorData);
      alert('Failed to delete job.');
    }
  } catch (error) {
    console.error('Error deleting job:', error);
    alert('An error occurred while deleting the job.');
  } finally {
    // Reset loading state for delete
    setActionLoading((prev) => ({ ...prev, [jobId]: { ...prev[jobId], delete: false } }));
  }
};



  // Fetch Processing Updates
  const fetchProcessingUpdates = async (clientName) => {
    setIsProcessingUpdatesLoading(true);
    setProcessingUpdatesError(null);
    try {
      const response = await fetch(
        `${API_URL_IMAGE}/api/get-processing-updates?client_name=${encodeURIComponent(
          clientName
        )}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setProcessingUpdates(data.updates);
      } else {
        console.error('Failed to fetch processing updates');
        setProcessingUpdatesError('Failed to load processing updates.');
        setProcessingUpdates([]);
      }
    } catch (error) {
      console.error('Error fetching processing updates:', error);
      setProcessingUpdatesError('An error occurred while loading processing updates.');
      setProcessingUpdates([]);
    } finally {
      setIsProcessingUpdatesLoading(false);
    }
  };

  // Fetch Feed Dates
  const fetchFeedDates = async (clientName) => {
    setIsFeedDatesLoading(true);
    setFeedDatesError(null);
    try {
      const response = await fetch(
        `${API_URL_IMAGE}/api/get-client-feed-dates?client_name=${encodeURIComponent(
          clientName
        )}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setFeedDates({
          image_feed_import_date: data.image_feed_import_date,
          feed_processing_date: data.feed_processing_date,
          feed_update_date: data.feed_update_date,
        });
      } else {
        console.error('Failed to fetch feed dates');
        setFeedDatesError('Failed to load feed dates.');
        setFeedDates({
          image_feed_import_date: null,
          feed_processing_date: null,
          feed_update_date: null,
        });
      }
    } catch (error) {
      console.error('Error fetching feed dates:', error);
      setFeedDatesError('An error occurred while loading feed dates.');
      setFeedDates({
        image_feed_import_date: null,
        feed_processing_date: null,
        feed_update_date: null,
      });
    } finally {
      setIsFeedDatesLoading(false);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      color: '#292B3D',
      borderColor: '#E0E1EB',
      boxShadow: 'none',
      fontFamily: 'DM Sans, sans-serif',
      '&:hover': {
        borderColor: '#E0E1EB',
      },
      zIndex: 2,
      backgroundColor: state.isDisabled ? '#f0f0f0' : '#fff',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#fff' : '#292B3D',
      backgroundColor: state.isSelected
        ? '#4758EB'
        : state.isFocused
        ? '#E0E1EB'
        : '#fff',
      fontFamily: 'DM Sans, sans-serif',
      '&:hover': {
        backgroundColor: state.isSelected ? '#4758EB' : '#E0E1EB',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#292B3D',
      fontFamily: 'DM Sans, sans-serif',
    }),
    menu: (provided) => ({
      ...provided,
      fontFamily: 'DM Sans, sans-serif',
      zIndex: 1000,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 1000 }),
  };

  // Fetch Scheduling Settings
  const fetchSchedulingSettings = async (clientName) => {
    setIsSchedulingSettingsLoading(true);
    setSchedulingSettingsError(null);
    try {
      const response = await fetch(
        `${API_URL_IMAGE}/api/get-client-scheduling-settings?client_name=${encodeURIComponent(
          clientName
        )}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSchedulerImageAutoImportFeed(data.scheduler_image_auto_import_feed);
        setSchedulerImageAutoRunJobs(data.scheduler_image_auto_run_jobs);
        setSchedulerImageAutoExportFeed(data.scheduler_image_auto_export_feed);
        setSchedulerImageRunScheduleEvery(data.scheduler_image_run_schedule_every);
      } else {
        console.error('Failed to fetch scheduling settings');
        setSchedulingSettingsError('Failed to load scheduling settings.');
      }
    } catch (error) {
      console.error('Error fetching scheduling settings:', error);
      setSchedulingSettingsError('An error occurred while loading scheduling settings.');
    } finally {
      setIsSchedulingSettingsLoading(false);
    }
  };

  // Update Scheduling Settings
  const updateSchedulingSettings = async (updatedSettings) => {
    try {
      const response = await fetch(`${API_URL_IMAGE}/api/update-client-scheduling-settings`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          client_name: selectedClient,
          ...updatedSettings,
        }),
      });

      if (!response.ok) {
        console.error('Failed to update scheduling settings');
      }
    } catch (error) {
      console.error('Error updating scheduling settings:', error);
    }
  };

  // Handle Scheduler Image Auto Import Feed toggle
  const handleSchedulerImageAutoImportFeedChange = (e) => {
    const newValue = e.target.checked;
    setSchedulerImageAutoImportFeed(newValue);

    // If Auto Import Feed is turned off, disable the other settings
    if (!newValue) {
      setSchedulerImageAutoRunJobs(false);
      setSchedulerImageAutoExportFeed(false);
      setSchedulerImageRunScheduleEvery('off');
    }

    // Update backend
    updateSchedulingSettings({
      scheduler_image_auto_import_feed: newValue,
      scheduler_image_auto_run_jobs: newValue ? schedulerImageAutoRunJobs : false,
      scheduler_image_auto_export_feed: newValue ? schedulerImageAutoExportFeed : false,
      scheduler_image_run_schedule_every: newValue ? schedulerImageRunScheduleEvery : 'off',
    });
  };

  // Handle Scheduler Image Auto Run Jobs toggle
  const handleSchedulerImageAutoRunJobsChange = (e) => {
    const newValue = e.target.checked;
    setSchedulerImageAutoRunJobs(newValue);

    // If Auto Run Jobs is turned off, clear existing jobs (optional)
    if (!newValue) {
      setJobs([]);
    }

    // Update backend
    updateSchedulingSettings({
      scheduler_image_auto_run_jobs: newValue,
    });
  };

  // Handle Scheduler Image Auto Export Feed toggle
  const handleSchedulerImageAutoExportFeedChange = (e) => {
    const newValue = e.target.checked;
    setSchedulerImageAutoExportFeed(newValue);

    // Update backend
    updateSchedulingSettings({ scheduler_image_auto_export_feed: newValue });
  };

  // Handle Scheduler Image Run Schedule Every dropdown
  const handleSchedulerImageRunScheduleEveryChange = (selectedOption) => {
    const newValue = selectedOption.value;
    setSchedulerImageRunScheduleEvery(newValue);

    // Update backend
    updateSchedulingSettings({ scheduler_image_run_schedule_every: newValue });
  };

  // Handle Logout
  const handleLogout = async () => {
    try {
      await axios.post(
        `${API_URL}/logout`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log('Logout successful');
    } catch (err) {
      console.error('Error logging out', err);
    }
    localStorage.removeItem('token');
    navigate('/login');
  };

  // Handle Import Deep Dive Button Click
  const handleImportDeepDive = async (updateId) => {
    try {
      const response = await fetch(
        `${API_URL_IMAGE}/api/get-import-update-details?update_id=${updateId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSelectedImportUpdateDetails(data);
        setIsImportDetailsModalOpen(true);
      } else {
        console.error('Failed to fetch import update details');
      }
    } catch (error) {
      console.error('Error fetching import update details:', error);
    }
  };

  // Handle Processing Deep Dive Button Click
  const handleProcessingDeepDive = async (updateId) => {
    try {
      const response = await fetch(
        `${API_URL_IMAGE}/api/get-processing-update-details?update_id=${updateId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSelectedProcessingUpdateDetails(data);
        setIsProcessingDetailsModalOpen(true);
      } else {
        console.error('Failed to fetch processing update details');
      }
    } catch (error) {
      console.error('Error fetching processing update details:', error);
    }
  };

  // Handle modal close on Esc key
  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      if (isImportDetailsModalOpen) setIsImportDetailsModalOpen(false);
      if (isProcessingDetailsModalOpen) setIsProcessingDetailsModalOpen(false);
    }
  };

  // useEffect to attach keydown listener
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  // useEffect to fetch linked clients on component mount
  useEffect(() => {
    fetchLinkedClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect to fetch updates when selectedClient changes
  useEffect(() => {
    if (selectedClient) {
      // Set loading states immediately
      setIsFeedDatesLoading(true);
      setIsImportUpdatesLoading(true);
      setIsProcessingUpdatesLoading(true);
      setIsSchedulingSettingsLoading(true);
  
      // Fetch updates and related data
      fetchImportUpdates(selectedClient);
      fetchProcessingUpdates(selectedClient);
      fetchFeedDates(selectedClient);
      fetchSchedulingSettings(selectedClient);
      fetchJobs(selectedClient); // Ensure this line is present
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  // Function to determine the status color based on hours since last update
  const getStatusColor = (dateString, schedulerSetting) => {
    if (!dateString) return 'red';
  
    const lastUpdate = new Date(dateString);
    const now = new Date();
    const diffInHours = (now - lastUpdate) / (1000 * 60 * 60);
  
    // Define thresholds based on scheduler setting
    const thresholds = {
      'off': 168,        // 7 days
      '4 hours': 5,
      'daily': 26,
      'weekly': 168,     // 7 days
    };
  
    const threshold = thresholds[schedulerSetting] || 168; // Default to 7 days if unknown
  
    return diffInHours <= threshold ? 'green' : 'red';
  };

  

  return (
    <div>
      {/* Top Bar */}
      <div className={styles.topBar}>
        {/* Back Button */}
        <button
          onClick={() => navigate('/image-generation')}
          className={styles.backButton}
          title="Back"
          aria-label="Back"
        >
          <FaArrowLeft size={20} />
        </button>

        {/* Logout Button */}
        <button
          onClick={handleLogout}
          className={styles.logoutBtn}
          title="Logout"
          aria-label="Logout"
        >
          <FaSignOutAlt size={20} />
        </button>

        {/* Header Text */}
        <div className={styles.headerTextContainer}>
          <span className={styles.feedViewerVersion}>
            Quantum Feed Engine - Image Attributes
          </span>
          <span className={styles.headerSubtitle}>
            Import, processing, and exports
          </span>
        </div>

        {/* Client Selection Dropdown */}
        <Select
          options={linkedClients}
          onChange={handleClientChange}
          styles={customStyles}
          className={styles.clientDropdown}
          placeholder={isClientsLoading ? 'Loading feeds...' : 'Select Feed'}
          isSearchable
          isLoading={isClientsLoading}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          noOptionsMessage={() =>
            clientError
              ? clientError
              : !isClientsLoading && linkedClients.length === 0
              ? 'No linked clients available'
              : 'Loading...'
          }
          isDisabled={isClientsLoading || !!clientError}
          value={
            selectedClient
              ? { value: selectedClient, label: selectedClient }
              : null
          }
        />

        {/* Client Error Message */}
        {clientError && (
          <div className={styles.clientError}>
            <span>{clientError}</span>
          </div>
        )}

        {/* Header Image */}
        <img
          src="https://storage.googleapis.com/quantum-feed-engine/workbench/application-images/3_down.png"
          alt="Header Image"
          className={styles.headerImage}
        />
      </div>

      {/* Feed Dates Section */}
      {selectedClient && (
        <div className={styles.feedDatesContainer}>
          <div className={styles.cardsWrapper}>
            {/* Last Feed Import Card */}
            <div className={styles.card}>
              <div
                className={styles.statusDot}
                style={{
                  backgroundColor: isFeedDatesLoading
                    ? '#E0E1EB'
                    : getStatusColor(feedDates.image_feed_import_date, schedulerImageRunScheduleEvery),
                }}
              ></div>
              <div className={styles.cardContent}>
                <h3>Last Feed Import</h3>
                {isFeedDatesLoading ? (
                  <ClipLoader size={15} color="#4758EB" />
                ) : feedDatesError ? (
                  <p>Error loading data</p>
                ) : (
                  <p>
                    {feedDates.image_feed_import_date
                      ? new Date(feedDates.image_feed_import_date).toLocaleString()
                      : 'N/A'}
                  </p>
                )}
              </div>
            </div>

            {/* Last Image Processing Card */}
            <div className={styles.card}>
              <div
                className={styles.statusDot}
                style={{
                  backgroundColor: isFeedDatesLoading
                    ? '#E0E1EB'
                    : getStatusColor(feedDates.feed_processing_date),
                }}
              ></div>
              <div className={styles.cardContent}>
                <h3>Last Image Processing</h3>
                {isFeedDatesLoading ? (
                  <ClipLoader size={15} color="#4758EB" />
                ) : feedDatesError ? (
                  <p>Error loading data</p>
                ) : (
                  <p>
                    {feedDates.feed_processing_date
                      ? new Date(feedDates.feed_processing_date).toLocaleString()
                      : 'N/A'}
                  </p>
                )}
              </div>
            </div>

            {/* Last Feed Export Card */}
            <div className={styles.card}>
              <div
                className={styles.statusDot}
                style={{
                  backgroundColor: isFeedDatesLoading
                    ? '#E0E1EB'
                    : getStatusColor(feedDates.feed_update_date),
                }}
              ></div>
              <div className={styles.cardContent}>
                <h3>Last Feed Export</h3>
                {isFeedDatesLoading ? (
                  <ClipLoader size={15} color="#4758EB" />
                ) : feedDatesError ? (
                  <p>Error loading data</p>
                ) : (
                  <p>
                    {feedDates.feed_update_date
                      ? new Date(feedDates.feed_update_date).toLocaleString()
                      : 'N/A'}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Overview Section */}
      {selectedClient && (
        <div className={styles.overviewContainer}>
          {/* Tables Wrapper */}
          <div className={styles.tablesWrapper}>
            {/* Titles and Paragraphs */}
            <div className={styles.tablesHeader}>
              <div className={styles.tableHeader}>
                <h2>Import Updates</h2>
                <p>Below is the list of recent import updates.</p>
              </div>
              <div className={styles.tableHeader}>
                <h2>Processing Updates</h2>
                <p>Below is the list of recent processing updates.</p>
              </div>
            </div>
            {/* Tables */}
            <div className={styles.tablesContainer}>
              {/* Import Updates Table */}
              <div className={styles.tableWrapper}>
                {isImportUpdatesLoading ? (
                  <div className={styles.loadingContainer}>
                    <ClipLoader size={30} color="#4758EB" />
                  </div>
                ) : importUpdatesError ? (
                  <div className={styles.errorContainer}>
                    <p>{importUpdatesError}</p>
                  </div>
                ) : importUpdates.length === 0 ? (
                  <div className={styles.noDataContainer}>
                    <p>No import updates available.</p>
                  </div>
                ) : (
                  <div className={styles.tableContainer}>
                    <table className={styles.table}>
                      <thead>
                        <tr>
                          <th>Time</th>
                          <th>Updated By</th>
                          <th>New IDs</th>
                          <th>Removed IDs</th> {/* New Column */}
                          <th>Returning IDs</th>
                          <th>Modified Images</th> {/* New Column */}
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                      {importUpdates.map((update) => (
                        <tr key={update.id}>
                          <td>{new Date(update.updated_at).toLocaleString()}</td>
                          <td>{update.updated_by}</td>
                          <td>{update.new_id_count}</td>
                          <td>{update.removed_id_count}</td>
                          <td>{update.returning_id_count}</td>
                          <td>{update.modified_image_count}</td>
                          <td>
                            <button
                              className={styles.iconButton}
                              onClick={() => handleImportDeepDive(update.id)}
                            >
                              <FaSearchPlus size={16} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    </table>
                  </div>
                )}
              </div>
              {/* Processing Updates Table */}
              <div className={styles.tableWrapper}>
                {isProcessingUpdatesLoading ? (
                  <div className={styles.loadingContainer}>
                    <ClipLoader size={30} color="#4758EB" />
                  </div>
                ) : processingUpdatesError ? (
                  <div className={styles.errorContainer}>
                    <p>{processingUpdatesError}</p>
                  </div>
                ) : processingUpdates.length === 0 ? (
                  <div className={styles.noDataContainer}>
                    <p>No processing updates available.</p>
                  </div>
                ) : (
                  <div className={styles.tableContainer}>
                    <table className={styles.table}>
                      <thead>
                        <tr>
                          <th>Time</th>
                          <th>Updated By</th>
                          <th>Processed Images</th>
                          <th>Error Count</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {processingUpdates.map((update) => (
                          <tr key={update.id}>
                            <td>{new Date(update.updated_at).toLocaleString()}</td>
                            <td>{update.updated_by}</td>
                            <td>{update.processed_image_count}</td>
                            <td>{update.error_count}</td>
                            <td>
                              <button
                                className={styles.iconButton}
                                onClick={() => handleProcessingDeepDive(update.id)}
                              >
                                <FaSearchPlus size={16} />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Scheduling Settings Section */}
          <div className={styles.schedulingSettingsContainer}>
            <h2>Scheduling Settings</h2>
            {isSchedulingSettingsLoading ? (
              <div className={styles.loadingContainer}>
                <ClipLoader size={30} color="#4758EB" />
              </div>
            ) : schedulingSettingsError ? (
              <div className={styles.errorContainer}>
                <p>{schedulingSettingsError}</p>
              </div>
            ) : (
              <div className={styles.settingsWrapper}>
                {/* Scheduler Image Auto Import Feed Toggle */}
                <div className={styles.settingItem}>
                  <label className={styles.toggleLabel}>
                    Auto Import Feed
                    <input
                      type="checkbox"
                      checked={schedulerImageAutoImportFeed}
                      onChange={handleSchedulerImageAutoImportFeedChange}
                    />
                    <span className={styles.toggleSlider}></span>
                  </label>
                </div>

                {/* Scheduler Image Auto Run Jobs Toggle */}
                <div className={styles.settingItem}>
                  <label className={styles.toggleLabel}>
                    Auto Run Jobs
                    <input
                      type="checkbox"
                      checked={schedulerImageAutoRunJobs}
                      onChange={handleSchedulerImageAutoRunJobsChange}
                      disabled={!schedulerImageAutoImportFeed}
                    />
                    <span className={styles.toggleSlider}></span>
                  </label>
                </div>

                {/* Scheduler Image Auto Export Feed Toggle */}
                <div className={styles.settingItem}>
                  <label className={styles.toggleLabel}>
                    Auto Export Feed
                    <input
                      type="checkbox"
                      checked={schedulerImageAutoExportFeed}
                      onChange={handleSchedulerImageAutoExportFeedChange}
                      disabled={!schedulerImageAutoImportFeed}
                    />
                    <span className={styles.toggleSlider}></span>
                  </label>
                </div>

                {/* Scheduler Image Run Schedule Every Dropdown */}
                <div className={styles.settingItem}>
                  <label className={styles.dropdownLabel}>
                    Run schedule every
                    <Select
                      options={[
                        { value: 'off', label: 'Off' },
                        { value: '4 hours', label: '4 Hours' },
                        { value: 'daily', label: 'Daily' },
                        { value: 'weekly', label: 'Weekly' },
                      ]}
                      value={{ value: schedulerImageRunScheduleEvery, label: schedulerImageRunScheduleEvery }}
                      onChange={handleSchedulerImageRunScheduleEveryChange}
                      styles={customStyles}
                      isDisabled={!schedulerImageAutoImportFeed}
                      className={styles.scheduleDropdown}
                    />
                  </label>
                </div>
              </div>
            )}
          </div>

          {/* Import Update Details Modal */}
{/* Import Update Details Modal */}
{isImportDetailsModalOpen && selectedImportUpdateDetails && (
  <div
    className={styles.modalOverlay}
    onClick={() => setIsImportDetailsModalOpen(false)}
  >
    <div
      className={styles.modalContent}
      onClick={(e) => e.stopPropagation()}
    >
      <button
        className={styles.closeModalButton}
        onClick={() => setIsImportDetailsModalOpen(false)}
        aria-label="Close Modal"
      >
        &times;
      </button>
      <h3>Import Update Details</h3>
      
      {/* New IDs Section */}
      <div className={styles.modalSection}>
        <h4>New IDs</h4>
        <pre className={styles.idList}>
          {selectedImportUpdateDetails.new_ids.join('\n')}
        </pre>
      </div>
      
      {/* Removed IDs Section */}
      <div className={styles.modalSection}>
        <h4>Removed IDs</h4>
        <pre className={styles.idList}>
          {selectedImportUpdateDetails.removed_ids.join('\n')}
        </pre>
      </div>
      
      {/* Returning IDs Section */}
      <div className={styles.modalSection}>
        <h4>Returning IDs</h4>
        <pre className={styles.idList}>
          {selectedImportUpdateDetails.returning_ids.join('\n')}
        </pre>
      </div>
      
      {/* Modified Images Section */}
      <div className={styles.modalSection}>
        <h4>Modified Images ({selectedImportUpdateDetails.modified_image_count})</h4>
        
        {/* Grouping modified_images by id */}
        <div className={styles.groupedModifiedImagesContainer}>
          {Object.entries(
            selectedImportUpdateDetails.modified_images.reduce((acc, image) => {
              if (!acc[image.id]) {
                acc[image.id] = [];
              }
              acc[image.id].push(image);
              return acc;
            }, {})
          ).map(([id, images]) => (
            <div key={id} className={styles.modifiedImageGroup}>
              <h5>ID: {id}</h5>
              <div className={styles.imagePairs}>
                {images.map((image, index) => (
                  <div key={index} className={styles.imagePair}>
                    {/* Current Image */}
                    <div className={`${styles.imageWrapper} ${styles.currentImage}`}>
                      <img
                        src={image.current_link}
                        alt={`Current - ${image.image_type}`}
                        loading="lazy"
                        className={styles.image}
                      />
                      <p className={styles.imageLabel}>Current: {image.image_type}</p>
                    </div>
                    
                    {/* New Image */}
                    <div className={`${styles.imageWrapper} ${styles.newImage}`}>
                      <img
                        src={image.new_link}
                        alt={`New - ${image.image_type}`}
                        loading="lazy"
                        className={styles.image}
                      />
                      <p className={styles.imageLabel}>New: {image.image_type}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      
    </div>
  </div>
)}


          {/* Processing Update Details Modal */}
          {isProcessingDetailsModalOpen && selectedProcessingUpdateDetails && (
            <div
              className={styles.modalOverlay}
              onClick={() => setIsProcessingDetailsModalOpen(false)}
            >
              <div
                className={styles.modalContent}
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  className={styles.closeModalButton}
                  onClick={() => setIsProcessingDetailsModalOpen(false)}
                >
                  &times;
                </button>
                <h3>Processing Update Details</h3>
                <div className={styles.modalSection}>
                  <h4>Processed IDs</h4>
                  <pre className={styles.idList}>
                    {selectedProcessingUpdateDetails.processed_ids
                      .map(
                        (item) => `${item.image_id}: ${item.processing_type}`
                      )
                      .join('\n')}
                  </pre>
                </div>
                <div className={styles.modalSection}>
                  <h4>Failed Processed IDs</h4>
                  <pre className={styles.idList}>
                    {selectedProcessingUpdateDetails.failed_processed
                      .map(
                        (item) => `${item.image_id}: ${item.error_message}`
                      )
                      .join('\n')}
                  </pre>
                </div>
              </div>
            </div>
          )}

      {/* New Processing Jobs Section */}
      <div className={styles.processingJobsContainer}>
  {/* Header Row with Title and Button */}
  <div className={styles.processingJobsHeader}>
    <h2>Processing Jobs</h2>
    <button
      className={styles.createJobButton}
      onClick={openCreateJobModal}
      title="Create New Job"
      aria-label="Create New Job"
    >
      <FaPlus size={16} /> Create New Job
    </button>
  </div>
  {/* Jobs Overview Table */}
  <div className={styles.jobsTableWrapper}>
    {isJobsLoading ? (
      <div className={styles.loadingContainer}>
        <ClipLoader size={30} color="#4758EB" />
      </div>
    ) : jobsError ? (
      <div className={styles.errorContainer}>
        <p>{jobsError}</p>
      </div>
    ) : jobs.length === 0 ? (
      <div className={styles.noDataContainer}>
        <p>No processing jobs available.</p>
      </div>
    ) : (
      <table className={styles.jobsTable}>
        <thead>
          <tr>
            <th>Active</th>
            <th>Job Name</th>
            <th>Job Type</th>
            <th>Priority</th> {/* New Column */}
            <th>Delete</th>
          </tr>
        </thead>


        <tbody>
          {jobs.map((job) => (
            <tr key={job.id}>
              <td>
                <label className={styles.switch}>
                  <input
                    type="checkbox"
                    checked={job.active}
                    onChange={(e) => handleToggleActive(job.id, e.target.checked)}
                    disabled={actionLoading[job.id]?.toggle}
                  />
                  <span className={styles.slider}></span>
                </label>
              </td>
              <td>{job.job_name}</td>
              <td>{job.job_type}</td> {/* Existing Column */}
              <td>{job.priority !== undefined ? job.priority : 'N/A'}</td> {/* New Column */}
              <td>
                <button
                  className={styles.deleteButton}
                  onClick={() => handleDeleteJob(job.id)}
                  title="Delete Job"
                  aria-label="Delete Job"
                  disabled={actionLoading[job.id]?.delete}
                >
                  {actionLoading[job.id]?.delete ? (
                    <ClipLoader size={8} color="#ffffff" />
                  ) : (
                    <FaTimes size={16} />
                  )}
                </button>
              </td>
            </tr>
          ))}
        </tbody>


      </table>
    )}
  </div>
</div>

      {/* Create Job Modal */}
{/* Create Job Modal */}
{isCreateJobModalOpen && (
  <div
    className={styles.modalOverlay}
    onClick={closeCreateJobModal}
  >
    <div
      className={styles.modalContent}
      onClick={(e) => e.stopPropagation()}
    >
      <button
        className={styles.closeModalButton}
        onClick={closeCreateJobModal}
        aria-label="Close Modal"
      >
        &times;
      </button>
      <h3>Create New Job</h3>
      <form onSubmit={handleCreateJobSubmit} className={styles.createJobForm}>
        {/* Job Name */}
        <div className={styles.formGroup}>
          <label htmlFor="jobName">Job Name:</label>
          <input
            type="text"
            id="jobName"
            value={newJobName}
            onChange={(e) => setNewJobName(e.target.value)}
            required
          />
        </div>

        {/* Job Type */}
        <div className={styles.formGroup}>
          <label htmlFor="jobType">Job Type:</label>
          <Select
            options={jobTypeOptions}
            value={jobType}
            onChange={(selectedOption) => {
              setJobType(selectedOption);
              // Reset format transformer fields if job type changes
              if (selectedOption.value !== 'format_transformer') {
                setFormatTransformerFields({
                  format_transformer_selectedColumns: [],
                  format_transformer_selectedTypes: [],
                  format_transformer_transformFormat: '',
                  activateAfterProcessing: false,
                  priority: 1,
                });
              }
            }}
            styles={customStyles}
            placeholder="Select Job Type"
            isClearable
            required
          />
        </div>

        {/* Conditional Rendering for Format Transformer Fields */}
        {jobType && jobType.value === 'format_transformer' && (
          <div className={styles.formatTransformerSection}>
            {/* Select Image Columns */}
            <div className={styles.formGroup}>
              <label htmlFor="selectedColumns">Select Image Columns:</label>
              <Select
                isMulti
                closeMenuOnSelect={false} // Keeps the menu open after selection
                options={imageColumns.map(column => ({ value: column, label: column }))}
                value={formatTransformerFields.format_transformer_selectedColumns.map(column => ({ value: column, label: column }))}
                onChange={(selectedOptions) => {
                  const selected = selectedOptions ? selectedOptions.map(option => option.value) : [];
                  setFormatTransformerFields(prev => ({
                    ...prev,
                    format_transformer_selectedColumns: selected,
                  }));
                }}
                styles={customStyles}
                placeholder="Select Image Columns"
              />
            </div>

            {/* Select Image Types */}
            <div className={styles.formGroup}>
              <label>Select Image Types:</label>
              <div className={styles.checkboxGroup}>
                {imageTypes.map((type) => (
                  <label key={type} className={styles.checkboxLabel}>
                    <input
                      type="checkbox"
                      checked={formatTransformerFields.format_transformer_selectedTypes.includes(type)}
                      onChange={() => {
                        setFormatTransformerFields(prev => {
                          const newTypes = prev.format_transformer_selectedTypes.includes(type)
                            ? prev.format_transformer_selectedTypes.filter(t => t !== type)
                            : [...prev.format_transformer_selectedTypes, type];
                          return { ...prev, format_transformer_selectedTypes: newTypes };
                        });
                      }}
                    />
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </label>
                ))}
              </div>
            </div>

            {/* Transformation Format */}
            <div className={styles.formGroup}>
              <label>Transformation Format:</label>
              <div className={styles.radioGroup}>
                {['webp', 'png', 'jpg'].map(format => (
                  <label key={format} className={styles.radioLabel}>
                    <input
                      type="radio"
                      name="transformFormat"
                      value={format}
                      checked={formatTransformerFields.format_transformer_transformFormat === format}
                      onChange={(e) => {
                        setFormatTransformerFields(prev => ({
                          ...prev,
                          format_transformer_transformFormat: e.target.value,
                        }));
                      }}
                      required
                    />
                    {format.toUpperCase()}
                  </label>
                ))}
              </div>
            </div>

            {/* Activate After Processing - Visible for all except "Selector" */}
            {jobType.value !== 'selector' && (
              <div className={styles.formGroup}>
                <label className={styles.toggleLabel}>
                  Activate After Processing
                  <input
                    type="checkbox"
                    checked={formatTransformerFields.activateAfterProcessing}
                    onChange={(e) => {
                      setFormatTransformerFields(prev => ({
                        ...prev,
                        activateAfterProcessing: e.target.checked,
                      }));
                    }}
                  />
                  <span className={styles.toggleSlider}></span>
                </label>

              </div>
            )}

            {/* Priority - Visible for all job types */}
            <div className={styles.formGroup}>
              <label htmlFor="priority">Priority:</label>
              <input
                type="number"
                id="priority"
                value={formatTransformerFields.priority}
                onChange={(e) => {
                  const value = e.target.value;
                  // Ensure that priority is a positive integer
                  if (/^\d*$/.test(value)) {
                    setFormatTransformerFields(prev => ({
                      ...prev,
                      priority: value ? parseInt(value, 10) : '',
                    }));
                  }
                }}
                min="1"
                required
              />
            </div>
          </div>
        )}

        {/* Additional Fields for Other Job Types */}
        {jobType && jobType.value !== 'format_transformer' && jobType.value !== 'selector' && (
          <>
            {/* Activate After Processing */}
            <div className={styles.formGroup}>
              <label className={styles.toggleLabel}>
                Activate After Processing
                <input
                  type="checkbox"
                  checked={formatTransformerFields.activateAfterProcessing}
                  onChange={(e) => {
                    setFormatTransformerFields(prev => ({
                      ...prev,
                      activateAfterProcessing: e.target.checked,
                    }));
                  }}
                />
                <span className={styles.toggleSlider}></span>
              </label>
            </div>

            {/* Priority */}
            <div className={styles.formGroup}>
              <label htmlFor="priority">Priority:</label>
              <input
                type="number"
                id="priority"
                value={formatTransformerFields.priority}
                onChange={(e) => {
                  const value = e.target.value;
                  // Ensure that priority is a positive integer
                  if (/^\d*$/.test(value)) {
                    setFormatTransformerFields(prev => ({
                      ...prev,
                      priority: value ? parseInt(value, 10) : '',
                    }));
                  }
                }}
                min="1"
                required
              />
            </div>
          </>
        )}

        {/* Submit and Cancel Buttons */}
        <div className={styles.formActions}>
          <button type="submit" className={styles.submitButton}>
            Create Job
          </button>
          <button
            type="button"
            className={styles.cancelButton}
            onClick={closeCreateJobModal}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
)}



          
        </div>
      )}
    </div>
  );
};

export default ImageGenerationScheduler;
