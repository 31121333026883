// src/popups/PopupBulkActionPages/UncropperPage.jsx

import React from 'react';
import Select from 'react-select'; // Ensure this import is present
import PropTypes from 'prop-types';
import styles from './UncropperPage.module.css';
import ClipLoader from "react-spinners/ClipLoader";

const UncropperPage = ({
  allIds,
  filterIds,
  isAllIdsLoading,
  isFilterIdsLoading,
  allIdsError,
  filterIdsError,
  selectedIds = [],
  filters = [],
  selectedClient,
  refreshData,
  onUncropSuccess, // New prop for success callback
}) => {
  // Initialize state with values from localStorage or default values
  const [imageColumns, setImageColumns] = React.useState([]);
  const [selectedColumns, setSelectedColumns] = React.useState(() => {
    try {
      const saved = localStorage.getItem('selectedColumns');
      return saved ? JSON.parse(saved) : [];
    } catch (e) {
      console.error('Error parsing selectedColumns from localStorage:', e);
      return [];
    }
  });
  const [imageTypes, setImageTypes] = React.useState([]);
  const [selectedTypes, setSelectedTypes] = React.useState(() => {
    try {
      const saved = localStorage.getItem('selectedTypes');
      return saved ? JSON.parse(saved) : [];
    } catch (e) {
      console.error('Error parsing selectedTypes from localStorage:', e);
      return [];
    }
  });
  const [selectionScope, setSelectionScope] = React.useState(() => {
    const saved = localStorage.getItem('selectionScope');
    return saved ? saved : null;
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [width, setWidth] = React.useState(() => {
    const saved = localStorage.getItem('uncropWidth');
    return saved ? saved : '';
  });
  const [height, setHeight] = React.useState(() => {
    const saved = localStorage.getItem('uncropHeight');
    return saved ? saved : '';
  });
  const [successCount, setSuccessCount] = React.useState(0);
  const [failureDetails, setFailureDetails] = React.useState(() => {
    try {
      const saved = localStorage.getItem('failureDetails');
      return saved ? JSON.parse(saved) : [];
    } catch (e) {
      console.error('Error parsing failureDetails from localStorage:', e);
      return [];
    }
  });

  // Debugging: Log state changes
  React.useEffect(() => {
    console.log('Selection Scope:', selectionScope);
  }, [selectionScope]);

  React.useEffect(() => {
    console.log('Selected Columns:', selectedColumns);
  }, [selectedColumns]);

  React.useEffect(() => {
    console.log('Selected Types:', selectedTypes);
  }, [selectedTypes]);

  React.useEffect(() => {
    console.log('Uncrop Width:', width);
  }, [width]);

  React.useEffect(() => {
    console.log('Uncrop Height:', height);
  }, [height]);

  // Dynamically generate image columns including 'image_link'
  React.useEffect(() => {
    const generateImageColumns = () => {
      const padNumber = (num) => (num < 10 ? `0${num}` : `${num}`);
      const additional = Array.from({ length: 10 }, (_, i) => `additional_image_link_${padNumber(i + 1)}`);
      const lifestyle = Array.from({ length: 10 }, (_, i) => `lifestyle_image_link_${padNumber(i + 1)}`);
      const custom = Array.from({ length: 10 }, (_, i) => `custom_image_link_${padNumber(i + 1)}`);
      return ['image_link', ...additional, ...lifestyle, ...custom];
    };

    setImageColumns(generateImageColumns());
  }, []);

  // Define all image types relevant to uncropping
  React.useEffect(() => {
    const types = [
      'primary',
      'uncropped',
      'background',
      'upload',
      'import',
    ];
    setImageTypes(types);
  }, []);

  // Persist selectedColumns to localStorage whenever it changes
  React.useEffect(() => {
    try {
      localStorage.setItem('selectedColumns', JSON.stringify(selectedColumns));
    } catch (e) {
      console.error('Error saving selectedColumns to localStorage:', e);
    }
  }, [selectedColumns]);

  // Persist selectedTypes to localStorage whenever it changes
  React.useEffect(() => {
    try {
      localStorage.setItem('selectedTypes', JSON.stringify(selectedTypes));
    } catch (e) {
      console.error('Error saving selectedTypes to localStorage:', e);
    }
  }, [selectedTypes]);

  // Persist selectionScope to localStorage whenever it changes
  React.useEffect(() => {
    if (selectionScope) {
      localStorage.setItem('selectionScope', selectionScope);
    } else {
      localStorage.removeItem('selectionScope');
    }
  }, [selectionScope]);

  // Persist width to localStorage whenever it changes
  React.useEffect(() => {
    if (width) {
      localStorage.setItem('uncropWidth', width);
    } else {
      localStorage.removeItem('uncropWidth');
    }
  }, [width]);

  // Persist height to localStorage whenever it changes
  React.useEffect(() => {
    if (height) {
      localStorage.setItem('uncropHeight', height);
    } else {
      localStorage.removeItem('uncropHeight');
    }
  }, [height]);

  // Persist failureDetails to localStorage whenever it changes
  React.useEffect(() => {
    try {
      localStorage.setItem('failureDetails', JSON.stringify(failureDetails));
    } catch (e) {
      console.error('Error saving failureDetails to localStorage:', e);
    }
  }, [failureDetails]);

  // Handle changes in image column selection
  const handleColumnChange = (selectedOptions) => {
    const columns = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setSelectedColumns(columns);
  };

  // Handle changes in image type selection
  const handleTypeChange = (type) => {
    setSelectedTypes((prev) =>
      prev.includes(type)
        ? prev.filter((t) => t !== type)
        : [...prev, type]
    );
  };

  // Handle changes in selection scope
  const handleScopeChange = (scope) => {
    setSelectionScope(scope);
  };

  // Handle width change
  const handleWidthChange = (e) => {
    const value = e.target.value;
    // Optionally, add validation for numeric input
    if (/^\d*$/.test(value)) {
      setWidth(value);
    }
  };

  // Handle height change
  const handleHeightChange = (e) => {
    const value = e.target.value;
    // Optionally, add validation for numeric input
    if (/^\d*$/.test(value)) {
      setHeight(value);
    }
  };

  // Handle bulk uncrop action
  const handleUncrop = async () => {
    console.log('Uncrop action initiated');

    if (!selectionScope) {
      setFailureDetails([{ error: 'Please select a scope before proceeding.', id: 'N/A', column: 'N/A', image_type: 'N/A', image_url: 'N/A' }]);
      return;
    }

    if (!width || !height) {
      setFailureDetails([{ error: 'Please provide both width and height.', id: 'N/A', column: 'N/A', image_type: 'N/A', image_url: 'N/A' }]);
      return;
    }

    const widthInt = parseInt(width, 10);
    const heightInt = parseInt(height, 10);

    if (isNaN(widthInt) || isNaN(heightInt) || widthInt <= 0 || heightInt <= 0) {
      setFailureDetails([{ error: 'Width and Height must be positive integers.', id: 'N/A', column: 'N/A', image_type: 'N/A', image_url: 'N/A' }]);
      return;
    }

    let idsToUncrop = [];
    if (selectionScope === 'selectAll') {
      idsToUncrop = allIds;
    } else if (selectionScope === 'selectCurrentView') {
      idsToUncrop = filterIds;
    } else if (selectionScope === 'selectSelectedItems') {
      idsToUncrop = selectedIds;
    }

    if (idsToUncrop.length === 0) {
      setFailureDetails([{ error: 'No items found for the selected scope.', id: 'N/A', column: 'N/A', image_type: 'N/A', image_url: 'N/A' }]);
      return;
    }

    if (selectedColumns.length === 0) {
      setFailureDetails([{ error: 'Please select at least one image column', id: 'N/A', column: 'N/A', image_type: 'N/A', image_url: 'N/A' }]);
      return;
    }

    // Estimate the number of uncrops
    const estimatedUncrops = idsToUncrop.length * selectedColumns.length;
    const estimatedCredits = estimatedUncrops * 100;

    // Show confirmation dialog
    const userConfirmed = window.confirm(
      `You are about to uncrop ${estimatedUncrops} images to ${width}x${height} dimensions, which will cost up to ${estimatedCredits} credits. Do you want to proceed?`
    );

    if (!userConfirmed) {
      return; // User cancelled the operation
    }

    const requestData = {
      client_name: selectedClient,
      ids: idsToUncrop,
      image_columns: selectedColumns,
      image_types: selectedTypes,
      width: widthInt,
      height: heightInt,
    };

    setIsLoading(true);
    setSuccessCount(0);
    setFailureDetails([]); // Clear previous failure details before new uncrop

    try {
      const API_BASE_URL = process.env.REACT_APP_API_URL_IMAGE;

      if (!API_BASE_URL) {
        throw new Error('REACT_APP_API_URL_IMAGE is not defined in the environment variables.');
      }

      const response = await fetch(`${API_BASE_URL}/uncrop-images-bulk`, { // Ensure the endpoint accepts width and height
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(requestData),
      });

      const responseData = await response.json();

      if (response.ok || response.status === 207) { // 207: Multi-Status
        setSuccessCount(responseData.success || 0);
        setFailureDetails(responseData.failures || []);

        // Show alert with credits deducted if available
        if (responseData.credits_deducted) {
          alert(`Successfully uncropped ${responseData.success} images. Credits deducted: ${responseData.credits_deducted}. New credit balance: ${responseData.new_credit_balance}.`);
        } else {
          alert(`Successfully uncropped ${responseData.success} images.`);
        }

        if (refreshData) refreshData();

        if (onUncropSuccess) {
          onUncropSuccess(); // Call the callback to update credits
        }
      } else {
        setFailureDetails([{ error: responseData.error || 'Unknown error occurred during uncropping.', id: 'N/A', column: 'N/A', image_type: 'N/A', image_url: 'N/A' }]);
        alert(`Uncrop failed: ${responseData.error}`);
      }
    } catch (error) {
      setFailureDetails([{ error: 'An error occurred during the bulk image uncropping.', id: 'N/A', column: 'N/A', image_type: 'N/A', image_url: 'N/A' }]);
      console.error('Bulk uncrop error:', error);
      alert('An error occurred during the bulk image uncropping.');
    } finally {
      setIsLoading(false);
    }
  };

  // Options for Select components
  const imageColumnOptions = imageColumns.map(column => ({ value: column, label: column }));

  return (
    <div className={styles.pageContainer}>
      {/* Header Section: Description and Image Side by Side */}
      <div className={styles.headerSection}>
        {/* Description */}
        <div className={styles.description}>
          <h2>Uncrop Images in Bulk (100 credits)</h2>
          <p>
            This tab allows you to uncrop image formats in bulk. 
            Please choose the relevant image columns, types, uncrop dimensions, and scope of items that should be processed.
          </p>
        </div>

        {/* Header Image */}
        <div className={styles.headerImage}>
          <img 
            src="https://storage.googleapis.com/quantum-feed-engine/workbench/application-images/img-uncrop-removebg-preview.png" 
            alt="Image Uncropping Overview" 
          />
        </div>
      </div>

      {/* Select Image Columns */}
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h4>Select Image Columns</h4>
          <div className={styles.tooltip}>
            <i className="fas fa-info-circle"></i>
            <span className={styles.tooltiptext}>
              Choose the image columns you want to uncrop.
            </span>
          </div>
        </div>
        <Select
          isMulti
          options={imageColumnOptions}
          className={styles.multiSelect}
          classNamePrefix="select"
          onChange={handleColumnChange}
          value={imageColumnOptions.filter(option => selectedColumns.includes(option.value))}
          placeholder="Select image columns..."
          closeMenuOnSelect={false}
        />
      </div>


      {/* Select Scope */}
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h4>Select Scope</h4>
          <div className={styles.tooltip}>
            <i className="fas fa-info-circle"></i>
            <span className={styles.tooltiptext}>
              Choose the scope of items you want to apply the uncropping to.
            </span>
          </div>
        </div>
        <div className={styles.scopeGroup}>
          <button
            type="button"
            className={`${styles.scopeButton} ${selectionScope === 'selectSelectedItems' ? styles.activeScopeButton : ''}`}
            onClick={() => handleScopeChange('selectSelectedItems')}
          >
            Selected IDs ({selectedIds.length})
          </button>
          <button
            type="button"
            className={`${styles.scopeButton} ${selectionScope === 'selectCurrentView' ? styles.activeScopeButton : ''}`}
            onClick={() => handleScopeChange('selectCurrentView')}
          >
            Filtered IDs ({filterIds.length})
          </button>
          <button
            type="button"
            className={`${styles.scopeButton} ${selectionScope === 'selectAll' ? styles.activeScopeButton : ''}`}
            onClick={() => handleScopeChange('selectAll')}
          >
            All IDs ({allIds.length})
          </button>
        </div>
      </div>

      {/* Uncrop Dimensions and Action Button */}
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h4>Uncrop Dimensions</h4>
          <div className={styles.tooltip}>
            <i className="fas fa-info-circle"></i>
            <span className={styles.tooltiptext}>
              Enter the desired width and height for uncropping the selected images.
            </span>
          </div>
        </div>
        <div className={styles.dimensionsAndButton}>
          <div className={styles.inputGroup}>
            <input
              type="text"
              placeholder="Width"
              value={width}
              onChange={handleWidthChange}
              className={styles.dimensionInput}
            />
            <input
              type="text"
              placeholder="Height"
              value={height}
              onChange={handleHeightChange}
              className={styles.dimensionInput}
            />
          </div>
          <button
            type="button"
            className={styles.applyButton}
            onClick={handleUncrop}
            disabled={
              isLoading ||
              !selectionScope ||
              selectedColumns.length === 0 ||
              !width ||
              !height
            }
          >
            {isLoading ? 'Uncropping...' : 'Uncrop Images'}
          </button>
        </div>
      </div>

      {/* Loading Indicator */}
      {isLoading && (
        <div className={styles.loadingOverlay}>
          <ClipLoader size={50} color={"#4758EB"} loading={true} />
          <p>Uncropping images...</p>
        </div>
      )}

      {/* Uncrop Status */}
      {!isLoading && (successCount > 0 || failureDetails.length > 0) && (
        <div className={styles.resultSummary}>
          <h4>Uncrop Summary</h4>
          {successCount > 0 && <p>Successfully uncropped images: {successCount}</p>}
          {failureDetails.length > 0 && (
            <>
              <p>Failed uncrops: {failureDetails.length}</p>
              <div className={styles.failureTable}>
                <h4>Failed Uncrops</h4>
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Column</th>
                      <th>Image Type</th>
                      <th>Image Preview</th>
                      <th>Error</th>
                    </tr>
                  </thead>
                  <tbody>
                    {failureDetails.map((failure, index) => (
                      <tr key={index}>
                        <td>{failure.id || 'N/A'}</td>
                        <td>{failure.column || 'N/A'}</td>
                        <td>{failure.image_type || 'N/A'}</td>
                        <td>
                          {failure.image_url && failure.image_url !== 'N/A' ? (
                            <div className={styles.imageContainer}>
                              <img 
                                src={failure.image_url} 
                                alt="Image preview"
                                onError={(e) => { e.target.onerror = null; e.target.src="path/to/placeholder.png"; }} // Optional: handle image load errors
                              />
                            </div>
                          ) : 'N/A'}
                        </td>
                        <td>{failure.error || 'N/A'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

// Define PropTypes for type checking
UncropperPage.propTypes = {
  allIds: PropTypes.array.isRequired,
  filterIds: PropTypes.array.isRequired,
  isAllIdsLoading: PropTypes.bool.isRequired,
  isFilterIdsLoading: PropTypes.bool.isRequired,
  allIdsError: PropTypes.string,
  filterIdsError: PropTypes.string,
  selectedIds: PropTypes.array,
  filters: PropTypes.array,
  selectedClient: PropTypes.string.isRequired,
  refreshData: PropTypes.func.isRequired,
  onUncropSuccess: PropTypes.func.isRequired, // New PropType
};

// Removed React.memo for debugging purposes
export default UncropperPage;
