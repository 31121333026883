// src/popups/PopupBulkActionPages/ImporterPage.jsx

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './ImporterPage.module.css';
import Select from 'react-select';
import ClipLoader from "react-spinners/ClipLoader"; // For loading indicators

// Define the base API URL from environment variables
const API_BASE_URL = process.env.REACT_APP_API_URL_IMAGE;

const ImporterPage = ({
  allIds,
  filterIds,
  selectedIds,
  selectedClient,
  filters,
  refreshData,
  isAllIdsLoading,
  isFilterIdsLoading,
  allIdsError,
  filterIdsError,
}) => {
  const [importType, setImportType] = useState('internal'); // 'internal' or 'file'

  // State variables for Internal Import
  const [fromField, setFromField] = useState(null);
  const [toField, setToField] = useState(null);
  const [fromImageType, setFromImageType] = useState(null);
  const [idSelection, setIdSelection] = useState(null); // 'all', 'filtered', 'selected'

  const [isApplying, setIsApplying] = useState(false);
  const [availableFields, setAvailableFields] = useState([]);
  const [imageColumns, setImageColumns] = useState([]);
  const [imageTypes, setImageTypes] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  // States for File Import Preview
  const [previewData, setPreviewData] = useState(null);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [previewError, setPreviewError] = useState(null);

  // Store column order explicitly
  const [previewColumns, setPreviewColumns] = useState([]);

  // Column selections for File Import
  const [idColumn, setIdColumn] = useState(null);
  const [imageLinkColumn, setImageLinkColumn] = useState(null);
  const [fileToField, setFileToField] = useState(null);

  // Ref for the file input
  const fileInputRef = useRef(null);

  // Dynamically generate image columns including 'image_link'
  useEffect(() => {
    const generateImageColumns = () => {
      const padNumber = (num) => (num < 10 ? `0${num}` : `${num}`);
      const additional = Array.from({ length: 10 }, (_, i) => `additional_image_link_${padNumber(i + 1)}`);
      const lifestyle = Array.from({ length: 10 }, (_, i) => `lifestyle_image_link_${padNumber(i + 1)}`);
      const custom = Array.from({ length: 10 }, (_, i) => `custom_image_link_${padNumber(i + 1)}`);
      return ['image_link', ...additional, ...lifestyle, ...custom];
    };

    setImageColumns(generateImageColumns());
  }, []);

  // Define all image types
  useEffect(() => {
    const types = [
      'primary',
      'uncropped',
      'background',
      'upload',
      'import',
    ];
    setImageTypes(types);
  }, []);

  // Fetch available fields (Assuming 'filters' prop contains field information)
  useEffect(() => {
    if (filters && Array.isArray(filters)) {
      // Extract field names from filters
      const fields = filters.map(filter => filter.fieldName); // Adjust based on your filters structure
      setAvailableFields(fields);
    }
  }, [filters]);

  // Auto-select common columns (Optional)
  useEffect(() => {
    if (previewColumns.length > 0) {
      // Auto-select ID column if possible
      const autoSelectId = previewColumns.find(col => col.toLowerCase() === 'id' || col.toLowerCase() === 'identifier');
      if (autoSelectId) {
        setIdColumn({ value: autoSelectId, label: autoSelectId });
        console.log('Auto-selected ID Column:', autoSelectId);
      }

      // Auto-select Image Link column if possible
      const autoSelectImageLink = previewColumns.find(col => col.toLowerCase() === 'image_link' || col.toLowerCase() === 'imageurl');
      if (autoSelectImageLink) {
        setImageLinkColumn({ value: autoSelectImageLink, label: autoSelectImageLink });
        console.log('Auto-selected Image Link Column:', autoSelectImageLink);
      }
    }
  }, [previewColumns]);

  // Handler for toggling import type
  const handleToggle = (type) => {
    setImportType(type);
    // Reset fields when switching import types
    setFromField(null);
    setToField(null);
    setFromImageType(null);
    setIdSelection(null);
    // Reset file import states
    setSelectedFile(null);
    setPreviewData(null);
    setPreviewColumns([]);
    setPreviewError(null);
    setIdColumn(null); // Reset selections
    setImageLinkColumn(null);
    setFileToField(null); // Reset file "to" field
    // Reset the file input's value
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  // Handler for File Selection and Preview
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setPreviewData(null);
    setPreviewColumns([]);
    setPreviewError(null);
    setIdColumn(null); // Reset selections
    setImageLinkColumn(null);
    setFileToField(null); // Reset file "to" field

    if (file) {
      if (!selectedClient) {
        setPreviewError('Client name is not selected.');
        // Reset the file input since client name is missing
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        return;
      }

      setIsPreviewLoading(true);
      try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('client_name', selectedClient);

        const response = await fetch(`${API_BASE_URL}/api/file-import?preview=true`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: formData,
        });

        // **Debugging Logs**
        console.log('Response Status:', response.status);
        console.log('Response Headers:', response.headers.get('Content-Type'));

        if (response.ok) {
          const data = await response.json();
          setPreviewData(data.preview);
          if (data.preview.length > 0) {
            setPreviewColumns(data.columns);
          }
        } else {
          // **Attempt to parse the response as text if JSON parsing fails**
          let errorText;
          try {
            const errorData = await response.json();
            errorText = errorData.error || 'Failed to fetch preview.';
          } catch {
            errorText = await response.text();
          }
          setPreviewError(errorText);
        }
      } catch (error) {
        console.error('Preview error:', error);
        setPreviewError('An error occurred while fetching the preview.');
      } finally {
        setIsPreviewLoading(false);
      }
    }
  };

  // Handler for Actual Import
  const handleFileUpload = async () => {
    if (!selectedFile) {
      return;
    }

    if (!idColumn || !imageLinkColumn) {
      alert('Please select both ID and Image Link columns.');
      return;
    }

    if (!fileToField) {
      alert('Please select To field.');
      return;
    }

    console.log('Selected ID Column:', idColumn.value);
    console.log('Selected Image Link Column:', imageLinkColumn.value);
    console.log('Selected To Field:', fileToField.value);

    setIsApplying(true);

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('client_name', selectedClient);
    formData.append('id_column', idColumn.value);
    formData.append('image_link_column', imageLinkColumn.value);
    formData.append('to_field', fileToField.value);
    formData.append('to_image_type', 'import'); // Set to 'import' by default

    // Log all FormData entries
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/file-import-apply`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData,
      });

      // **Debugging Logs**
      console.log('Response Status:', response.status);
      console.log('Response Headers:', response.headers.get('Content-Type'));

      if (response.ok) {
        const data = await response.json();
        alert(data.message || 'File import successful.');
        setPreviewData(null); // Clear preview after successful import
        setPreviewColumns([]);
        setSelectedFile(null); // Clear selected file
        setIdColumn(null); // Reset selections
        setImageLinkColumn(null);
        setFileToField(null); // Reset file "to" field
        if (refreshData) refreshData();
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.error || 'Failed to import file.'}`);
      }
    } catch (error) {
      console.error('File import error:', error);
      alert('An error occurred during the file import.');
    } finally {
      setIsApplying(false);
    }
  };

  // Handler for Apply button in Internal Import
  const handleApply = async () => {
    if (!fromField || !toField || !fromImageType || !idSelection) {
      alert('Please select From field, To field, Image Type, and ID selection.');
      return;
    }

    let idsToProcess = [];
    if (idSelection === 'all') {
      idsToProcess = allIds;
    } else if (idSelection === 'filtered') {
      idsToProcess = filterIds;
    } else if (idSelection === 'selected') {
      idsToProcess = selectedIds;
    }

    if (idsToProcess.length === 0) {
      alert('No IDs available for the selected scope.');
      return;
    }

    const requestData = {
      client_name: selectedClient,
      ids: idsToProcess,
      from_field: fromField.value,
      to_field: toField.value,
      from_image_type: fromImageType,
      to_image_type: 'import', // Set to 'import' by default
      import_type: importType,
    };

    setIsApplying(true);

    try {
      const response = await fetch(`${API_BASE_URL}/api/internal-import`, { // Updated endpoint
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        alert('Internal import successful.');
        if (refreshData) refreshData();
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.error || 'Failed to perform internal import.'}`);
      }
    } catch (error) {
      console.error('Internal import error:', error);
      alert('An error occurred during the internal import.');
    } finally {
      setIsApplying(false);
    }
  };

  // Options for Select components
  const fieldOptions = imageColumns.map(column => ({ value: column, label: column }));
  const idSelectionOptions = [
    { value: 'selected', label: `Selected IDs (${selectedIds.length})` },
    { value: 'filtered', label: `Filtered IDs (${filterIds.length})` },
    { value: 'all', label: `All IDs (${allIds.length})` },
  ];

  // Compute whether the Apply button should be disabled
  const isApplyDisabled = importType === 'file'
    ? (
      !selectedFile ||
      !idColumn ||
      !imageLinkColumn ||
      !fileToField ||
      isApplying
    )
    : isApplying || !fromField || !toField || !fromImageType || !idSelection;

  return (
    <div className={styles.pageContainer}>
      <h2>Importer Page (Free)</h2>
      <p>Selected Client: {selectedClient}</p>

      {/* Toggle Switch */}
      <div className={styles.toggleSwitch}>
        <button
          className={`${styles.toggleButton} ${
            importType === 'internal' ? styles.internalActiveButton : styles.internalMode
          }`}
          onClick={() => handleToggle('internal')}
        >
          Internal Import
        </button>
        <button
          className={`${styles.toggleButton} ${
            importType === 'file' ? styles.fileActiveButton : styles.fileMode
          }`}
          onClick={() => handleToggle('file')}
        >
          Based on File
        </button>
      </div>

      {/* Internal Import View */}
      {importType === 'internal' && (
        <div className={styles.internalImportContainer}>
          {/* From Field Group */}
          <div className={styles.fieldGroup}>
            <label className={styles.fieldLabel}>From:</label>
            <div className={styles.fieldRow}>
              <div className={styles.selectContainer}>
                <Select
                  options={fieldOptions}
                  value={fromField}
                  onChange={setFromField}
                  placeholder="Select source field..."
                  className={styles.select}
                  classNamePrefix="select"
                />
              </div>
              <div className={styles.imageTypeContainer}>
                <span className={styles.imageTypeLabel}>Image Type:</span>
                <div className={styles.tooltip}>
                  <i className="fas fa-info-circle"></i>
                  <span className={styles.tooltiptext}>
                    Choose the type of image to import from the source field.
                  </span>
                </div>
                <div className={styles.radioGroup}>
                  {imageTypes.map((type) => (
                    <label key={`from-${type}`} className={styles.radioLabel}>
                      <input
                        type="radio"
                        name="fromImageType"
                        value={type}
                        checked={fromImageType === type}
                        onChange={() => setFromImageType(type)}
                      />
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* To Field Group */}
          <div className={styles.fieldGroup}>
            <label className={styles.fieldLabel}>To:</label>
            <div className={styles.fieldRow}>
              <div className={styles.selectContainer}>
                <Select
                  options={fieldOptions}
                  value={toField}
                  onChange={setToField}
                  placeholder="Select target field..."
                  className={styles.select}
                  classNamePrefix="select"
                />
              </div>
              <div className={styles.imageTypeContainer}>
                <span className={styles.imageTypeLabel}>Image Type:</span>
                <div className={styles.tooltip}>
                  <i className="fas fa-info-circle"></i>
                  <span className={styles.tooltiptext}>
                    This image type will be placed in the "import" placeholder.
                  </span>
                </div>
                <span className={styles.importPlaceholder}>
                  This will be placed in the "import" placeholder.
                </span>
              </div>
            </div>
          </div>

          {/* ID Selection */}
          <div className={styles.fieldGroup}>
            <label className={styles.fieldLabel}>For / ID Selection:</label>
            <div className={styles.idSelectionContainer}>
              <div className={styles.scopeGroup}>
                {idSelectionOptions.map(option => (
                  <button
                    key={option.value}
                    type="button"
                    className={`${styles.scopeButton} ${idSelection === option.value ? styles.activeScopeButton : ''}`}
                    onClick={() => setIdSelection(option.value)}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Apply Button */}
          <div className={styles.buttonGroup}>
            <button
              className={styles.applyButton}
              onClick={handleApply}
              disabled={isApplyDisabled} // Updated: Disable button based on isApplyDisabled
            >
              {isApplying ? 'Applying...' : 'Apply'}
            </button>
          </div>

          {/* Loading Indicator */}
          {isApplying && (
            <div className={styles.loadingOverlay}>
              <ClipLoader size={50} color={"#123abc"} loading={true} />
              <p>Applying internal import...</p>
            </div>
          )}
        </div>
      )}

      {/* Based on File View */}
      {importType === 'file' && (
        <div className={styles.fileImportContainer}>
          <h4>Import Based on File</h4>

          {/* Step 1: Upload File */}
          <div className={styles.stepContainer}>
            <div className={styles.stepHeader}>
              <div className={styles.stepNumber}>1</div>
              <h3 className={styles.stepTitle}>Upload File</h3>
            </div>
            <p>Select a file to import data (CSV, TSV, Excel):</p>
            <input
              type="file"
              accept=".csv, .xlsx, .xls, .tsv"
              className={styles.fileInput}
              onChange={handleFileChange}
              ref={fileInputRef} // Attach the ref to the file input
            />

            {/* Preview Loading Indicator */}
            {isPreviewLoading && (
              <div className={styles.loadingPreview}>
                <ClipLoader size={30} color={"#123abc"} loading={true} />
                <p>Loading preview...</p>
              </div>
            )}

            {previewError && (
              <div className={styles.errorMessage}>
                <p>Error: {previewError}</p>
              </div>
            )}
          </div>

          {/* Step 2: Preview and Select Columns */}
          {previewData && (
            <>
              <div className={styles.stepContainer}>
                <div className={styles.stepHeader}>
                  <div className={styles.stepNumber}>2</div>
                  <h3 className={styles.stepTitle}>Select Columns</h3>
                </div>
                <p>Preview the data and select the ID and Image Link columns:</p>
                <div className={styles.previewTableContainer}>
                  <table className={styles.previewTable}>
                    <thead>
                      <tr>
                        {previewColumns.map((key) => (
                          <th key={key}>{key}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {previewData.map((row, index) => (
                        <tr key={index}>
                          {previewColumns.map((key) => (
                            <td key={key}>{row[key]}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* Column Selection Dropdowns */}
                <div className={styles.columnSelection}>
                  <div className={styles.selectWrapper}>
                    <label className={styles.selectionLabel}>
                      Select ID Column:
                      <div className={styles.tooltip}>
                        <i className="fas fa-info-circle"></i>
                        <span className={styles.tooltiptext}>
                          Choose the column that uniquely identifies each record.
                        </span>
                      </div>
                    </label>
                    <Select
                      options={previewColumns.map(column => ({ value: column, label: column }))}
                      value={idColumn}
                      onChange={(selected) => {
                        setIdColumn(selected);
                        console.log('ID Column selected:', selected);
                      }}
                      placeholder="Select ID column..."
                      className={styles.select}
                      classNamePrefix="select"
                    />
                  </div>

                  <div className={styles.selectWrapper}>
                    <label className={styles.selectionLabel}>
                      Select Image Link Column:
                      <div className={styles.tooltip}>
                        <i className="fas fa-info-circle"></i>
                        <span className={styles.tooltiptext}>
                          Choose the column that contains image URLs or paths.
                        </span>
                      </div>
                    </label>
                    <Select
                      options={previewColumns.map(column => ({ value: column, label: column }))}
                      value={imageLinkColumn}
                      onChange={(selected) => {
                        setImageLinkColumn(selected);
                        console.log('Image Link Column selected:', selected);
                      }}
                      placeholder="Select Image Link column..."
                      className={styles.select}
                      classNamePrefix="select"
                    />
                  </div>
                </div>
              </div>

              {/* Step 3: Select Destination Field */}
              <div className={styles.stepContainer}>
                <div className={styles.stepHeader}>
                  <div className={styles.stepNumber}>3</div>
                  <h3 className={styles.stepTitle}>Select Destination Field</h3>
                </div>
                <p>Choose where the image links should be placed in the database:</p>
                <div className={styles.columnSelection}>
                  <div className={styles.toFieldContainer}>
                    <label className={styles.toLabel}>
                      To Field:
                      <div className={styles.tooltip}>
                        <i className="fas fa-info-circle"></i>
                        <span className={styles.tooltiptext}>
                          Choose the target field for the import.
                        </span>
                      </div>
                    </label>
                    <Select
                      options={imageColumns.map(column => ({ value: column, label: column }))}
                      value={fileToField}
                      onChange={(selected) => {
                        setFileToField(selected);
                        console.log('To Field selected:', selected);
                      }}
                      placeholder="Select To field..."
                      className={styles.select}
                      classNamePrefix="select"
                    />
                  </div>
                  <div className={styles.imageTypeContainer}>
                    <span className={styles.imageTypeLabel}>Image Type:</span>
                    <span className={styles.importPlaceholder}>
                      This will be placed in the "import" placeholder.
                    </span>
                  </div>
                </div>
              </div>

              {/* Apply Button */}
              <div className={styles.buttonGroup}>
                <button
                  className={styles.applyButton}
                  onClick={handleFileUpload}
                  disabled={isApplyDisabled}
                >
                  {isApplying ? 'Uploading...' : 'Apply'}
                </button>
              </div>
            </>
          )}

          {/* Loading Indicator for Import */}
          {isApplying && (
            <div className={styles.loadingOverlay}>
              <ClipLoader size={50} color={"#123abc"} loading={true} />
              <p>Uploading and processing the file...</p>
            </div>
          )}
        </div>
      )}

    </div>
  );
};

// Define PropTypes for type checking
ImporterPage.propTypes = {
  allIds: PropTypes.array.isRequired,
  filterIds: PropTypes.array.isRequired,
  selectedIds: PropTypes.array.isRequired,
  selectedClient: PropTypes.string.isRequired,
  filters: PropTypes.array.isRequired, // Assuming filters contain field information
  refreshData: PropTypes.func.isRequired,
  isAllIdsLoading: PropTypes.bool.isRequired,
  isFilterIdsLoading: PropTypes.bool.isRequired,
  allIdsError: PropTypes.string,
  filterIdsError: PropTypes.string,
};

export default React.memo(ImporterPage);
