// src/popups/PopupTextPublish.js

import React, { useState, useEffect } from 'react';
import { ClipLoader } from 'react-spinners';
import { FiDownload, FiEye, FiX } from 'react-icons/fi';
import styles from './css/PopupTextPublish.module.css';
import axios from 'axios';
import PropTypes from 'prop-types';

const PopupTextPublish = ({
  showFeedPopup,
  handlePopupOverlayClick,
  handleCloseFeedPopup,
  handlePublishSupplementaryFeed,
  disableEditing,
  publishing,
  savingCells,
  selectedClient,
  token,
}) => {
  // State for last updated date
  const [lastUpdated, setLastUpdated] = useState('Loading...');
  
  // State for toggle
  const [includeItemGroupId, setIncludeItemGroupId] = useState(true);
  
  // State for success and error messages
  const [message, setMessage] = useState({ type: '', text: '' });

  // Define the feed links with labels and URL templates
  const feedLinks = [
    {
      id: 1,
      label: "Original XML + QFE Fields",
      description: "Includes all QFE fields",
      downloadUrl: `https://storage.googleapis.com/quantum-feed-engine/${selectedClient}/original_xml_original_qfe.xml`,
      viewUrlTemplate: `https://feedviewer.s360digital.com/?url=${encodeURIComponent(`https://storage.googleapis.com/quantum-feed-engine/${selectedClient}/original_xml_original_qfe.xml`)}`
    },
    {
      id: 2,
      label: "Only QFE Fields",
      description: "Includes only QFE fields",
      downloadUrl: `https://storage.googleapis.com/quantum-feed-engine/${selectedClient}/original_qfe.xml`,
      viewUrlTemplate: `https://feedviewer.s360digital.com/?url=${encodeURIComponent(`https://storage.googleapis.com/quantum-feed-engine/${selectedClient}/original_qfe.xml`)}`
    },
    {
      id: 3,
      label: "Only QFE Fields (No QFE Names)",
      description: "Excludes QFE names",
      downloadUrl: `https://storage.googleapis.com/quantum-feed-engine/${selectedClient}/modified_qfe.xml`,
      viewUrlTemplate: `https://feedviewer.s360digital.com/?url=${encodeURIComponent(`https://storage.googleapis.com/quantum-feed-engine/${selectedClient}/modified_qfe.xml`)}`
    },
  ];

  // Handlers for preview actions
  const handleDownloadClick = (url) => {
    if (!selectedClient) {
      setMessage({ type: 'error', text: 'Client name is not defined.' });
      return;
    }
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleViewFeedClick = (urlTemplate) => {
    if (!selectedClient) {
      setMessage({ type: 'error', text: 'Client name is not defined.' });
      return;
    }
    window.open(urlTemplate, '_blank', 'noopener,noreferrer');
  };

  // Function to fetch the last updated timestamp
  const fetchLastUpdated = async () => {
    if (!selectedClient) {
      setLastUpdated('Client not selected.');
      return;
    }
  
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-feed-text-update-date`, {
        params: { client_name: selectedClient },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const { feed_text_update_date } = response.data;
  
      if (feed_text_update_date === 'Feed never published') {
        setLastUpdated(feed_text_update_date);
      } else if (feed_text_update_date) {
        const date = new Date(feed_text_update_date);
        setLastUpdated(date.toLocaleString());
      } else {
        setLastUpdated('Never');
      }
    } catch (error) {
      console.error('Error fetching last updated date:', error);
      setLastUpdated('Error fetching date');
    }
  };

  // Fetch the last updated timestamp when the popup is shown
  useEffect(() => {
    if (showFeedPopup) {
      fetchLastUpdated();
      setMessage({ type: '', text: '' }); // Reset messages when popup opens
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFeedPopup]);

  // Handler for publishing the feed
  const handlePublish = async () => {
    if (!selectedClient) {
      setMessage({ type: 'error', text: 'Client name is not defined.' });
      return;
    }
  
    try {
      await handlePublishSupplementaryFeed(includeItemGroupId);
      setMessage({ type: 'success', text: 'Feed published successfully!' });
      fetchLastUpdated();
    } catch (error) {
      console.error('Error publishing feed:', error);
      // Check if the error response has a specific message
      const errorMsg = error.response?.data?.error || 'Failed to publish feed. Please try again.';
      setMessage({ type: 'error', text: errorMsg });
    }
  };

  // Early return if the popup should not be shown
  if (!showFeedPopup) return null;

  return (
    <div
      className={styles.overlay}
      onClick={handlePopupOverlayClick}
      role="dialog"
      aria-modal="true"
      aria-labelledby="popup-title"
    >
      <div className={styles.popupContainer} onClick={(e) => e.stopPropagation()}>
        {/* Header */}
        <div className={styles.header}>
          <h2 id="popup-title">Feed Management</h2>
          <button
            className={styles.closeButton}
            onClick={handleCloseFeedPopup}
            aria-label="Close Popup"
          >
            <FiX />
          </button>
        </div>

        {/* Last Updated Section */}
        <div className={styles.lastUpdatedSection}>
          <strong>Last Updated:</strong> {lastUpdated || 'Never'}
        </div>

        {/* Controls Section */}
        <div className={styles.controlsSection}>
          {/* Toggle Button */}
          <div className={styles.toggleContainer}>
            <label htmlFor="includeItemGroupId" className={styles.toggleLabel}>
              Include Item Group ID
            </label>
            <input
              type="checkbox"
              id="includeItemGroupId"
              checked={includeItemGroupId}
              onChange={(e) => setIncludeItemGroupId(e.target.checked)}
              className={styles.toggleInput}
              aria-checked={includeItemGroupId}
            />
          </div>
          <button
            onClick={handlePublish}
            disabled={disableEditing || publishing || savingCells.length > 0 || !selectedClient}
            className={styles.publishButton}
            aria-label="Publish Feed"
          >
            {publishing ? (
              <ClipLoader size={20} color={"#FFFFFF"} loading={publishing} />
            ) : (
              <>
                Publish Feed
              </>
            )}
          </button>
        </div>

        {/* Message Display */}
        {message.text && (
          <div
            className={`${styles.message} ${
              message.type === 'success' ? styles.success : styles.error
            }`}
            role="alert"
          >
            {message.text}
          </div>
        )}


        {/* Divider */}
        <hr className={styles.divider} />

        {/* Feed Links Visualization */}
        <div className={styles.section}>
          <h3>Open Feeds Directly</h3>
          <div className={styles.feedPreviewContainer}>
            {feedLinks.map((feed) => (
              <div key={feed.id} className={styles.feedItem}>
                <div className={styles.feedInfo}>
                  <h4>{feed.label}</h4>
                  <p>{feed.description}</p>
                </div>
                <div className={styles.buttonGroup}>
                  <button
                    className={styles.actionButton}
                    onClick={() => handleDownloadClick(feed.downloadUrl)}
                    disabled={!selectedClient}
                    aria-label={`Download ${feed.label}`}
                  >
                    <FiDownload className={styles.buttonIcon} />
                    View in browser
                  </button>
                  <button
                    className={styles.actionButton}
                    onClick={() => handleViewFeedClick(feed.viewUrlTemplate)}
                    disabled={!selectedClient}
                    aria-label={`View ${feed.label}`}
                  >
                    <FiEye className={styles.buttonIcon} />
                    Open in Feed Viewer
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// PropTypes for type checking
PopupTextPublish.propTypes = {
  showFeedPopup: PropTypes.bool.isRequired,
  handlePopupOverlayClick: PropTypes.func.isRequired,
  handleCloseFeedPopup: PropTypes.func.isRequired,
  handlePublishSupplementaryFeed: PropTypes.func.isRequired,
  disableEditing: PropTypes.bool,
  publishing: PropTypes.bool,
  savingCells: PropTypes.array,
  selectedClient: PropTypes.string,
  token: PropTypes.string.isRequired,
};

export default PopupTextPublish;
