// src/popups/PopupBulkActionPages/SelectorPage.jsx

import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import styles from './SelectorPage.module.css';
import ClipLoader from "react-spinners/ClipLoader"; // Import ClipLoader for loading indicators

const SelectorPage = ({
  allIds,
  filterIds,
  isAllIdsLoading,
  isFilterIdsLoading,
  allIdsError,
  filterIdsError,
  selectedIds = [],
  filters = [],
  selectedClient,
  refreshData,
}) => {
  const [imageColumns, setImageColumns] = React.useState([]);
  const [selectedColumns, setSelectedColumns] = React.useState([]);
  const [imageTypes, setImageTypes] = React.useState([]);
  const [selectedTypes, setSelectedTypes] = React.useState([]);
  const [selectionScope, setSelectionScope] = React.useState(null); // No default selection
  const [isLoading, setIsLoading] = React.useState(false);
  const [actionType, setActionType] = React.useState('select');

  // Dynamically generate image columns including 'image_link'
  React.useEffect(() => {
    const generateImageColumns = () => {
      const additional = Array.from({ length: 10 }, (_, i) => `additional_image_link_${i + 1 < 10 ? `0${i + 1}` : `${i + 1}`}`);
      const lifestyle = Array.from({ length: 10 }, (_, i) => `lifestyle_image_link_${i + 1 < 10 ? `0${i + 1}` : `${i + 1}`}`);
      const custom = Array.from({ length: 10 }, (_, i) => `custom_image_link_${i + 1 < 10 ? `0${i + 1}` : `${i + 1}`}`);
      return ['image_link', ...additional, ...lifestyle, ...custom]; // Include 'image_link'
    };

    setImageColumns(generateImageColumns());
  }, []);

  // Define all image types
  React.useEffect(() => {
    const types = [
      'primary',
      'uncropped',
      'background',
      'upload',
      'import',
    ];
    setImageTypes(types);
  }, []);

  // Handle changes in image column selection
  const handleColumnChange = (selectedOptions) => {
    setSelectedColumns(selectedOptions ? selectedOptions.map(option => option.value) : []);
  };

  // Handle changes in image type selection
  const handleTypeChange = (type) => {
    setSelectedTypes((prev) =>
      prev.includes(type)
        ? prev.filter((t) => t !== type)
        : [...prev, type]
    );
  };

  // Handle changes in selection scope
  const handleScopeChange = (scope) => {
    setSelectionScope(scope);
  };

  // Handle action type change
  const handleActionTypeChange = (selectedOption) => {
    setActionType(selectedOption.value);
  };

  // Handle bulk select/deselect action
  const handleAction = async () => {
    if (!selectionScope) {
      alert('Please select a scope before proceeding.');
      return;
    }

    let idsToUpdate = [];
    if (selectionScope === 'selectAll') {
      idsToUpdate = allIds;
    } else if (selectionScope === 'selectCurrentView') {
      idsToUpdate = filterIds;
    } else if (selectionScope === 'selectSelectedItems') {
      idsToUpdate = selectedIds;
    }
    console.log('IDs to update:', idsToUpdate);

    if (idsToUpdate.length === 0) {
      alert('No items found for the selected scope.');
      return;
    }

    if (selectedColumns.length === 0 || selectedTypes.length === 0) {
      alert('Please select at least one image column and one image type.');
      return;
    }

    const requestData = {
      client_name: selectedClient,
      ids: idsToUpdate,
      image_columns: selectedColumns,
      image_types: selectedTypes,
      action: actionType, // 'select' or 'deselect'
    };

    setIsLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL_IMAGE}/api/bulk-update-selection-state`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        alert('Bulk update successful.');
        if (refreshData) refreshData(); // Invoke the refreshData function to update the table
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.error}`);
      }
    } catch (error) {
      console.error('Bulk update error:', error);
      alert('An error occurred during the bulk update.');
    } finally {
      setIsLoading(false);
    }
  };

  // Options for Select components
  const imageColumnOptions = imageColumns.map(column => ({ value: column, label: column }));
  const actionOptions = [
    { value: 'select', label: 'Select' },
    { value: 'deselect', label: 'Deselect' },
  ];

  return (
    <div className={styles.pageContainer}>
      {/* Unified Top Section with Title, Description, and Image */}
      <div className={styles.topSection}>
        <div className={styles.textContainer}>
          <h2>Selector Page (Free)</h2>
          <p className={styles.description}>
            This tab allows you to select the image columns and types to be exported into the XML feed. 
            Please choose the relevant image columns, types, and scope of items that should be included in the feed.
          </p>
        </div>
        <img
          src="https://storage.googleapis.com/quantum-feed-engine/workbench/application-images/selectorimage.png"
          alt="Selector Illustration"
          className={styles.headerImage}
        />
      </div>
      
      {/* Select Image Columns */}
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h4>Select Image Columns</h4>
          <div className={styles.tooltip}>
            <i className="fas fa-info-circle"></i>
            <span className={styles.tooltiptext}>
              Choose the image columns you want to include in the action.
            </span>
          </div>
        </div>
        <Select
          isMulti
          options={imageColumnOptions}
          className={styles.multiSelect}
          classNamePrefix="select"
          onChange={handleColumnChange}
          placeholder="Select image columns..."
          closeMenuOnSelect={false}
        />
      </div>

      {/* Select Image Types */}
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h4>Select Image Types</h4>
          <div className={styles.tooltip}>
            <i className="fas fa-info-circle"></i>
            <span className={styles.tooltiptext}>
              Choose the types of images you want to include in the action.
            </span>
          </div>
        </div>
        <div className={styles.checkboxGroup}>
          {imageTypes.map((type) => (
            <label key={type} className={styles.checkboxLabel}>
              <input
                type="checkbox"
                checked={selectedTypes.includes(type)}
                onChange={() => handleTypeChange(type)}
              />
              {type}
            </label>
          ))}
        </div>
      </div>

      {/* Select Scope */}
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h4>Select Scope</h4>
          <div className={styles.tooltip}>
            <i className="fas fa-info-circle"></i>
            <span className={styles.tooltiptext}>
              Choose the scope of items you want to apply the action to.
            </span>
          </div>
        </div>
        <div className={styles.scopeGroup}>
          <button
            className={`${styles.scopeButton} ${selectionScope === 'selectSelectedItems' ? styles.activeScopeButton : ''}`}
            onClick={() => handleScopeChange('selectSelectedItems')}
          >
            Selected IDs ({selectedIds.length})
          </button>
          <button
            className={`${styles.scopeButton} ${selectionScope === 'selectCurrentView' ? styles.activeScopeButton : ''}`}
            onClick={() => handleScopeChange('selectCurrentView')}
          >
            Filtered IDs ({filterIds.length})
          </button>
          <button
            className={`${styles.scopeButton} ${selectionScope === 'selectAll' ? styles.activeScopeButton : ''}`}
            onClick={() => handleScopeChange('selectAll')}
          >
            All IDs ({allIds.length})
          </button>
        </div>
      </div>

      {/* Action Selection */}
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h4>Action</h4>
          <div className={styles.tooltip}>
            <i className="fas fa-info-circle"></i>
            <span className={styles.tooltiptext}>
              Choose the action you want to perform.
            </span>
          </div>
        </div>
        <div className={styles.actionGroup}>
          <Select
            options={actionOptions}
            className={styles.actionSelect}
            classNamePrefix="select"
            onChange={handleActionTypeChange}
            defaultValue={actionOptions[0]}
          />
          <button
            className={styles.applyButton}
            onClick={handleAction}
            disabled={isLoading || !selectionScope || selectedColumns.length === 0 || selectedTypes.length === 0}
          >
            {isLoading ? 'Applying...' : 'Apply'}
          </button>
        </div>
      </div>

      {/* Loading Indicator */}
      {isLoading && (
        <div className={styles.loadingOverlay}>
          <ClipLoader size={50} color={"#123abc"} loading={true} />
          <p>Updating selection state...</p>
        </div>
      )}
    </div>
  );
};

// Define PropTypes for type checking
SelectorPage.propTypes = {
  allIds: PropTypes.array.isRequired,
  filterIds: PropTypes.array.isRequired,
  isAllIdsLoading: PropTypes.bool.isRequired,
  isFilterIdsLoading: PropTypes.bool.isRequired,
  allIdsError: PropTypes.string,
  filterIdsError: PropTypes.string,
  selectedIds: PropTypes.array,
  filters: PropTypes.array,
  selectedClient: PropTypes.string.isRequired,
  refreshData: PropTypes.func.isRequired,
};

export default React.memo(SelectorPage);
