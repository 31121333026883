import React from 'react';
import styles from './TBA1.module.css';

const TBA1 = () => {
  return (
    <div className={styles.container}>
      <h2>TBA 1 (In Development)</h2>
      <p>
        This feature is currently under development and will be available soon.
      </p>
    </div>
  );
};

export default TBA1;
