// src/popups/PopupUploaded.js

import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './css/PopupUploaded.module.css';
import ClipLoader from 'react-spinners/ClipLoader'; // Import the spinner
import { MdCloudUpload, MdUpload } from 'react-icons/md';

// Retrieve the API base URL from environment variables
const API_URL_IMAGE = process.env.REACT_APP_API_URL_IMAGE;

const PopupUploaded = ({ isOpen, onClose, imageUrl, client, id, column, onImageUploadSuccess }) => {
  const [uploadedImage, setUploadedImage] = useState(imageUrl?.url || null); // Track uploaded image
  const [errorMessage, setErrorMessage] = useState(''); // State to track error messages
  const [file, setFile] = useState(null); // Store the file to be uploaded
  const [isUploading, setIsUploading] = useState(false); // Track the upload status
  const [successMessage, setSuccessMessage] = useState(''); // State to track success message

  const onDrop = useCallback((acceptedFiles) => {
    setErrorMessage(''); // Clear any previous error message
    setSuccessMessage(''); // Clear any previous success message
    const droppedFile = acceptedFiles[0];
    if (droppedFile) {
      const previewUrl = URL.createObjectURL(droppedFile); // Create URL for preview
      setUploadedImage(previewUrl); // Update state with preview
      setFile(droppedFile); // Store the file for later upload
    }
  }, []);

  const onDropRejected = useCallback(() => {
    setErrorMessage('Only .webp, .jpg, and .png files are supported.');
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected, // Callback for rejected files
    accept: {
      'image/webp': ['.webp'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    multiple: false, // Allow only single file upload
  });

  useEffect(() => {
    if (!isOpen) {
      setUploadedImage(null); // Reset the uploaded image when the popup is closed
      setErrorMessage(''); // Reset the error message
      setSuccessMessage(''); // Reset the success message
      setFile(null); // Reset the file to null, disabling the button
      setIsUploading(false); // Reset the uploading state just in case
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(); // Trigger close action
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setErrorMessage('Please select an image to upload.');
      return;
    }

    setIsUploading(true); // Set uploading state
    setErrorMessage(''); // Clear any error message
    setSuccessMessage(''); // Clear any previous success message

    const formData = new FormData();
    formData.append('file', file);
    formData.append('client', client); // Pass the client name
    formData.append('id', id); // Pass the image id
    formData.append('column', column); // Pass the column name

    try {
      const response = await fetch(`${API_URL_IMAGE}/upload-image`, { // Updated API URL
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        setSuccessMessage('Image uploaded successfully!');
        // Trigger the callback function to notify parent components
        if (onImageUploadSuccess) {
          onImageUploadSuccess();
        }
      } else {
        setErrorMessage('Failed to upload image.');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      setErrorMessage('An error occurred while uploading the image.');
    } finally {
      setIsUploading(false); // Reset uploading state
    }
  };

  return (
    <div
      className={styles.popupOverlay}
      onClick={handleOverlayClick}
      role="dialog"
      aria-modal="true"
      aria-labelledby="popup-uploaded-title"
    >
      <div
        className={styles.popupContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.popupHeader}>
          <h2 id="popup-uploaded-title">Upload Image</h2>
        </div>

        {/* Dropzone with cloud icon, drag-and-drop text, and button */}
        <div
          {...getRootProps({ className: styles.dropzone })}
          className={isDragActive ? `${styles.dropzone} ${styles.dropzoneActive}` : styles.dropzone}
        >
          <input {...getInputProps()} />
          {!uploadedImage ? (
            <div className={styles.dropzoneContent}>
              <MdCloudUpload className={styles.dropzoneIcon} size={90} aria-hidden="true" />
              <p className={styles.dropzoneText}>Drag and drop files here</p>
              <p className={styles.dropzoneText}>or</p>
              <button className={styles.browseButton} type="button">Browse files</button>
            </div>
          ) : (
            <img src={uploadedImage} alt="Uploaded Preview" className={styles.uploadedImage} />
          )}
        </div>

        {/* Display error message if any */}
        {errorMessage && <p className={styles.errorMessage} role="alert">{errorMessage}</p>}

        {/* Button container for success message and upload button */}
        <div className={styles.buttonContainer}>
          {successMessage && <p className={styles.successMessage}>{successMessage}</p>}

          {/* Upload button with icon or spinner */}
          <button
            onClick={handleUpload}
            className={styles.uploadButton}
            disabled={!file || isUploading}  // Disable if no file is selected or during upload
            aria-disabled={!file || isUploading}
            aria-label="Upload Image"
          >
            {isUploading ? (
              <ClipLoader size={12} color={"#ffffff"} />  // Spinner while uploading
            ) : (
              <MdUpload size={20} aria-hidden="true" />  // Display a relevant icon when ready to upload
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupUploaded;
