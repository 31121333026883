// src/components/Config.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './css/Config.module.css';
import { FaTimes, FaLink, FaUnlink, FaCreditCard } from 'react-icons/fa';
import PopupUpdateCreditBalance from '../popups/PopupUpdateCreditBalance'; // Import the popup

const Config = ({ token, username, onClose }) => {
  const [clients, setClients] = useState([]);
  const [linkedClients, setLinkedClients] = useState([]);
  const [availableClients, setAvailableClients] = useState([]);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  // New state for the credit balance popup
  const [isUpdateCreditPopupOpen, setIsUpdateCreditPopupOpen] = useState(false);
  const [selectedFeed, setSelectedFeed] = useState(null);

  // Helper function to sort an array of strings alphabetically (case-insensitive)
  const sortAlphabetically = (arr) => {
    return arr.slice().sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  };

  useEffect(() => {
    const fetchClients = async () => {
      try {
        // Fetch all clients
        const clientResponse = await axios.get(`${process.env.REACT_APP_API_URL}/client-feeds`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const allClients = clientResponse.data.map(client => client.client_name);
        const sortedAllClients = sortAlphabetically(allClients);
        setClients(clientResponse.data);

        // Fetch linked clients for the user
        const configResponse = await axios.get(`${process.env.REACT_APP_API_URL}/config`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { username: username },
        });
        const linked = configResponse.data.linked_clients || [];
        const sortedLinked = sortAlphabetically(linked);
        setLinkedClients(sortedLinked);

        // Determine available clients to link
        const available = sortedAllClients.filter((clientName) => !linked.includes(clientName));
        const sortedAvailable = sortAlphabetically(available);
        setAvailableClients(sortedAvailable);
      } catch (err) {
        console.error('Failed to fetch data:', err);
        setMessage('Failed to fetch data');
        setMessageType('error');
      }
    };

    fetchClients();
  }, [token, username]);

  const handleAssignClient = async (clientName) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/assign-client`,
        { username: username, client_name: clientName },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Update linkedClients
      const updatedLinked = sortAlphabetically([...linkedClients, clientName]);
      setLinkedClients(updatedLinked);

      // Update availableClients
      const updatedAvailable = sortAlphabetically(availableClients.filter((client) => client !== clientName));
      setAvailableClients(updatedAvailable);

      setMessage('Client linked successfully');
      setMessageType('success');

      // Clear message after 3 seconds
      setTimeout(() => setMessage(''), 3000);
    } catch (err) {
      console.error('Failed to link client:', err);
      setMessage('Failed to link client');
      setMessageType('error');
    }
  };

  const handleUnassignClient = async (clientName) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/unassign-client`,
        { username: username, client_name: clientName },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Update linkedClients
      const updatedLinked = sortAlphabetically(linkedClients.filter((client) => client !== clientName));
      setLinkedClients(updatedLinked);

      // Update availableClients
      const updatedAvailable = sortAlphabetically([...availableClients, clientName]);
      setAvailableClients(updatedAvailable);

      setMessage('Client unlinked successfully');
      setMessageType('success');

      // Clear message after 3 seconds
      setTimeout(() => setMessage(''), 3000);
    } catch (err) {
      console.error('Failed to unlink client:', err);
      setMessage('Failed to unlink client');
      setMessageType('error');
    }
  };

  const handleCreditClient = async (clientName) => {
    // Find the client data based on clientName
    const clientData = clients.find(client => client.client_name === clientName);
    if (clientData) {
      setSelectedFeed(clientData);
      setIsUpdateCreditPopupOpen(true);
    } else {
      setMessage('Client data not found.');
      setMessageType('error');
      setTimeout(() => setMessage(''), 3000);
    }
  };

  const filteredAvailableClients = availableClients.filter((clientName) =>
    clientName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredLinkedClients = linkedClients.filter((clientName) =>
    clientName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handler to close the Credit popup
  const closeUpdateCreditPopup = () => {
    setSelectedFeed(null);
    setIsUpdateCreditPopupOpen(false);
  };

  // Function to refresh clients after updating credit balance
  const refreshClients = async () => {
    try {
      // Re-fetch linked clients
      const configResponse = await axios.get(`${process.env.REACT_APP_API_URL}/config`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { username: username },
      });
      const linked = configResponse.data.linked_clients || [];
      const sortedLinked = sortAlphabetically(linked);
      setLinkedClients(sortedLinked);

      // Re-fetch all clients
      const clientResponse = await axios.get(`${process.env.REACT_APP_API_URL}/client-feeds`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const allClients = clientResponse.data.map(client => client.client_name);
      const sortedAllClients = sortAlphabetically(allClients);
      setClients(clientResponse.data);

      // Update available clients
      const available = sortedAllClients.filter((clientName) => !linked.includes(clientName));
      const sortedAvailable = sortAlphabetically(available);
      setAvailableClients(sortedAvailable);
    } catch (err) {
      console.error('Failed to refresh clients:', err);
      setMessage('Failed to refresh clients');
      setMessageType('error');
    }
  };

  return (
    <div className={styles.configPage}>
      <div className={styles.configContainer}>
        <div className={styles.header}>
          <h2>Manage Your Clients</h2>
        </div>
        {message && (
          <div
            className={`${styles.message} ${
              messageType === 'error' ? styles.errorMessage : styles.successMessage
            }`}
          >
            {message}
          </div>
        )}
        {linkedClients.length === 0 && (
          <div className={styles.noClientsMessage}>
            <p>You don't have any linked clients. Use the list below to link clients to your account.</p>
          </div>
        )}

        <input
          type="text"
          placeholder="Search clients..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={styles.searchInput}
          aria-label="Search Clients"
        />

        <div className={styles.clientLists}>
          {/* Available Clients Section */}
          <div className={styles.listContainer}>
            <div className={styles.listHeader}>Available Clients</div>
            <div className={styles.clientList}>
              {filteredAvailableClients.length > 0 ? (
                filteredAvailableClients.map((clientName, index) => (
                  <div key={index} className={styles.clientItem}>
                    <span className={styles.clientName}>{clientName}</span>
                    <div>
                      <button
                        onClick={() => handleAssignClient(clientName)}
                        className={`${styles.actionButton} ${styles.linkButton}`}
                        aria-label={`Link ${clientName}`}
                        title="Link Client"
                      >
                        <FaLink />
                      </button>
                      <button
                        onClick={() => handleCreditClient(clientName)}
                        className={`${styles.actionButton} ${styles.creditButton}`}
                        aria-label={`Update Credit Balance for ${clientName}`}
                        title="Update Credit Balance"
                      >
                        <FaCreditCard />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p style={{ padding: '10px' }}>No available clients found.</p>
              )}
            </div>
          </div>
          {/* Linked Clients Section */}
          <div className={styles.listContainer}>
            <div className={styles.listHeader}>Linked Clients</div>
            <div className={styles.clientList}>
              {filteredLinkedClients.length > 0 ? (
                filteredLinkedClients.map((clientName, index) => (
                  <div key={index} className={styles.clientItem}>
                    <span className={styles.clientName}>{clientName}</span>
                    <div>
                      <button
                        onClick={() => handleUnassignClient(clientName)}
                        className={`${styles.actionButton} ${styles.unlinkButton}`}
                        aria-label={`Unlink ${clientName}`}
                        title="Unlink Client"
                      >
                        <FaUnlink />
                      </button>
                      <button
                        onClick={() => handleCreditClient(clientName)}
                        className={`${styles.actionButton} ${styles.creditButton}`}
                        aria-label={`Update Credit Balance for ${clientName}`}
                        title="Update Credit Balance"
                      >
                        <FaCreditCard />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p style={{ padding: '10px' }}>No linked clients found.</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Update Credit Balance Popup */}
      {isUpdateCreditPopupOpen && selectedFeed && (
        <PopupUpdateCreditBalance
          token={token}
          feed={selectedFeed}
          onClose={closeUpdateCreditPopup}
          onUpdate={refreshClients} // Refresh clients after update
        />
      )}
    </div>
  );
};

export default Config;
