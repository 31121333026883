import React from 'react';
import styles from './css/PopupRowData.module.css';
import { ClipLoader } from 'react-spinners'; // Import ClipLoader

const PopupRowData = ({
  popupRowData,
  qfeColumns,
  originalData,
  savingCells,
  generating,
  popupSaving,
  activeProcesses,
  hasUnsavedChanges,
  saveSuccess,
  showWarning,
  popupFieldSelection,
  selectedPromptConfiguration,
  promptConfigurations,
  loadingStates,
  handlePopupOverlayClick,
  handlePopupClose,
  handleCancelCloseWarning,
  handleSavePopupChanges,
  handlePopupChange,
  handleBlur,
  handleToggleState,
  handlePopupFieldSelectionChange,
  handleGenerateItemInPopup,
  setSelectedPromptConfiguration
}) => {
  if (!popupRowData) return null;

  // Utility function reused from your code:
  const getBackgroundColor = (state, selected, generating) => {
    if (generating) {
      return selected ? '#FFDDC1' : '#FFD4A1';
    }
    let color;
    switch (state) {
      case 'draft':
        color = selected ? '#fefbec' : '#fefbec';
        break;
      case 'active':
        color = selected ? '#E0EBE4' : '#E0EBE4';
        break;
      default:
        color = selected ? '#A9A9A9' : 'transparent';
    }
    return color;
  };

  // Filter out qfe_keys that do not appear in the original feed data
  const missingQfeKeys = qfeColumns.filter(qfeKey => {
    const originalKey = qfeKey.replace('qfe_', '');
    return !originalData[0].hasOwnProperty(originalKey);
  });

  const popupId = popupRowData.id;

  return (
    <div className={`${styles['popup-overlay']} ${styles['new-popup-overlay']}`} onClick={handlePopupOverlayClick}>
      <div className={`${styles['popup-inner']} ${styles['new-popup-inner']}`} onClick={e => e.stopPropagation()}>
        <button className={styles['new-popup-close-button']} onClick={handlePopupClose}>×</button>
        <div className={`${styles['popup-header']} ${styles['new-popup-header']}`}>
          <div className={`${styles['popup-preview']} ${styles['new-popup-preview']}`}>
            <div className={`${styles['popup-preview-image']} ${styles['new-popup-preview-image']}`}>
              {popupRowData.image_link ? (
                <img src={popupRowData.image_link} alt={popupRowData.title} />
              ) : (
                <p>No image available</p>
              )}
            </div>
            <div className={`${styles['popup-preview-details']} ${styles['new-popup-preview-details']}`}>
              <p><strong>ID:</strong> {popupId}</p>
              <p><strong>Title:</strong> {popupRowData.title}</p>
              <p><strong>Brand:</strong> {popupRowData.brand}</p>
            </div>
          </div>
          <div className={`${styles['popup-controls']} ${styles['new-popup-controls']}`}>
          </div>
        </div>

        <div className={styles['field-selection-container']}>
          <div className={styles['field-selection-header']}>
            <h3>Generate QFE Fields</h3>
            <div className={styles['field-selection-actions']}>
              <button onClick={() => handlePopupFieldSelectionChange('selectAll')}>Select All</button>
              <button onClick={() => handlePopupFieldSelectionChange('deselectAll')}>Deselect All</button>

              <select
                id="prompt-select"
                value={selectedPromptConfiguration}
                onChange={(e) => setSelectedPromptConfiguration(e.target.value)}
              >
                <option value="" disabled>Select a configuration</option>
                {promptConfigurations.map((config) => (
                  <option key={config.combination_id} value={config.combination_id}>{config.combination_name}</option>
                ))}
              </select>
              <button
                className={styles['popup-generate-button']}
                onClick={handleGenerateItemInPopup}
                disabled={
                  generating ||
                  savingCells.length > 0 ||
                  popupSaving ||
                  activeProcesses > 0 ||
                  popupFieldSelection.length === 0 ||
                  selectedPromptConfiguration === ''
                }
              >
                {generating ? (
                  <ClipLoader size={15} color="#ffffff" />
                ) : (
                  'Generate'
                )}
              </button>
            </div>
          </div>
          <div className={styles['field-selection-list']}>
            {qfeColumns.map((col) => (
              <label
                key={col}
                className={`${styles['field-item']} ${popupFieldSelection.includes(col) ? styles['selected'] : ''}`}
              >
                <input
                  type="checkbox"
                  checked={popupFieldSelection.includes(col)}
                  onChange={() => handlePopupFieldSelectionChange(col)}
                />
                {col}
              </label>
            ))}
          </div>
        </div>

        <div className={styles['new-row-details']}>
          {originalData.length > 0 && Object.keys(originalData[0])
            .filter(key =>
              !key.endsWith('_state') &&
              !key.startsWith('qfe_') &&
              key !== 'clientname' &&
              key !== 'id' &&
              key !== 'qfe_id' &&
              key !== 'image_link' &&
              key !== 'item_group_id' &&
              key !== 'other_feed_values'
            )
            .map((key) => {
              const qfeKey = `qfe_${key}`;
              const stateKey = `${qfeKey}_state`;
              const backgroundColor = getBackgroundColor(popupRowData[stateKey], false, false);
              const isLoading = savingCells.some(cell => cell.id === popupId && cell.key === qfeKey);

              return (
                <div key={key} className={styles['new-row-detail']}>
                  <div className={styles['new-row-detail-left']}>
                    <strong>{key}</strong>:<br />
                    {popupRowData[key] || <span className={styles['no-data']}>No data</span>}
                  </div>
                  <div className={styles['new-row-detail-right']}>
                    <strong>{qfeKey}:</strong>
                    <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                      <textarea
                        value={popupRowData[qfeKey] || ''}
                        onChange={(e) => handlePopupChange(popupId, qfeKey, e.target.value)}
                        onBlur={(e) => handleBlur(popupId, qfeKey, e.target.value, true)}
                        className={`${styles['popup-editable-textarea']} ${styles['new-popup-editable-textarea']} ${isLoading ? styles['saving'] : ''} ${qfeKey === 'qfe_description' ? styles['description-textarea'] : ''}`}
                        disabled={isLoading || generating || popupSaving}
                        style={{
                          backgroundColor,
                          width: '100%',
                          minHeight: qfeKey === 'qfe_description' ? '100px' : '50px', // Inline style for minHeight
                          resize: 'vertical', // Ensure the textarea can still be resized vertically
                          ...(qfeKey === 'qfe_description' && { height: 'auto' }) // Additional inline style if needed
                        }}
                      />
                      {isLoading && (
                        <ClipLoader
                          size={15}
                          color="#4758EB"
                          className={styles['loading-gif']}
                          style={{ position: 'absolute', right: '10px', bottom: '10px' }}
                        />
                      )}
                      <button
                        onClick={() => handleToggleState(popupId, qfeKey)}
                        className={`${styles['toggle-button']} ${styles['new-toggle-button']} ${popupRowData[stateKey] === 'active' ? styles['deactivate'] : styles['active']} ${loadingStates[`${popupId}_${stateKey}`] ? styles['loading'] : ''}`}
                        disabled={loadingStates[`${popupId}_${stateKey}`] || generating || popupSaving}
                      >
                        {loadingStates[`${popupId}_${stateKey}`] ? (
                          <ClipLoader size={15} color="#ffffff" />
                        ) : (
                          popupRowData[stateKey] === 'active' ? 'Deactivate' : 'Activate'
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}

          {missingQfeKeys.map((qfeKey) => {
            const stateKey = `${qfeKey}_state`;
            const backgroundColor = getBackgroundColor(popupRowData[stateKey], false, false);
            const isLoading = savingCells.some(cell => cell.id === popupId && cell.key === qfeKey);

            return (
              <div key={qfeKey} className={styles['new-row-detail']}>
                <div className={styles['new-row-detail-left']}>
                  <strong>{qfeKey.replace('qfe_', '')}</strong>:<br />
                  <span className={styles['no-data']}>No data</span>
                </div>
                <div className={styles['new-row-detail-right']}>
                  <strong>{qfeKey}:</strong>
                  <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                    <textarea
                      value={popupRowData[qfeKey] || ''}
                      onChange={(e) => handlePopupChange(popupId, qfeKey, e.target.value)}
                      onBlur={(e) => handleBlur(popupId, qfeKey, e.target.value, true)}
                      className={`${styles['popup-editable-textarea']} ${styles['new-popup-editable-textarea']} ${isLoading ? styles['saving'] : ''} ${qfeKey === 'qfe_description' ? styles['description-textarea'] : ''}`}
                      disabled={isLoading || generating || popupSaving}
                      style={{
                        backgroundColor,
                        width: '100%',
                        minHeight: qfeKey === 'qfe_description' ? '100px' : '50px', // Inline style for minHeight
                        resize: 'vertical', // Ensure the textarea can still be resized vertically
                        ...(qfeKey === 'qfe_description' && { height: 'auto' }) // Additional inline style if needed
                      }}
                    />
                    {isLoading && (
                      <ClipLoader
                        size={15}
                        color="#4758EB"
                        className={styles['loading-gif']}
                        style={{ position: 'absolute', right: '10px', bottom: '10px' }}
                      />
                    )}
                    <button
                      onClick={() => handleToggleState(popupId, qfeKey)}
                      className={`${styles['toggle-button']} ${styles['new-toggle-button']} ${popupRowData[stateKey] === 'active' ? styles['deactivate'] : styles['active']} ${loadingStates[`${popupId}_${stateKey}`] ? styles['loading'] : ''}`}
                      disabled={loadingStates[`${popupId}_${stateKey}`] || generating || popupSaving}
                    >
                      {loadingStates[`${popupId}_${stateKey}`] ? (
                        <ClipLoader size={15} color="#ffffff" />
                      ) : (
                        popupRowData[stateKey] === 'active' ? 'Deactivate' : 'Activate'
                      )}
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {hasUnsavedChanges && (
          <div className={styles['popup-unsaved']}>
            You have unsaved changes
          </div>
        )}
        {saveSuccess && (
          <div className={styles['popup-success']}>
            Changes saved successfully!
          </div>
        )}

        {/* Sticky Save Button */}
        <div className={styles['sticky-save-container']}>
          <button
            className={`${styles['popup-save-button']} ${styles['sticky-save-button']}`}
            onClick={handleSavePopupChanges}
            disabled={popupSaving || activeProcesses > 0 || !hasUnsavedChanges}
          >
            {popupSaving ? (
              <ClipLoader size={20} color="#ffffff" />
            ) : (
              'Save Changes'
            )}
          </button>
        </div>
      </div>
      {showWarning && (
        <div className={styles['popup-warning-overlay']}>
          <div className={styles['popup-warning-inner']}>
            <p>You have unsaved changes. Are you sure you want to close?</p>
            <button onClick={handlePopupClose}>Yes, Close</button>
            <button onClick={handleCancelCloseWarning}>Cancel</button> {/* Use the handler here */}
          </div>
        </div>
      )}
    </div>
  );
};

export default PopupRowData;
