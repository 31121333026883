// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Login from './components/Login';
import Register from './components/Register';
import AdminDashboard from './components/AdminDashboard';
import ClientDashboard from './components/ClientDashboard';
import Config from './components/Config';
import TextGeneration from './components/TextGeneration';
import PromptBuilder from './components/PromptBuilder'; // Import PromptBuilder
import ImageGeneration from './components/ImageGeneration'; // Import ImageGeneration
import CustomAttributes from './components/CustomAttributes'; // Import CustomAttributes
import ImageGenerationScheduler from './components/ImageGenerationScheduler'; // Import ImageGenerationScheduler

const PrivateRoute = ({ children, isAuthenticated }) => {
  return isAuthenticated ? children : <Navigate to="/login" />;
};

const AdminRoute = ({ children, isAuthenticated, isAdmin }) => {
  return isAuthenticated && isAdmin ? children : <Navigate to="/login" />;
};

function App() {
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [client, setClient] = useState('');
  const [user, setUser] = useState({ username: '', email: '' });
  const [loggingOut, setLoggingOut] = useState(false);
  const navigate = useNavigate();

  const handleSetToken = (newToken) => {
    setToken(newToken);
    if (newToken) {
      localStorage.setItem('token', newToken);
    } else {
      localStorage.removeItem('token');
    }
  };

  const handleLogout = async () => {
    setLoggingOut(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/logout`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log("Logout successful");
    } catch (err) {
      console.error("Logout error:", err);
    } finally {
      handleSetToken(null);
      setLoggingOut(false);
      navigate('/login');
    }
  };

  useEffect(() => {
    if (loggingOut) {
      console.log("Logging out, skipping validation...");
      return; // Skip validation if logging out
    }

    if (!token) {
      console.log("No token found, navigating to login");
      navigate('/login');
      return;
    }

    const validateToken = async () => {
      console.log("Validating token...");
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/validate-token`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log("Token validation successful");
        setIsAdmin(response.data.is_admin);
        setUser({ username: response.data.username, email: response.data.email });
      } catch (error) {
        console.error("Token validation error:", error);
        handleSetToken(null);
        navigate('/login');
      }
    };

    validateToken();
  }, [token, loggingOut, navigate]);

  useEffect(() => {
    const heartbeat = async () => {
      try {
        await axios.get(`${process.env.REACT_APP_API_URL}/heartbeat`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log("Heartbeat successful");
      } catch (error) {
        console.error("Heartbeat error:", error);
        handleSetToken(null);
        navigate('/login');
      }
    };

    // Send heartbeat
    const intervalId = setInterval(() => {
      if (token) {
        heartbeat();
      }
    }, 5.5 * 60 * 1000); // 5.5 minutes

    return () => clearInterval(intervalId); // Clear interval on unmount
  }, [token, navigate]);

  const isAuthenticated = !!token;
  console.log("isAuthenticated:", isAuthenticated);

  // Lift the selectedClient state up here
  const [selectedClient, setSelectedClient] = useState('');

  return (
    <Routes>
      <Route path="/login" element={<Login setToken={handleSetToken} setIsAdmin={setIsAdmin} setUser={setUser} />} />
      <Route path="/register" element={
        <AdminRoute isAuthenticated={isAuthenticated} isAdmin={isAdmin}>
          <Register />
        </AdminRoute>
      } />
      <Route path="/admin" element={
        <PrivateRoute isAuthenticated={isAuthenticated && isAdmin}>
          <AdminDashboard token={token} setClient={setClient} />
        </PrivateRoute>
      } />
      <Route path="/client" element={
        <PrivateRoute isAuthenticated={isAuthenticated && !isAdmin}>
          <ClientDashboard token={token} isAdmin={isAdmin} />
        </PrivateRoute>
      } />
      <Route path="/config" element={
        <PrivateRoute isAuthenticated={isAuthenticated}>
          <Config token={token} client={client} isAdmin={isAdmin} />
        </PrivateRoute>
      } />
      <Route path="/text-generation" element={
        <PrivateRoute isAuthenticated={isAuthenticated}>
          <TextGeneration token={token} isAdmin={isAdmin} />
        </PrivateRoute>
      } />
      <Route path="/prompt-builder" element={
        <PrivateRoute isAuthenticated={isAuthenticated}>
          <PromptBuilder token={token} />
        </PrivateRoute>
      } />
     <Route path="/image-generation" element={
        <PrivateRoute isAuthenticated={isAuthenticated}>
          <ImageGeneration 
            isAdmin={isAdmin} 
            token={token} 
            selectedClient={selectedClient} 
            setSelectedClient={setSelectedClient} 
          />
        </PrivateRoute>
      } />
      
      <Route path="/image-generation-scheduler" element={
        <PrivateRoute isAuthenticated={isAuthenticated}>
          <ImageGenerationScheduler 
            isAdmin={isAdmin} 
            token={token} 
            selectedClient={selectedClient} 
            setSelectedClient={setSelectedClient} 
          />
        </PrivateRoute>
      } />
      <Route path="/custom-attributes" element={
        <PrivateRoute isAuthenticated={isAuthenticated}>
          <CustomAttributes token={token} isAdmin={isAdmin} />
        </PrivateRoute>
      } />
      <Route path="/" element={<Navigate to="/login" />} />
    </Routes>
  );
}

export default App;
