import React, { useRef } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import styles from './css/PopupTextReplace.module.css'; // CSS module

const PopupTextReplace = ({
  replacePopupVisible,
  setReplacePopupVisible,
  replaceColumn,
  setReplaceColumn,
  replaceOriginalValue,
  setReplaceOriginalValue,
  replaceNewValue,
  setReplaceNewValue,
  isCaseSensitive,
  setIsCaseSensitive,
  updatedRowCount,
  isProcessingReplace,
  handleReplaceConfirm,
  qfeColumns
}) => {
  const overlayRef = useRef(null);

  const closeModal = () => {
    // Close the popup if not currently processing
    if (!isProcessingReplace) {
      setReplacePopupVisible(false);
    }
  };

  const handleOverlayClick = (e) => {
    if (overlayRef.current && overlayRef.current === e.target) {
      closeModal();
    }
  };

  if (!replacePopupVisible) return null;

  return (
    <div
      className={styles.popupOverlay}
      ref={overlayRef}
      onClick={handleOverlayClick}
    >
      <div className={styles.popupContainer} onClick={(e) => e.stopPropagation()}>
        {/* Header Section */}
        <button className={styles.closeButton} onClick={closeModal}>×</button>
        <div className={styles.header}>
          <h2 className={styles.title}>Replace Text in QFE Items</h2>
        </div>

        {/* Body Section */}
        <div className={styles.body}>
          <p className={styles.instructions}>
            Use this tool to find and replace text within a selected QFE column for all items
            belonging to a specific client. Please ensure accuracy before proceeding.
          </p>

          <div className={styles.inputGroup}>
            <div className={styles.field}>
              <label htmlFor="replace-original" className={styles.fieldLabel}>
                Replace:
              </label>
              <input
                type="text"
                id="replace-original"
                placeholder="Original value"
                className={styles.input}
                value={replaceOriginalValue}
                onChange={(e) => setReplaceOriginalValue(e.target.value)}
              />
            </div>

            <div className={styles.withLabel}>with</div>

            <div className={styles.field}>
              <input
                type="text"
                placeholder="New value"
                className={styles.input}
                value={replaceNewValue}
                onChange={(e) => setReplaceNewValue(e.target.value)}
              />
            </div>

            <div className={styles.withLabel}>in</div>

            <div className={styles.field}>
              <select
                id="replace-column"
                className={styles.select}
                value={replaceColumn}
                onChange={(e) => setReplaceColumn(e.target.value)}
              >
                <option value="" disabled>
                  Select a column
                </option>
                {qfeColumns.map((col) => (
                  <option key={col} value={col}>
                    {col}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className={styles.caseSensitiveGroup}>
            <span className={styles.caseSensitiveLabel}>Case Sensitive:</span>
            <label className={styles.radioLabel}>
              <input
                type="radio"
                name="caseSensitive"
                value={true}
                checked={isCaseSensitive === true}
                onChange={() => setIsCaseSensitive(true)}
              />
              Yes
            </label>
            <label className={styles.radioLabel}>
              <input
                type="radio"
                name="caseSensitive"
                value={false}
                checked={isCaseSensitive === false}
                onChange={() => setIsCaseSensitive(false)}
              />
              No
            </label>
          </div>
        </div>

        {/* Footer Section */}
        <div className={styles.footer}>
          <div className={styles.updateMessageContainer}>
            {updatedRowCount > 0 && (
              <div className={styles.updateMessage}>
                {updatedRowCount} row{updatedRowCount !== 1 ? 's' : ''} updated
              </div>
            )}
          </div>
          <div className={styles.buttons}>
            <button
              className={styles.replaceButton}
              onClick={handleReplaceConfirm}
              disabled={
                isProcessingReplace ||
                !replaceColumn ||
                !replaceOriginalValue ||
                !replaceNewValue
              }
            >
              {isProcessingReplace ? (
                <ClipLoader size={20} color="#ffffff" />
              ) : (
                'Replace'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupTextReplace;
