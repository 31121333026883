// src/popups/PopupTransform.js

import React from 'react';
import styles from './css/PopupTransform.module.css';
import { FaTimes } from 'react-icons/fa';
import { MdInfoOutline } from 'react-icons/md';
import ClipLoader from 'react-spinners/ClipLoader';
import PropTypes from 'prop-types';


const PopupTransform = ({
  isOpen,
  onClose,
  imageUrl,
  column,
  id,
  client,
  imageKey,
  onTransformSuccess,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [currentFormat, setCurrentFormat] = React.useState(null);

  // Retrieve the API base URL from environment variables
  const API_URL_IMAGE = process.env.REACT_APP_API_URL_IMAGE;

  // Function to get the current image format from the imageUrl
  const getImageFormat = (url) => {
    try {
      const parsedUrl = new URL(url);
      const pathname = parsedUrl.pathname;
      const extensionMatch = pathname.match(/\.([a-zA-Z]+)(\?|$)/);
      if (extensionMatch && extensionMatch[1]) {
        return extensionMatch[1].toLowerCase();
      }
    } catch (error) {
      console.error('Invalid imageUrl:', url);
    }
    return null;
  };

  React.useEffect(() => {
    if (imageUrl) {
      const format = getImageFormat(imageUrl);
      setCurrentFormat(format);
    }
  }, [imageUrl]);

  React.useEffect(() => {
    if (!isOpen) {
      setMessage('');
    }
  }, [isOpen]);

  const handleTransform = async (format) => {
    setIsLoading(true);
    setMessage(''); // Reset message
    try {
      const response = await fetch(`${API_URL_IMAGE}/transform-image`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Include Authorization header if your backend requires authentication
          'Authorization': `Bearer ${localStorage.getItem('token')}`, // Optional
        },
        body: JSON.stringify({
          imageUrl,
          format,
          column,
          id,
          client,
          imageKey,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Transformation successful:', data);
        setMessage('Transformation successful!');
        onTransformSuccess && onTransformSuccess();
        // Optionally close the popup after a delay
      } else {
        const errorData = await response.json();
        console.error('Transformation failed:', errorData.error);
        setMessage(`Transformation failed: ${errorData.error}`);
      }
    } catch (error) {
      console.error('Error transforming image:', error);
      setMessage('An error occurred during transformation.');
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  // List of available formats
  const availableFormats = ['jpg', 'png', 'webp'];

  // Exclude the current format
  const formatsToShow = availableFormats.filter((f) => f !== currentFormat);

  return (
    <div
      className={styles.overlay}
      role="dialog"
      aria-modal="true"
      aria-labelledby="popup-transform-title"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div className={styles.popup}>
        <button
          className={styles.closeButton}
          onClick={onClose}
          aria-label="Close Transform Image Popup"
        >
          <FaTimes />
        </button>
        <h2 id="popup-transform-title">Transform Image</h2>
        <img src={imageUrl} alt="To Transform" className={styles.previewImage} />
        <div className={styles.optionsContainer}>
          {formatsToShow.length > 0 ? (
            formatsToShow.map((format) => (
              <button
                key={format}
                className={styles.transformButton}
                onClick={() => handleTransform(format)}
                disabled={isLoading}
                aria-disabled={isLoading}
              >
                {isLoading ? (
                  <ClipLoader size={15} color={'#FFFFFF'} />
                ) : (
                  `Transform to ${format.toUpperCase()}`
                )}
              </button>
            ))
          ) : (
            <p className={styles.noOptions}>No other formats available.</p>
          )}
          <p className={styles.costNote}>
            <MdInfoOutline size={16} />
            Each transformation will cost <strong>10 credits</strong>.
          </p>
        </div>
        {message && (
          <div
            className={`${styles.message} ${
              message.includes('successful') ? styles.success : styles.error
            }`}
            role="alert"
          >
            {message}
          </div>
        )}
      </div>
    </div>
  );
};

// Define PropTypes for better type checking
PopupTransform.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
  column: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  client: PropTypes.string.isRequired,
  imageKey: PropTypes.string.isRequired,
  onTransformSuccess: PropTypes.func,
};

export default PopupTransform;
