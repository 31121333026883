// src/popups/DeleteFeedPopup.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './css/DeleteFeedPopup.module.css';
import { FaSpinner } from 'react-icons/fa';

const DeleteFeedPopup = ({ token, feed, onClose, onDelete }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [clientStats, setClientStats] = useState(null);
  const [confirmText, setConfirmText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);

  // On mount, fetch the counts from the backend
  useEffect(() => {
    const fetchClientStats = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/client-feed-stats`,
          {
            headers: { Authorization: `Bearer ${token}` },
            params: { client_name: feed.client_name },
          }
        );
        setClientStats(response.data);
      } catch (err) {
        console.error(err);
        setErrorMessage('Failed to fetch client statistics');
      }
    };

    fetchClientStats();
  }, [token, feed.client_name]);

  useEffect(() => {
    if (confirmText === feed.client_name) {
      setIsDeleteDisabled(false);
      setErrorMessage('');
    } else {
      setIsDeleteDisabled(true);
      if (confirmText) {
        setErrorMessage('Client name does not match.');
      } else {
        setErrorMessage('');
      }
    }
  }, [confirmText, feed.client_name]);

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/admin/delete-client-feed`,
        {
          headers: { Authorization: `Bearer ${token}` },
          data: { client_name: feed.client_name },
        }
      );
      setIsDeleting(false);
      onClose();
      onDelete(); // Refresh feeds
    } catch (err) {
      console.error(err);
      setIsDeleting(false);
      setErrorMessage('Failed to delete client feed');
    }
  };

  return (
    <div
      className={styles.modalBackdrop}
      role="dialog"
      aria-modal="true"
      aria-labelledby="delete-feed-modal-title"
    >
      <div className={styles.modalContent}>
        <h2 id="delete-feed-modal-title">Delete Client Feed</h2>
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
        {clientStats ? (
          <div>
            <p>
              Are you sure you want to delete the{' '}
              <strong>{feed.client_name}</strong>?
            </p>
            <p>This will delete the following:</p>
            <ul>
              <li>{clientStats.text_generations_count} text generations</li>
              <li>{clientStats.image_generations_count} image generations</li>
              <li>{clientStats.bucket_size_mb} MB of data in the client's bucket</li>
              <li>Client data (firestore) for&nbsp;<strong>{feed.client_name}</strong></li>
            </ul>
            <p>Please type the exact client/feed name to confirm deletion:</p>
            <input
              type="text"
              value={confirmText}
              onChange={(e) => setConfirmText(e.target.value)}
              className={styles.confirmInput}
              aria-label="Confirm Client Name"
            />
            <div className={styles.buttonsContainer}>
              <button onClick={onClose} className={styles.cancelButton}>
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className={styles.deleteButton}
                disabled={isDeleting || isDeleteDisabled}
              >
                {isDeleting ? <FaSpinner className={styles.spinner} /> : 'Delete'}
              </button>
            </div>
          </div>
        ) : (
          <div className={styles.loadingContainer}>
            <FaSpinner className={styles.spinner} />
            <p>Loading client statistics...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeleteFeedPopup;
