// BackgroundManipulatorPage.jsx

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './BackgroundManipulatorPage.module.css';
import axios from 'axios';
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';

const BackgroundManipulatorPage = ({
  allIds,
  filterIds,
  selectedIds,
  selectedClient,
  filters,
  refreshData,
  isAllIdsLoading,
  isFilterIdsLoading,
  allIdsError,
  filterIdsError,
  onBackgroundManipulationSuccess, // Accept the callback prop
}) => {
  const [processing, setProcessing] = useState(false);
  const [processingError, setProcessingError] = useState(null);
  const [processingSuccess, setProcessingSuccess] = useState(null);

  // State for background manipulation options
  const [backgroundOption, setBackgroundOption] = useState('transparent');
  const [color, setColor] = useState('#ffffff');
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
  const [backgroundPrompt, setBackgroundPrompt] = useState('');
  const [shadow, setShadow] = useState('None');
  const [relightImage, setRelightImage] = useState(false);
  const [removeText, setRemoveText] = useState(false);
  const [removeTextMode, setRemoveTextMode] = useState('all');
  const [customWidth, setCustomWidth] = useState('');
  const [customHeight, setCustomHeight] = useState('');
  const [scaling, setScaling] = useState('fit');
  const [paddingValue, setPaddingValue] = useState('');
  const [paddingUnit, setPaddingUnit] = useState('%');
  const [referenceBox, setReferenceBox] = useState('subject');

  // State for selecting which images to process
  const [imageColumns, setImageColumns] = useState([]);
  const [selectedImageColumns, setSelectedImageColumns] = useState(['image_link']);

  const API_URL_IMAGE = process.env.REACT_APP_API_URL_IMAGE;

  // State for which IDs to process
  const [targetIdsOption, setTargetIdsOption] = useState('selected'); // 'selected', 'filtered', 'all'

  // Options for Select components
  const imageColumnOptions = imageColumns.map(column => ({ value: column, label: column }));

  // State variables for cost calculation
  const [costPerImage, setCostPerImage] = useState(50);
  const [estimatedCost, setEstimatedCost] = useState(0);
  const [totalImages, setTotalImages] = useState(0);

  // Generate image columns dynamically
  useEffect(() => {
    const generateImageColumns = () => {
      const padNumber = (num) => (num < 10 ? `0${num}` : `${num}`);
      const additional = Array.from({ length: 10 }, (_, i) => `additional_image_link_${padNumber(i + 1)}`);
      const lifestyle = Array.from({ length: 10 }, (_, i) => `lifestyle_image_link_${padNumber(i + 1)}`);
      const custom = Array.from({ length: 10 }, (_, i) => `custom_image_link_${padNumber(i + 1)}`);
      return ['image_link', ...additional, ...lifestyle, ...custom];
    };
    setImageColumns(generateImageColumns());
  }, []);

  // Effect to calculate cost per image based on selected options
  useEffect(() => {
    const extraCreditConditions =
      backgroundOption === 'ai_generated' ||
      ['Soft', 'Hard', 'Floating'].includes(shadow) ||
      relightImage ||
      removeText;
    setCostPerImage(extraCreditConditions ? 100 : 50);
  }, [backgroundOption, shadow, relightImage, removeText]);



  useEffect(() => {
    // Load settings for the selected client from localStorage
    const savedSettings = localStorage.getItem(`backgroundManipulatorSettings_${selectedClient}`);
    if (savedSettings) {
      const parsedSettings = JSON.parse(savedSettings);
      // Update state variables
      setBackgroundOption(parsedSettings.backgroundOption || 'transparent');
      setColor(parsedSettings.color || '#ffffff');
      setBackgroundImageUrl(parsedSettings.backgroundImageUrl || '');
      setBackgroundPrompt(parsedSettings.backgroundPrompt || '');
      setShadow(parsedSettings.shadow || 'None');
      setRelightImage(parsedSettings.relightImage || false);
      setRemoveText(parsedSettings.removeText || false);
      setRemoveTextMode(parsedSettings.removeTextMode || 'all');
      setCustomWidth(parsedSettings.customWidth || '');
      setCustomHeight(parsedSettings.customHeight || '');
      setScaling(parsedSettings.scaling || 'fit');
      setPaddingValue(parsedSettings.paddingValue || '');
      setPaddingUnit(parsedSettings.paddingUnit || '%');
      setReferenceBox(parsedSettings.referenceBox || 'subject');
      setSelectedImageColumns(parsedSettings.selectedImageColumns || ['image_link']);
      setTargetIdsOption(parsedSettings.targetIdsOption || 'selected');
    }
  }, [selectedClient]);
  
  // Effect to calculate estimated total cost
  useEffect(() => {
    let idsCount = 0;
    if (targetIdsOption === 'selected') {
      idsCount = selectedIds.length;
    } else if (targetIdsOption === 'filtered') {
      idsCount = filterIds.length;
    } else if (targetIdsOption === 'all') {
      idsCount = allIds.length;
    }
    const imagesToProcess = idsCount * selectedImageColumns.length;
    setTotalImages(imagesToProcess);
    setEstimatedCost(imagesToProcess * costPerImage);
  }, [costPerImage, targetIdsOption, selectedIds, filterIds, allIds, selectedImageColumns]);

  const handleProcess = async () => {
    setProcessing(true);
    setProcessingError(null);
    setProcessingSuccess(null);

    // Determine which IDs to process
    let ids = [];
    if (targetIdsOption === 'selected') {
      ids = selectedIds;
    } else if (targetIdsOption === 'filtered') {
      ids = filterIds;
    } else if (targetIdsOption === 'all') {
      ids = allIds;
    }

    // Prepare the request payload
    const payload = {
      client_name: selectedClient,
      ids: ids,
      image_columns: selectedImageColumns,
      image_types: ['primary'], // 'primary' is pre-selected
      background: backgroundOption,
      color: color ? color.replace('#', '') : null,
      backgroundImageUrl: backgroundImageUrl,
      backgroundPrompt: backgroundPrompt,
      removeBackground: true,
      width: customWidth || null,
      height: customHeight || null,
      scaling: scaling,
      paddingValue: paddingValue || null,
      paddingUnit: paddingUnit,
      referenceBox: referenceBox,
      shadow: shadow,
      relightImage: relightImage,
      removeText: removeText,
      removeTextMode: removeTextMode,
    };

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_URL_IMAGE}/background-removal-bulk`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200 || response.status === 207) {
        setProcessingSuccess(response.data.message);
        refreshData();
        if (onBackgroundManipulationSuccess) {
          onBackgroundManipulationSuccess(); // Invoke the callback
        }
      } else {
        setProcessingError(response.data.error || 'Unexpected error occurred.');
      }
    } catch (error) {
      console.error('Error during bulk background manipulation:', error);
      if (error.response && error.response.data && error.response.data.error) {
        setProcessingError(error.response.data.error);
      } else if (error.request) {
        setProcessingError('Network error: Unable to reach the server.');
      } else {
        setProcessingError('An unexpected error occurred.');
      }
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className={styles.pageContainer}>
      {/* Header Section: Description and Image Side by Side */}
      <div className={styles.headerSection}>
        {/* Description */}
        <div className={styles.description}>
          <h2>Bulk Background Manipulation</h2>
          <p>
            This tool allows you to manipulate the background of images in bulk.
            You can remove backgrounds, add shadows, relight images, and more.
          </p>
        </div>

        {/* Header Image */}
        <div className={styles.headerImage}>
          <img
            src="https://storage.googleapis.com/quantum-feed-engine/workbench/application-images/background_manipulation.png"
            alt="Background Manipulation Overview"
          />
        </div>
      </div>

      {/* Pricing Information */}
      <div className={styles.pricingSection}>
        <h4>Pricing Information</h4>
        <p>
          The base cost per image is <strong>50 credits</strong>.
        </p>
        <p>
          The following options will increase the cost to <strong>100 credits</strong> per image:
        </p>
        <ul>
          <li>Background Option: AI Generated (Extra Cost)</li>
          <li>Shadow: Soft, Hard, or Floating</li>
          <li>Relight Image</li>
          <li>Remove Text</li>
        </ul>
      </div>

      {/* Select Images to Process */}
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h4>1. Select Images to Process</h4>
          <div className={styles.tooltip}>
            <i className="fas fa-info-circle"></i>
            <span className={styles.tooltiptext}>
              Choose which images you want to process.
            </span>
          </div>
        </div>
        <div className={styles.scopeGroup}>
          <button
            type="button"
            className={`${styles.scopeButton} ${targetIdsOption === 'selected' ? styles.activeScopeButton : ''}`}
            onClick={() => setTargetIdsOption('selected')}
          >
            Selected Images ({selectedIds.length})
          </button>
          <button
            type="button"
            className={`${styles.scopeButton} ${targetIdsOption === 'filtered' ? styles.activeScopeButton : ''}`}
            onClick={() => setTargetIdsOption('filtered')}
          >
            Filtered Images ({filterIds.length})
          </button>
          <button
            type="button"
            className={`${styles.scopeButton} ${targetIdsOption === 'all' ? styles.activeScopeButton : ''}`}
            onClick={() => setTargetIdsOption('all')}
          >
            All Images ({allIds.length})
          </button>
        </div>
      </div>

      {/* Select Image Columns */}
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h4>2. Select Image Columns</h4>
          <div className={styles.tooltip}>
            <i className="fas fa-info-circle"></i>
            <span className={styles.tooltiptext}>
              Choose the image columns you want to process.
            </span>
          </div>
        </div>
        <Select
          isMulti
          options={imageColumnOptions}
          className={styles.multiSelect}
          classNamePrefix="select"
          onChange={(selectedOptions) => {
            const columns = selectedOptions ? selectedOptions.map(option => option.value) : [];
            setSelectedImageColumns(columns);
          }}
          value={imageColumnOptions.filter(option => selectedImageColumns.includes(option.value))}
          placeholder="Select image columns..."
          closeMenuOnSelect={false}
        />
      </div>

      {/* Background Manipulation Options */}
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h4>3. Background Manipulation Options</h4>
          <div className={styles.tooltip}>
            <i className="fas fa-info-circle"></i>
            <span className={styles.tooltiptext}>
              Customize how the background should be manipulated.
            </span>
          </div>
        </div>

        {/* Collapsible Sections for better UX */}
        {/* Background Settings */}
        <div className={styles.subSection}>
          <h5>Background Settings</h5>
          <div className={styles.optionGroup}>
            <label className={styles.optionLabel}>
              Background Option:
            </label>
            <Select
              options={[
                { value: 'transparent', label: 'Transparent' },
                { value: 'color', label: 'Color' },
                { value: 'image', label: 'Image URL' },
                { value: 'ai_generated', label: 'AI Generated (Extra Cost)' },
              ]}
              className={styles.actionSelect}
              classNamePrefix="select"
              onChange={(selectedOption) => setBackgroundOption(selectedOption.value)}
              value={{
                value: backgroundOption,
                label:
                  backgroundOption === 'ai_generated'
                    ? 'AI Generated (Extra Cost)'
                    : backgroundOption.charAt(0).toUpperCase() +
                      backgroundOption.slice(1).replace('_', ' '),
              }}
            />
          </div>

          {/* Conditional Inputs based on Background Option */}
          {backgroundOption === 'color' && (
            <div className={styles.optionGroup}>
              <label className={styles.optionLabel}>
                Color:
              </label>
              <input
                type="color"
                value={color}
                onChange={(e) => setColor(e.target.value)}
                className={styles.colorInput}
              />
            </div>
          )}
          {backgroundOption === 'image' && (
            <div className={styles.optionGroup}>
              <label className={styles.optionLabel}>
                Background Image URL:
              </label>
              <input
                type="text"
                value={backgroundImageUrl}
                onChange={(e) => setBackgroundImageUrl(e.target.value)}
                className={styles.textInput}
                placeholder="Enter image URL..."
              />
            </div>
          )}
          {backgroundOption === 'ai_generated' && (
            <div className={styles.optionGroup}>
              <label className={styles.optionLabel}>
                Background Prompt:
              </label>
              <input
                type="text"
                value={backgroundPrompt}
                onChange={(e) => setBackgroundPrompt(e.target.value)}
                className={styles.textInput}
                placeholder="Enter AI prompt..."
              />
            </div>
          )}
        </div>

        {/* Additional Effects */}
        <div className={styles.subSection}>
          <h5>Additional Effects</h5>
          <div className={styles.optionGroup}>
            <label className={styles.optionLabel}>
              Shadow:
            </label>
            <Select
              options={[
                { value: 'None', label: 'None' },
                { value: 'Soft', label: 'Soft (Extra Cost)' },
                { value: 'Hard', label: 'Hard (Extra Cost)' },
                { value: 'Floating', label: 'Floating (Extra Cost)' },
              ]}
              className={styles.actionSelect}
              classNamePrefix="select"
              onChange={(selectedOption) => setShadow(selectedOption.value)}
              value={{
                value: shadow,
                label:
                  ['Soft', 'Hard', 'Floating'].includes(shadow)
                    ? `${shadow} (Extra Cost)`
                    : shadow,
              }}
            />
          </div>

          <div className={styles.optionGroupCheckbox}>
            <label className={styles.checkboxLabel}>
              <input
                type="checkbox"
                checked={relightImage}
                onChange={(e) => setRelightImage(e.target.checked)}
                aria-label="Relight Image"
              />
              Relight Image {relightImage && <span className={styles.premiumLabel}>Extra Cost</span>}
            </label>
            <label className={styles.checkboxLabel}>
              <input
                type="checkbox"
                checked={removeText}
                onChange={(e) => setRemoveText(e.target.checked)}
                aria-label="Remove Text"
              />
              Remove Text {removeText && <span className={styles.premiumLabel}>Extra Cost</span>}
            </label>
          </div>

          {removeText && (
            <div className={styles.optionGroup}>
              <label className={styles.optionLabel}>
                Remove Text Mode:
              </label>
              <Select
                options={[
                  { value: 'all', label: 'All' },
                  { value: 'natural', label: 'Natural' },
                  { value: 'artificial', label: 'Artificial' },
                ]}
                className={styles.actionSelect}
                classNamePrefix="select"
                onChange={(selectedOption) => setRemoveTextMode(selectedOption.value)}
                value={{
                  value: removeTextMode,
                  label: removeTextMode.charAt(0).toUpperCase() + removeTextMode.slice(1),
                }}
              />
            </div>
          )}
        </div>

        {/* Image Dimensions & Scaling */}
        <div className={styles.subSection}>
          <h5>Image Dimensions & Scaling</h5>
          <div className={styles.optionGroupRow}>
            <div className={styles.optionGroup}>
              <label className={styles.optionLabel}>
                Final size (px):
              </label>
              <input
                type="number"
                value={customWidth}
                onChange={(e) => setCustomWidth(e.target.value)}
                className={styles.numberInput}
                placeholder="Width"
              />
            </div>
            <div className={styles.optionGroup}>
              <input
                type="number"
                value={customHeight}
                onChange={(e) => setCustomHeight(e.target.value)}
                className={styles.numberInput}
                placeholder="Height"
              />
            </div>
          </div>
          <div className={styles.optionGroup}>
            <label className={styles.optionLabel}>
              Scaling:
            </label>
            <Select
              options={[
                { value: 'fit', label: 'Fit' },
                { value: 'fill', label: 'Fill' },
              ]}
              className={styles.actionSelect}
              classNamePrefix="select"
              onChange={(selectedOption) => setScaling(selectedOption.value)}
              value={{
                value: scaling,
                label: scaling.charAt(0).toUpperCase() + scaling.slice(1),
              }}
            />
          </div>
        </div>

        {/* Padding Settings */}
        <div className={styles.subSection}>
          <div className={styles.optionGroupRow}>
            <div className={styles.optionGroup}>
              <label className={styles.optionLabel}>
                Padding Value:
              </label>
              <input
                type="number"
                value={paddingValue}
                onChange={(e) => setPaddingValue(e.target.value)}
                className={styles.numberInput}
                placeholder="Padding value"
              />
            </div>
            <div className={styles.optionGroup}>
              <Select
                options={[
                  { value: '%', label: '%' },
                  { value: 'px', label: 'px' },
                ]}
                className={styles.smallSelect}
                classNamePrefix="select"
                onChange={(selectedOption) => setPaddingUnit(selectedOption.value)}
                value={{ value: paddingUnit, label: paddingUnit }}
              />
            </div>
          </div>
          <div className={styles.optionGroup}>
            <label className={styles.optionLabel}>
              Reference Box:
            </label>
            <Select
              options={[
                { value: 'subject', label: 'Subject' },
                { value: 'original', label: 'Original Image' },
              ]}
              className={styles.actionSelect}
              classNamePrefix="select"
              onChange={(selectedOption) => setReferenceBox(selectedOption.value)}
              value={{
                value: referenceBox,
                label: referenceBox.charAt(0).toUpperCase() + referenceBox.slice(1),
              }}
            />
          </div>
        </div>
      </div>

      {/* Estimated Cost */}
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h4>Estimated Cost</h4>
        </div>
        <p>
          Cost per image: <strong>{costPerImage} credits</strong>
        </p>
        <p>
          Total images to process: <strong>{totalImages}</strong>
        </p>
        <p>
          Estimated total cost: <strong>{estimatedCost} credits</strong>
        </p>
      </div>

      {/* Confirm and Process */}
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h4>4. Confirm and Process</h4>
        </div>
        {processingError && (
          <div className={`${styles.messageBox} ${styles.errorMessage}`}>
            <i className="fas fa-exclamation-circle"></i> {processingError}
          </div>
        )}
        {processingSuccess && (
          <div className={`${styles.messageBox} ${styles.successMessage}`}>
            <i className="fas fa-check-circle"></i> {processingSuccess}
          </div>
        )}
        <button
          type="button"
          className={styles.applyButton}
          onClick={handleProcess}
          disabled={processing}
        >
          {processing ? (
            <>
              <ClipLoader size={16} color={'#ffffff'} loading={processing} />
              Processing...
            </>
          ) : (
            'Start Background Manipulation'
          )}
        </button>
      </div>
    </div>
  );
};

// Define PropTypes for type checking
BackgroundManipulatorPage.propTypes = {
  allIds: PropTypes.array.isRequired,
  filterIds: PropTypes.array.isRequired,
  selectedIds: PropTypes.array.isRequired,
  selectedClient: PropTypes.string.isRequired,
  filters: PropTypes.array.isRequired,
  refreshData: PropTypes.func.isRequired,
  isAllIdsLoading: PropTypes.bool.isRequired,
  isFilterIdsLoading: PropTypes.bool.isRequired,
  allIdsError: PropTypes.string,
  filterIdsError: PropTypes.string,
  onBackgroundManipulationSuccess: PropTypes.func.isRequired, // Add this line
};
export default React.memo(BackgroundManipulatorPage);
