import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import axios from 'axios';
import styles from './AggregatedValues.module.css';
import { FaPlus, FaTimes, FaTrash, FaChevronDown, FaChevronUp, FaEye } from 'react-icons/fa'; // Added FaEye
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { ClipLoader } from 'react-spinners';
import { v4 as uuidv4 } from 'uuid'; // For unique filter IDs

// Import the API URL from the environment variables
const API_URL_AGG_FUNCTIONS = process.env.REACT_APP_API_URL_AGG_FUNCTIONS;

const AggregatedValues = ({ client }) => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newProjectName, setNewProjectName] = useState('');
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loadingColumns, setLoadingColumns] = useState(false);
  const [columns, setColumns] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccessMessage, setSaveSuccessMessage] = useState('');

  const [updatingAll, setUpdatingAll] = useState(false); // New state variable
  const [aggregations, setAggregations] = useState({
    aggregate_function: '',
    column1: '',
    column2: '',
    separator: '',
    level: '',
    attribute_name: '', // Added attribute_name
    ignore_previous_levels: false, // New state variable
  });
  const [filters, setFilters] = useState([]); // New state for filters
  const [error, setError] = useState(null);
  const [filtersExpanded, setFiltersExpanded] = useState(false);

  const [previewData, setPreviewData] = useState([]);
  const [examplePreviewData, setExamplePreviewData] = useState([]); // New state for example preview
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [previewError, setPreviewError] = useState(null);

  const [activeStatus, setActiveStatus] = useState(false);
  const [updatingActive, setUpdatingActive] = useState(false);

  const columnsCache = useRef({});
  const [advancedMode, setAdvancedMode] = useState(false);
  const [sampleValues, setSampleValues] = useState([]);
  const [exampleGroupValue, setExampleGroupValue] = useState('');

  // Request tracking using a ref
  const currentRequestId = useRef(0);

  // Define SQL Aggregate Functions
  const aggregateOptions = [
    { value: 'COUNT', label: 'COUNT' },
    { value: 'COUNT UNIQUE', label: 'COUNT UNIQUE' },
    { value: 'SUM', label: 'SUM' },
    { value: 'AVERAGE', label: 'AVERAGE' },
    { value: 'MAX', label: 'MAX' },
    { value: 'MIN', label: 'MIN' },
    // Add other aggregate functions as needed
  ];

  // Define Logical Operators for Filters
  const logicalOperators = [
    { value: 'AND', label: 'AND' },
    { value: 'OR', label: 'OR' },
  ];

  // Define SQL Operators for Filters
  const filterOperators = [
    { value: '=', label: '=' },
    { value: '!=', label: '!=' },
    { value: '>', label: '>' },
    { value: '<', label: '<' },
    { value: '>=', label: '>=' },
    { value: '<=', label: '<=' },
    { value: 'LIKE', label: 'LIKE' },
    { value: 'IN', label: 'IN' },
    // Add other operators as needed
  ];

  // Custom styles for react-select
  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: '#E0E1EB',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#E0E1EB',
      },
      fontFamily: 'Lexend Deca, sans-serif',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? '#E0E1EB'
        : state.isSelected
        ? '#4758EB'
        : '#fff',
      color: state.isSelected ? '#fff' : '#292B3D',
      fontFamily: 'Lexend Deca, sans-serif',
      '&:hover': {
        backgroundColor: state.isSelected ? '#4758EB' : '#E0E1EB',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#292B3D',
      fontFamily: 'Lexend Deca, sans-serif',
    }),
    menu: (provided) => ({
      ...provided,
      fontFamily: 'Lexend Deca, sans-serif',
      zIndex: 1000,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 1000 }),
  };

  // Function to render custom option labels with colored dots
  const formatOptionLabel = ({ label, active }) => (
    <div className={styles.optionLabel}>
      <span
        className={active ? styles.activeDot : styles.inactiveDot}
        aria-label={active ? 'Active' : 'Inactive'}
      ></span>
      <span className={styles.optionText}>{label}</span>
    </div>
  );

  // Function to reset all relevant states
  const resetStates = () => {
    setColumns([]);
    setAggregations({
      aggregate_function: '',
      column1: '',
      column2: '',
      separator: '',
      level: '',
      attribute_name: '',
      ignore_previous_levels: false,
    });
    setFilters([]); // Reset filters
    setFiltersExpanded(false); // Reset filtersExpanded
    setActiveStatus(false);
    setPreviewData([]);
    setExamplePreviewData([]);
    setAdvancedMode(false);
    setSampleValues([]);
    setExampleGroupValue('');
    setError(null);
    setPreviewError(null);
    
    // Reset unsaved changes and success message
    setHasUnsavedChanges(false);
    setSaveSuccessMessage('');
  };

  const handleView = () => {
    if (!client) {
      return;
    }

    const encodedClient = encodeURIComponent(client);
    const url = `https://storage.googleapis.com/quantum-feed-engine/${encodedClient}/aggregated_values_feed.xml`;

    // Open the URL in a new tab
    window.open(url, '_blank');
  };

  

  const fetchProjects = async (currentSelectedProject = null) => {
    setLoadingProjects(true);
    try {
      const response = await axios.get(
        `${API_URL_AGG_FUNCTIONS}/client-feeds/${encodeURIComponent(
          client
        )}/aggregated-value-projects`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
  
      let projectOptions = response.data.projects.map((proj) => {
        let lastUpdatedAt = null;
        if (proj.last_updated_at) {
          lastUpdatedAt = new Date(proj.last_updated_at);
        }
        return {
          value: proj.id,
          label: proj.aggregated_value_project_name,
          aggregations: proj.aggregations || {},
          active: proj.active || false,
          last_updated_at: lastUpdatedAt,
        };
      });
  
      projectOptions.sort((a, b) => a.label.localeCompare(b.label));
  
      setProjects(projectOptions);
  
      // Maintain the selected project
      if (currentSelectedProject) {
        const updatedSelectedProject = projectOptions.find(
          (proj) => proj.value === currentSelectedProject.value
        );
        setSelectedProject(updatedSelectedProject || null);
      } else {
        setSelectedProject(projectOptions[0] || null);
      }
    } catch (err) {
      console.error('Error fetching projects:', err.response || err.message);
      setError('Failed to load projects. Please try again.');
    } finally {
      setLoadingProjects(false);
    }
  };
  
  useEffect(() => {
    if (saveSuccessMessage) {
      const timer = setTimeout(() => {
        setSaveSuccessMessage('');
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [saveSuccessMessage]);
  

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = ''; // This is necessary for Chrome
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);
  

  useEffect(() => {
    if (!client) return;
  
    // Reset all states when client changes
    resetStates();
  
    fetchProjects();
  }, [client]);
  

  useEffect(() => {
    if (!selectedProject || !client) {
      resetStates();
      setFiltersExpanded(false); // No project selected, collapse filters
      return;
    }
  
    // Increment the request ID
    currentRequestId.current += 1;
    const requestId = currentRequestId.current;
  
    const controller = new AbortController();
    const signal = controller.signal;
  
    const fetchColumns = async () => {
      setLoadingColumns(true);
      setError(null);
      try {
        // Check if columns are already cached for this client
        if (columnsCache.current[client]) {
          if (requestId === currentRequestId.current) {
            setColumns(columnsCache.current[client]);
            // Pre-fill aggregations if available
            setAggregations({
              aggregate_function: selectedProject.aggregations.aggregate_function || '',
              column1: selectedProject.aggregations.column1 || '',
              column2: selectedProject.aggregations.column2 || '',
              separator: selectedProject.aggregations.separator || '',
              level: selectedProject.aggregations.level || '',
              attribute_name: selectedProject.aggregations.attribute_name || '',
              ignore_previous_levels: selectedProject.aggregations.ignore_previous_levels || false, // Include ignore_previous_levels
            });
            setFilters(selectedProject.aggregations.filters || []); // Set filters
            setFiltersExpanded((selectedProject.aggregations.filters || []).length > 0); // Set filtersExpanded

            setActiveStatus(selectedProject.active || false);
            setAdvancedMode(!!(selectedProject.aggregations.separator && selectedProject.aggregations.level));

            if (selectedProject.aggregations.column2) {
              fetchSampleValues(selectedProject.aggregations.column2, requestId);
            }
          }
          return;
        }

        const response = await axios.get(
          `${API_URL_AGG_FUNCTIONS}/client-feeds/${encodeURIComponent(client)}/columns`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            signal, // Pass the AbortController signal
          }
        );

        if (requestId === currentRequestId.current) {
          const fetchedColumns = response.data.columns;
          setColumns(fetchedColumns);
          columnsCache.current[client] = fetchedColumns; // Cache the columns

          // Pre-fill aggregations if available
          setAggregations({
            aggregate_function: selectedProject.aggregations.aggregate_function || '',
            column1: selectedProject.aggregations.column1 || '',
            column2: selectedProject.aggregations.column2 || '',
            separator: selectedProject.aggregations.separator || '',
            level: selectedProject.aggregations.level || '',
            attribute_name: selectedProject.aggregations.attribute_name || '',
            ignore_previous_levels: selectedProject.aggregations.ignore_previous_levels || false, // Include ignore_previous_levels
          });

          const projectFilters = selectedProject.aggregations.filters || [];
          setFilters(projectFilters); // Set filters
          setFiltersExpanded(projectFilters.length > 0); // Set filtersExpanded

          setActiveStatus(selectedProject.active || false);
          setAdvancedMode(!!(selectedProject.aggregations.separator && selectedProject.aggregations.level));

          if (selectedProject.aggregations.column2) {
            fetchSampleValues(selectedProject.aggregations.column2, requestId);
          }
        }
      } catch (err) {
        if (!axios.isCancel(err) && requestId === currentRequestId.current) {
          console.error('Error fetching columns:', err.response || err.message);
          const errorMessage = err.response?.data?.message || 'Failed to load columns. Please try again.';
          setError(errorMessage);
        }
      } finally {
        if (requestId === currentRequestId.current) {
          setLoadingColumns(false);
        }
      }
    };

    fetchColumns();

    return () => {
      controller.abort(); // Cleanup: cancel the request on unmount or selection change
    };
  }, [selectedProject?.value, client]);

  useEffect(() => {
    // Update exampleGroupValue whenever aggregations.separator, aggregations.level, or sampleValues change
    if (aggregations.separator && aggregations.level && sampleValues.length > 0) {
      const firstValue = sampleValues[0];
      const parts = firstValue.split(aggregations.separator);
      const lvl = parseInt(aggregations.level, 10);
      if (isNaN(lvl) || lvl <= 0) {
        setExampleGroupValue('Invalid level');
      } else {
        if (aggregations.ignore_previous_levels) {
          // Extract only the specified level
          const groupValue = parts[lvl - 1] ? parts[lvl - 1].trim() : 'Invalid level';
          setExampleGroupValue(groupValue);
        } else {
          // Include up to the specified level
          const groupParts = parts.slice(0, lvl);
          const groupValue = groupParts.join(` ${aggregations.separator} `);
          setExampleGroupValue(groupValue);
        }
      }
    } else {
      setExampleGroupValue('');
    }
  }, [aggregations.separator, aggregations.level, aggregations.ignore_previous_levels, sampleValues]);

  const handleProjectChange = (selectedOption) => {
    if (hasUnsavedChanges) {
      const confirmDiscard = window.confirm(
        'You have unsaved changes. Are you sure you want to discard them and switch projects?'
      );
      if (!confirmDiscard) {
        // Do not change the project
        return;
      }
    }
  
    setSelectedProject(selectedOption);
    setActiveStatus(selectedOption ? selectedOption.active : false);
    setPreviewData([]); // Clear previous preview data when project changes
    setExamplePreviewData([]); // Clear previous example preview data
    setPreviewError(null);
    setAdvancedMode(false);
    setSampleValues([]);
    setExampleGroupValue('');
  
    const projectFilters = selectedOption?.aggregations.filters || [];
    setFilters(projectFilters); // Set filters from selected project
  
    // Set filtersExpanded based on whether filters exist
    setFiltersExpanded(projectFilters.length > 0);
  
    setAggregations({
      aggregate_function: selectedOption?.aggregations.aggregate_function || '',
      column1: selectedOption?.aggregations.column1 || '',
      column2: selectedOption?.aggregations.column2 || '',
      separator: selectedOption?.aggregations.separator || '',
      level: selectedOption?.aggregations.level || '',
      attribute_name: selectedOption?.aggregations.attribute_name || '',
      ignore_previous_levels:
        selectedOption?.aggregations.ignore_previous_levels || false,
    });
  
    if (selectedOption?.aggregations.column2) {
      fetchSampleValues(
        selectedOption.aggregations.column2,
        currentRequestId.current
      );
    }
  
    // Reset unsaved changes and success message
    setHasUnsavedChanges(false);
    setSaveSuccessMessage('');
  };
  
  

  const handleUpdateAll = async () => {
    setUpdatingAll(true);
    setError(null);
  
    try {
      await axios.post(
        `${API_URL_AGG_FUNCTIONS}/client-feeds/${encodeURIComponent(client)}/aggregated-value-projects/full-data`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      // Re-fetch projects to update the last_updated_at field
      await fetchProjects(selectedProject);
    } catch (err) {
      console.error('Error updating all data:', err.response || err.message);
  
      // Extract the main error message
      const errorMessage =
        err.response?.data?.message || 'Failed to update data.';
  
      // Extract the detailed error messages
      const errorDetails = err.response?.data?.details;
  
      // Combine the main error message with detailed messages
      let fullErrorMessage = errorMessage;
  
      if (errorDetails && Array.isArray(errorDetails)) {
        // Join the detailed messages with line breaks
        const detailsMessage = errorDetails.join('\n');
        fullErrorMessage += '\n' + detailsMessage;
      }
  
      // Set the full error message to display to the user
      setError(fullErrorMessage);
    } finally {
      setUpdatingAll(false);
    }
  };
  
  

  const openModal = () => {
    if (hasUnsavedChanges) {
      const confirmDiscard = window.confirm(
        'You have unsaved changes. Are you sure you want to discard them and add a new project?'
      );
      if (!confirmDiscard) {
        return;
      }
    }
    setNewProjectName('');
    setIsModalOpen(true);
    setError(null); // Clear previous errors when opening the modal
  
    // Reset unsaved changes and success message
    setHasUnsavedChanges(false);
    setSaveSuccessMessage('');
  };
  

  const closeModal = () => {
    setIsModalOpen(false);
    setError(null); // Clear any existing error messages
  };

  const handleAddProject = async (e) => {
    e.preventDefault();
    if (!newProjectName.trim()) {
      setError('Project name cannot be empty.');
      return;
    }

    try {
      const response = await axios.post(
        `${API_URL_AGG_FUNCTIONS}/client-feeds/${encodeURIComponent(client)}/aggregated-value-projects`,
        { aggregated_value_project_name: newProjectName },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const newProject = {
        value: response.data.project.id,
        label: response.data.project.aggregated_value_project_name,
        aggregations: response.data.project.aggregations || {},
        active: response.data.project.active || false,
      };

      setProjects((prevProjects) => [...prevProjects, newProject]);
      setSelectedProject(newProject);
      setActiveStatus(newProject.active);
      resetStates(); // Reset all states to avoid showing old data
      closeModal();
    } catch (err) {
      console.error('Error adding project:', err.response || err.message);
      // Extract the error message from the backend response
      const errorMessage = err.response?.data?.message || 'Failed to add project. Please try again.';
      setError(errorMessage);
    }
  };

  const handleDeleteProject = async (projectId) => {
    // Custom confirmation dialog using react-confirm-alert
    confirmAlert({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete this project?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              await axios.delete(
                `${API_URL_AGG_FUNCTIONS}/client-feeds/${encodeURIComponent(client)}/aggregated-value-projects/${projectId}`,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
                }
              );

              // Remove the project from the state
              setProjects((prevProjects) => prevProjects.filter((proj) => proj.value !== projectId));

              // Clear the selection if the deleted project was selected
              if (selectedProject && selectedProject.value === projectId) {
                setSelectedProject(null);
                resetStates(); // Reset all states to avoid showing old data
              }
            } catch (err) {
              console.error('Error deleting project:', err.response || err.message);
              const errorMessage = err.response?.data?.message || 'Failed to delete project. Please try again.';
              setError(errorMessage);
            }
          },
        },
        {
          label: 'No',
          onClick: () => {
            // Do nothing if user cancels
          },
        },
      ],
    });
  };

  const handleAggregationChange = (type, value) => {
    setAggregations((prevAggregations) => ({
      ...prevAggregations,
      [type]: value,
    }));
  
    setHasUnsavedChanges(true); // Mark as unsaved changes
  
    // If column2 changes, fetch sample values
    if (type === 'column2') {
      if (value) {
        fetchSampleValues(value, currentRequestId.current);
      } else {
        setSampleValues([]);
        setExampleGroupValue('');
      }
    }
  };
  

  const fetchSampleValues = async (column, requestId) => {
    try {
      const response = await axios.get(
        `${API_URL_AGG_FUNCTIONS}/client-feeds/${encodeURIComponent(client)}/sample-values`,
        {
          params: { column },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      // Ensure the response corresponds to the latest request
      if (requestId === currentRequestId.current) {
        setSampleValues(response.data.sample_values);
      }
    } catch (err) {
      console.error('Error fetching sample values:', err.response || err.message);
      // Handle error if needed
    }
  };

  const handleSaveAggregations = async () => {
    if (!selectedProject) return;
  
    try {
      // Build the updated aggregations object including filters
      const updatedAggregations = {
        ...aggregations,
        filters: filters, // Include filters in aggregations
        separator: advancedMode ? aggregations.separator : null,
        level: advancedMode ? aggregations.level : null,
        ignore_previous_levels: advancedMode ? aggregations.ignore_previous_levels : false,
      };
  
      await axios.put(
        `${API_URL_AGG_FUNCTIONS}/client-feeds/${encodeURIComponent(
          client
        )}/aggregated-value-projects/${selectedProject.value}/aggregations`,
        {
          aggregations: updatedAggregations,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      // Update the project in the local state
      setProjects((prevProjects) =>
        prevProjects.map((proj) =>
          proj.value === selectedProject.value
            ? { ...proj, aggregations: updatedAggregations }
            : proj
        )
      );
  
      // Reset unsaved changes flag
      setHasUnsavedChanges(false);
  
      // Show success message
      setSaveSuccessMessage('Aggregations saved successfully!');
    } catch (err) {
      console.error('Error saving aggregations:', err.response || err.message);
      const errorMessage =
        err.response?.data?.message ||
        'Failed to save aggregations. Please try again.';
      setError(errorMessage);
    }
  };
  
  

  const handlePreview = async () => {
    if (!selectedProject) return;
    const { aggregate_function, column1, column2, separator, level, ignore_previous_levels } = aggregations;

    if (!aggregate_function || !column1 || !column2) {
      setPreviewError('Please select aggregate function and both columns before previewing.');
      return;
    }

    setLoadingPreview(true);
    setPreviewError(null);
    setPreviewData([]);
    setExamplePreviewData([]); // Reset example preview data

    try {
      const response = await axios.post(
        `${API_URL_AGG_FUNCTIONS}/client-feeds/${encodeURIComponent(client)}/aggregated-value-projects/${selectedProject.value}/preview`,
        {
          aggregate_function,
          column1,
          column2,
          separator: advancedMode ? separator : null,
          level: advancedMode ? level : null,
          ignore_previous_levels: advancedMode ? ignore_previous_levels : false, // Include the new parameter
          filters: filters, // Include filters in the preview request
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setPreviewData(response.data.preview_data);
      setExamplePreviewData(response.data.example_preview_data); // Set example preview data
    } catch (err) {
      console.error('Error fetching preview data:', err.response || err.message);
      const errorMessage = err.response?.data?.message || 'Failed to fetch preview data. Please try again.';
      setPreviewError(errorMessage);
    } finally {
      setLoadingPreview(false);
    }
  };

  const formatDateTime = (date) => {
    return date.toLocaleString();
  };

  // Handle Active Status Toggle
// Handle Active Status Toggle
const handleToggleActive = async () => {
  if (!selectedProject) return;

  const newActiveStatus = !activeStatus;
  setUpdatingActive(true);
  setError(null);

  try {
    await axios.patch(
      `${API_URL_AGG_FUNCTIONS}/client-feeds/${encodeURIComponent(client)}/aggregated-value-projects/${selectedProject.value}/active`,
      { active: newActiveStatus },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      }
    );

    // Update the local state for the active status
    setActiveStatus(newActiveStatus);

    // Update the selectedProject state
    setSelectedProject((prevSelectedProject) =>
      prevSelectedProject
        ? { ...prevSelectedProject, active: newActiveStatus }
        : prevSelectedProject
    );

    // Update the projects list
    setProjects((prevProjects) =>
      prevProjects.map((proj) =>
        proj.value === selectedProject.value
          ? { ...proj, active: newActiveStatus }
          : proj
      )
    );
  } catch (err) {
    console.error('Error updating active status:', err.response || err.message);
    const errorMessage =
      err.response?.data?.message ||
      'Failed to update active status. Please try again.';
    setError(errorMessage);
  } finally {
    setUpdatingActive(false);
  }
};





  // Functions to handle Filters
  const handleAddFilter = () => {
    const newFilter = {
      id: uuidv4(),
      field: '',
      operator: '',
      value: '',
      connector: 'AND',
    };
    setFilters((prevFilters) => {
      const updatedFilters = [...prevFilters, newFilter];
      // Expand filters when the first filter is added
      if (updatedFilters.length === 1) {
        setFiltersExpanded(true);
      }
      return updatedFilters;
    });
  
    setHasUnsavedChanges(true); // Mark as unsaved changes
  };
  

  const handleRemoveFilter = (id) => {
    setFilters((prevFilters) => {
      const updatedFilters = prevFilters.filter((filter) => filter.id !== id);
      // Collapse filters if no filters remain
      if (updatedFilters.length === 0) {
        setFiltersExpanded(false);
      }
      return updatedFilters;
    });
  
    setHasUnsavedChanges(true); // Mark as unsaved changes
  };
  

  const handleFilterChange = (id, type, value) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) =>
        filter.id === id ? { ...filter, [type]: value } : filter
      )
    );
  
    setHasUnsavedChanges(true); // Mark as unsaved changes
  };
  

  // Function to render the example group value with highlighted level
  const renderExampleGroupValue = () => {
    if (!aggregations.separator || !aggregations.level || !exampleGroupValue) {
      return <p>{exampleGroupValue}</p>;
    }

    const parts = exampleGroupValue
      .split(aggregations.separator)
      .map((part) => part.trim());
    const lvl = parseInt(aggregations.level, 10);

    if (isNaN(lvl) || lvl <= 0) {
      return <p>Invalid level</p>;
    }

    return (
      <p>
        {parts.map((part, index) => {
          // Determine if this part should be highlighted
          const shouldHighlight = aggregations.ignore_previous_levels
            ? true // Highlight all parts when ignore_previous_levels is true
            : index === lvl - 1; // Highlight only the specified level when ignore_previous_levels is false

          return (
            <span key={index}>
              {shouldHighlight ? (
                <span className={styles.highlightedLevel}>{part}</span>
              ) : (
                <span>{part}</span>
              )}
              {index < parts.length - 1 && ` ${aggregations.separator} `}
            </span>
          );
        })}
      </p>
    );
  };

  return (
    <div className={styles.container}>
    {/* Global Spinner Overlay */}
    {loadingProjects && (
      <div className={styles.globalSpinner}>
        <ClipLoader color="#4758EB" size={50} />
      </div>
    )}

    <h2>Aggregated Values</h2>

    {/* Error Message */}
    {error && <p className={styles.error}>{error}</p>}

    {/* Project Selection */}
    <div className={styles.projectSelection}>
      <div className={styles.selectContainer}>
        <Select
          options={projects}
          value={selectedProject}
          onChange={handleProjectChange}
          styles={customSelectStyles}
          formatOptionLabel={formatOptionLabel}
          placeholder="Select Aggregated Value Project"
          isLoading={loadingProjects}
          isClearable
          menuPortalTarget={document.body}
          className={styles.selectDropdown}
          isDisabled={loadingColumns}
        />
      </div>
      {/* Add and Remove Buttons */}
      <div className={styles.buttonGroup}>
        <button
          className={styles.addButton}
          onClick={openModal}
          title="Add New Project"
          aria-label="Add New Project"
          disabled={loadingProjects || loadingColumns}
        >
          <FaPlus />
        </button>
        {selectedProject && (
          <button
            className={styles.removeButton}
            onClick={() => handleDeleteProject(selectedProject.value)}
            title="Delete Project"
            aria-label="Delete Project"
            disabled={loadingProjects || loadingColumns}
          >
            <FaTrash />
          </button>
        )}
      </div>
    </div>

    {/* Active Toggle and New View Button */}
    {selectedProject && (
      <div className={styles.toggleContainer}>
        <button
          className={styles.updateAllButton}
          onClick={handleUpdateAll}
          title="Update All"
          aria-label="Update All"
          disabled={loadingColumns || updatingActive || updatingAll}
        >
          {updatingAll ? (
            <span>
              <ClipLoader color="#fff" size={15} />
            </span>
          ) : (
            'Update All'
          )}
        </button>

        {/* New View Button */}
        <button
          className={styles.updateAllButton} // Reusing the same style
          onClick={handleView}
          title="View Details"
          aria-label="View Details"
          disabled={loadingColumns || updatingActive || updatingAll}
        >
          <FaEye />
        </button>

        {/* Display Last Updated At */}
        {selectedProject.last_updated_at && (
          <span className={styles.lastUpdatedAt}>
            Last updated at {formatDateTime(selectedProject.last_updated_at)}
          </span>
        )}
      </div>
    )}

    {/* Informational Infobox */}
    <div className={styles.infoBox}>
      All active feeds are scheduled to be updated every 4 hours.
    </div>

    {/* Aggregation Configuration */}
      {selectedProject && (
        <div className={styles.aggregationSection}>
          <h3>Configure Aggregation</h3>
          {/* Attribute Name Input - Styled Inline */}
          <div className={styles.inlineInputContainer}>
            <label htmlFor="attributeName"><b>Attribute Name:</b></label>
            <input
              type="text"
              id="attributeName"
              value={aggregations.attribute_name}
              onChange={(e) => handleAggregationChange('attribute_name', e.target.value)}
              placeholder="Enter attribute name"
              className={styles.attributeInput}
            />
            <div className={styles.toggleContainerInline}>
              <label htmlFor="activeToggle">Include in XML feed:</label>
              <Toggle
                id="activeToggle"
                checked={activeStatus}
                onChange={handleToggleActive}
                aria-label="Toggle project active status"
                disabled={updatingActive || loadingColumns || updatingAll} // Disable during update or loading
              />
            </div>
          </div>

          {loadingColumns ? (
            <div className={styles.sectionSpinner}>
              <ClipLoader color="#4758EB" size={30} />
            </div>
          ) : (
            <>
              <div className={styles.aggregationDropdowns}>
                {/* Dropdown 1: Aggregate Function */}
                <div className={styles.dropdownContainer}>
                  <label htmlFor="aggregateFunction">Aggregate Function</label>
                  <Select
                    id="aggregateFunction"
                    options={aggregateOptions}
                    value={
                      aggregations.aggregate_function
                        ? aggregateOptions.find(
                            (option) => option.value === aggregations.aggregate_function
                          )
                        : null
                    }
                    onChange={(option) => handleAggregationChange('aggregate_function', option ? option.value : '')}
                    styles={customSelectStyles}
                    placeholder="Please Select an Aggregate Function"
                    isClearable
                    className={styles.selectDropdown}
                  />
                </div>

                {/* Dropdown 2: Column 1 */}
                <div className={styles.dropdownContainer}>
                  <label htmlFor="column1">Column to Aggregate</label>
                  <Select
                    id="column1"
                    options={columns.map((col) => ({ value: col, label: col }))}
                    value={
                      aggregations.column1
                        ? { value: aggregations.column1, label: aggregations.column1 }
                        : null
                    }
                    onChange={(option) => handleAggregationChange('column1', option ? option.value : '')}
                    styles={customSelectStyles}
                    placeholder="Please Select Column 1"
                    isClearable
                    className={styles.selectDropdown}
                  />
                </div>

                {/* Dropdown 3: Column 2 with Advanced Options */}
                <div className={styles.dropdownContainer}>
                  <label htmlFor="column2">Column to Group By</label>
                  <Select
                    id="column2"
                    options={columns.map((col) => ({ value: col, label: col }))}
                    value={
                      aggregations.column2
                        ? { value: aggregations.column2, label: aggregations.column2 }
                        : null
                    }
                    onChange={(option) => handleAggregationChange('column2', option ? option.value : '')}
                    styles={customSelectStyles}
                    placeholder="Please Select Column 2"
                    isClearable
                    className={styles.selectDropdown}
                  />

                  {/* Advanced Options Toggle */}
                  <div className={styles.advancedToggle}>
                    <button
                      type="button"
                      className={styles.advancedToggleButton}
                      onClick={() => setAdvancedMode(!advancedMode)}
                      aria-expanded={advancedMode}
                      aria-controls="advanced-options-panel"
                      title="Toggle Advanced Options"
                      disabled={loadingColumns} // Disable during loading
                    >
                      {advancedMode ? <FaChevronUp /> : <FaChevronDown />} Advanced Options
                    </button>
                  </div>

                  {/* Advanced Options Panel */}
                  {advancedMode && (
                    <div id="advanced-options-panel" className={styles.advancedOptionsPanel}>
                      {/* Separator and Level Side by Side */}
                      <div className={styles.advancedOptionsRow}>
                        {/* Separator */}
                        <div className={styles.inputContainer}>
                          <label htmlFor="separator">
                            Separator
                          </label>
                          <input
                            type="text"
                            id="separator"
                            value={aggregations.separator}
                            onChange={(e) => handleAggregationChange('separator', e.target.value)}
                            placeholder="Enter separator (e.g., >)"
                          />
                        </div>

                        {/* Level */}
                        <div className={styles.inputContainer}>
                          <label htmlFor="level">
                            Level
                          </label>
                          <input
                            type="number"
                            id="level"
                            value={aggregations.level}
                            onChange={(e) => handleAggregationChange('level', e.target.value)}
                            placeholder="Enter level (e.g., 2)"
                            min="1"
                          />
                        </div>
                      </div>

                      {/* Ignore Previous Levels */}
                      <div className={styles.advancedOptionRow}>
                        <div className={styles.checkboxContainer}>
                          <input
                            type="checkbox"
                            id="ignorePreviousLevels"
                            checked={aggregations.ignore_previous_levels}
                            onChange={(e) => handleAggregationChange('ignore_previous_levels', e.target.checked)}
                          />
                          <label htmlFor="ignorePreviousLevels">Ignore Previous Levels</label>
                          </div>
                      </div>

                      {/* Example Group Value */}
                      <div className={styles.exampleContainer}>
                        {renderExampleGroupValue() && (
                          <>
                            <label>Example Group Value:</label>
                            {renderExampleGroupValue()}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Filters Section */}
              <div className={styles.filtersSection}>
                <div className={styles.filtersHeader}>
                  <h3>Filters</h3>
                  <button
                    type="button"
                    className={styles.filtersToggleButton}
                    onClick={() => setFiltersExpanded(!filtersExpanded)}
                    aria-expanded={filtersExpanded}
                    aria-controls="filters-panel"
                    title="Toggle Filters"
                    disabled={loadingColumns} // Disable during loading
                  >
                    {filtersExpanded ? <FaChevronUp /> : <FaChevronDown />} {filtersExpanded ? 'Hide Filters' : 'Show Filters'}
                  </button>
                </div>

                {/* Filters Panel */}
                {filtersExpanded && (
                  <div id="filters-panel" className={styles.filtersPanel}>
                    {filters.map((filter, index) => (
                      <div key={filter.id} className={styles.filterRow}>
                        {index > 0 && (
                          <div className={styles.connector}>
                            <Select
                              options={logicalOperators}
                              value={logicalOperators.find((op) => op.value === filter.connector)}
                              onChange={(option) =>
                                handleFilterChange(filter.id, 'connector', option ? option.value : 'AND')
                              }
                              styles={customSelectStyles}
                              placeholder="AND/OR"
                              isClearable={false}
                              className={styles.selectDropdown}
                            />
                          </div>
                        )}
                        <div className={styles.filterFields}>
                          <Select
                            options={columns.map((col) => ({ value: col, label: col }))}
                            value={filter.field ? { value: filter.field, label: filter.field } : null}
                            onChange={(option) => handleFilterChange(filter.id, 'field', option ? option.value : '')}
                            styles={customSelectStyles}
                            placeholder="Select Field"
                            isClearable
                            className={styles.selectDropdown}
                          />
                          <Select
                            options={filterOperators}
                            value={filter.operator ? filterOperators.find((op) => op.value === filter.operator) : null}
                            onChange={(option) => handleFilterChange(filter.id, 'operator', option ? option.value : '')}
                            styles={customSelectStyles}
                            placeholder="Select Operator"
                            isClearable
                            className={styles.selectDropdown}
                          />
                          {filter.operator === 'IN' ? (
                            <input
                              type="text"
                              value={filter.value}
                              onChange={(e) => handleFilterChange(filter.id, 'value', e.target.value)}
                              placeholder="Enter comma-separated values"
                              className={styles.filterInput}
                            />
                          ) : (
                            <input
                              type="text"
                              value={filter.value}
                              onChange={(e) => handleFilterChange(filter.id, 'value', e.target.value)}
                              placeholder="Enter value"
                              className={styles.filterInput}
                            />
                          )}
                        </div>
                        <button
                          type="button"
                          className={styles.removeFilterButton}
                          onClick={() => handleRemoveFilter(filter.id)}
                          title="Remove Filter"
                          aria-label="Remove Filter"
                        >
                          <FaTimes />
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      className={styles.addFilterButton}
                      onClick={handleAddFilter}
                      title="Add Filter"
                      aria-label="Add Filter"
                      disabled={loadingColumns} // Disable during loading
                    >
                      <FaPlus />
                    </button>
                  </div>
                )}
              </div>

              {/* Action Buttons */}
              <div className={styles.actionButtons}>
                {/* Save Aggregations Button */}
                <button
                  type="button"
                  className={styles.saveButton}
                  onClick={handleSaveAggregations}
                  disabled={
                    !aggregations.aggregate_function ||
                    !aggregations.column1 ||
                    !aggregations.column2 ||
                    loadingColumns ||
                    !hasUnsavedChanges
                  }
                >
                  {hasUnsavedChanges ? 'Save' : 'Save'}
                </button>
                {/* Preview Button */}
                <button
                  type="button"
                  className={`${styles.previewButton}`}
                  onClick={handlePreview}
                  disabled={
                    !aggregations.aggregate_function ||
                    !aggregations.column1 ||
                    !aggregations.column2 ||
                    loadingPreview ||
                    loadingColumns
                  }
                  title="Preview Aggregated Data"
                  aria-label="Preview Data"
                >
                  {loadingPreview ? (
                    <span>
                      <ClipLoader color="#fff" size={15} />
                    </span>
                  ) : (
                    'Preview'
                  )}
                </button>
              </div>

                {/* Success Message */}
                {saveSuccessMessage && (
                    <p className={styles.successMessage}>{saveSuccessMessage}</p>
                  )}

                  {/* Unsaved Changes Message */}
                  {hasUnsavedChanges && !saveSuccessMessage && (
                    <p className={styles.unsavedChangesMessage}>You have unsaved changes.</p>
                  )}

              {/* Preview Data Section */}
              {(previewData.length > 0 || loadingPreview || examplePreviewData.length > 0) && (
                <>
                  {/* Preview Data */}
                  {previewData.length > 0 && (
                    <div className={styles.previewSection}>
                      <h3>Preview Group</h3>
                      <table className={styles.previewTable}>
                        <thead>
                          <tr>
                            <th>Group Value</th>
                            <th>{`${aggregations.aggregate_function} (${aggregations.column1})`}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {previewData.map((row, index) => (
                            <tr key={index}>
                              <td>{row.group_value}</td>
                              <td>{row.aggregated_value}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {/* Example Preview Data */}
                  {examplePreviewData.length > 0 && (
                    <div className={styles.examplePreviewSection}>
                      <h3>Final Output</h3>
                      <table className={styles.previewTable}>
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Title</th>
                            <th>Group Value</th>
                            <th>{aggregations.attribute_name || 'Counted Group Value'}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {examplePreviewData.map((row, index) => (
                            <tr key={index}>
                              <td>{row.example_id}</td>
                              <td>{row.title}</td>
                              <td>{row.group_value}</td>
                              <td>{row.counted_group_value}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {/* Loading Spinner for Preview */}
                  {loadingPreview && (
                    <div className={styles.sectionSpinner}>
                      <ClipLoader color="#4758EB" size={30} />
                    </div>
                  )}

                  {/* Preview Error Message */}
                  {previewError && <p className={styles.error}>{previewError}</p>}
                </>
              )}
            </>
          )}
        </div>
      )}

      {/* Modal for adding new project */}
      {isModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <button
              className={styles.closeButton}
              onClick={closeModal}
              title="Close"
              aria-label="Close Modal"
            >
              <FaTimes />
            </button>
            <h3>Add New Aggregated Value Project</h3>
            <form onSubmit={handleAddProject} className={styles.form}>
              <label htmlFor="projectName">Project Name</label>
              <input
                type="text"
                id="projectName"
                value={newProjectName}
                onChange={(e) => setNewProjectName(e.target.value)}
                placeholder="Enter project name"
                required
              />
              {error && <p className={styles.error}>{error}</p>}
              <button type="submit" className={styles.submitButton}>
                Add Project
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AggregatedValues;
