// src/components/popups/PopupUpdateCreditBalance.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './css/PopupUpdateCreditBalance.module.css';
import { FaTimes } from 'react-icons/fa';

const PopupUpdateCreditBalance = ({ token, feed, onClose, onUpdate }) => {
  const [currentBalance, setCurrentBalance] = useState(null);
  const [newBalance, setNewBalance] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Fetch current credit_balance on mount
  useEffect(() => {
    const fetchCurrentBalance = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/get-credit-balance`,
          {
            headers: { Authorization: `Bearer ${token}` },
            params: { client_name: feed.client_name },
          }
        );
        setCurrentBalance(response.data.credit_balance);
      } catch (err) {
        console.error('Failed to fetch current balance:', err);
        setError('Failed to fetch current balance.');
      }
    };

    fetchCurrentBalance();
  }, [token, feed.client_name]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    // Validate newBalance
    const parsedBalance = parseFloat(newBalance);
    if (isNaN(parsedBalance) || parsedBalance < 0) {
      setError('Please enter a valid non-negative number.');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/update-credit-balance`,
        {
          client_name: feed.client_name,
          credit_balance: parsedBalance,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setSuccess('Credit balance updated successfully.');
      setCurrentBalance(parsedBalance);
      setNewBalance('');

      // Optionally, trigger a refresh in the parent component
      if (onUpdate) {
        onUpdate();
      }

      // Close the popup after a short delay
      setTimeout(() => {
        onClose();
      }, 1500);
    } catch (err) {
      console.error('Failed to update credit balance:', err);
      setError(
        err.response?.data?.error || 'Failed to update credit balance.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.modalBackdrop} role="dialog" aria-modal="true">
      <div className={styles.modalContent}>
        <button
          className={styles.closeButton}
          onClick={onClose}
          aria-label="Close"
        >
          <FaTimes size={20} />
        </button>
        <h2 id="update-credit-balance-title">Update Credit Balance</h2>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.field}>
            <label htmlFor="currentBalance">Current Balance:</label>
            <span id="currentBalance">
              {currentBalance !== null ? `${currentBalance}` : 'Loading...'}
            </span>
          </div>
          <div className={styles.field}>
            <label htmlFor="newBalance">New Balance:</label>
            <input
              type="number"
              id="newBalance"
              name="newBalance"
              value={newBalance}
              onChange={(e) => setNewBalance(e.target.value)}
              min="0"
              step="0.01"
              required
              aria-required="true"
            />
          </div>
          {error && <p className={styles.error}>{error}</p>}
          {success && <p className={styles.success}>{success}</p>}
          <div className={styles.buttons}>
            <button
              type="submit"
              className={styles.submitButton}
              disabled={loading}
            >
              {loading ? 'Updating...' : 'Update'}
            </button>
            <button
              type="button"
              className={styles.cancelButton}
              onClick={onClose}
              disabled={loading}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PopupUpdateCreditBalance;
