import React, { useState } from 'react';
import axios from 'axios';
import styles from './css/Register.module.css';
import { FaTimes } from 'react-icons/fa';

const Register = ({ onClose, token }) => { // Accept the token prop
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/register`,
        {
          username,
          password,
          admin_password: isAdmin ? adminPassword : null,
        },
        {
          headers: { Authorization: `Bearer ${token}` }, // Include the JWT
        }
      );
      setMessage(response.data.message);
      if (response.status === 201) { // Check for successful creation
        // Optionally, reset the form or perform other actions
        setUsername('');
        setPassword('');
        setAdminPassword('');
        setIsAdmin(false);
        // Optionally, close the modal or refresh the user list
        onClose();
      }
    } catch (err) {
      setMessage(err.response?.data?.message || 'Registration failed.');
    }
  };

  return (
    <div className={styles.registerModal}>
      <div className={styles.registerContainer}>
        <h2>Register New User</h2>
        <form onSubmit={handleSubmit}>
          {/* Username Field */}
          <div className={styles.formGroup}>
            <label htmlFor="username">Username</label>
            <input 
              type="text" 
              id="username"
              placeholder="Username" 
              value={username} 
              onChange={(e) => setUsername(e.target.value)} 
              required 
            />
          </div>
          
          {/* Password Field */}
          <div className={styles.formGroup}>
            <label htmlFor="password">Password</label>
            <input 
              type="password" 
              id="password"
              placeholder="Password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              required 
            />
          </div>
          
          {/* Admin Checkbox */}
          <label className={styles.adminCheckboxLabel} htmlFor="isAdmin">
            Should this account be admin?
          </label>
          <div className={`${styles.formGroup} ${styles.adminCheckboxContainer}`}>
            <input 
              type="checkbox" 
              id="isAdmin"
              checked={isAdmin} 
              onChange={(e) => setIsAdmin(e.target.checked)} 
            />
          </div>
          
          {/* Admin Password Field */}
          {isAdmin && (
            <div className={styles.formGroup}>
              <label htmlFor="adminPassword">Please enter the secret key to create Admin Account:</label>
              <input 
                type="password" 
                id="adminPassword"
                placeholder="Admin Password" 
                value={adminPassword} 
                onChange={(e) => setAdminPassword(e.target.value)} 
                required={isAdmin}
              />
            </div>
          )}
          
          {/* Submit Button */}
          <button type="submit" className={styles.submitButton}>Register</button>
        </form>
        
        {/* Success or Error Message */}
        {message && (
          <p className={`${styles.message} ${message.toLowerCase().includes('success') ? styles.messageSuccess : styles.messageError}`}>
            {message}
          </p>
        )}
        
        {/* Close Button */}
        <button 
          onClick={onClose} 
          className={styles.closeButton} 
          aria-label="Close Register Modal"
        >
          <FaTimes />
        </button>
      </div>
    </div>
  );
};

export default Register;
