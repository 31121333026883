// src/components/popups/PopupAssignClient.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import styles from './css/PopupAssignClient.module.css';
import { FaTimes, FaCheck, FaTrashAlt } from 'react-icons/fa';

const PopupAssignClient = ({ token, clientName, onClose, onUpdate }) => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [linkedUsers, setLinkedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Fetch all users on mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/users`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setUsers(response.data);
        // Identify users who already have the client assigned
        const alreadyLinked = response.data.filter(user =>
          user.linked_clients && user.linked_clients.includes(clientName)
        );
        setLinkedUsers(alreadyLinked);
      } catch (err) {
        console.error('Failed to fetch users:', err);
        setError('Failed to fetch users.');
      }
    };

    fetchUsers();
  }, [token, clientName]);

  // Prepare options for react-select
  const options = users.map(user => ({
    value: user.username,
    label: user.username,
    isDisabled: linkedUsers.some(linkedUser => linkedUser.username === user.username),
  }));

  // Handle assigning clients to selected users
  const handleAssign = async () => {
    if (selectedUsers.length === 0) {
      setError('Please select at least one user to assign.');
      return;
    }

    setLoading(true);
    setError('');
    setSuccess('');

    try {
      // Assign client to each selected user
      const assignPromises = selectedUsers.map(user =>
        axios.post(
          `${process.env.REACT_APP_API_URL}/admin/assign-client`,
          {
            username: user.value,
            client_name: clientName,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
      );

      await Promise.all(assignPromises);

      setSuccess('Clients assigned successfully.');
      setSelectedUsers([]);
      // Refresh linked users
      const updatedUsers = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/users`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setUsers(updatedUsers.data);
      const alreadyLinked = updatedUsers.data.filter(user =>
        user.linked_clients && user.linked_clients.includes(clientName)
      );
      setLinkedUsers(alreadyLinked);

      // Notify parent to refresh data
      if (onUpdate) {
        onUpdate();
      }

      // Close the popup after a short delay
      setTimeout(() => {
        onClose();
      }, 1500);
    } catch (err) {
      console.error('Failed to assign clients:', err);
      setError(
        err.response?.data?.error || 'Failed to assign clients. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  // Handle unassigning a client from a user
  const handleUnassign = async (username) => {
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/unassign-client`,
        {
          username: username,
          client_name: clientName,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setSuccess(`Client unassigned from ${username} successfully.`);
      // Refresh linked users
      const updatedUsers = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/users`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setUsers(updatedUsers.data);
      const alreadyLinked = updatedUsers.data.filter(user =>
        user.linked_clients && user.linked_clients.includes(clientName)
      );
      setLinkedUsers(alreadyLinked);

      // Notify parent to refresh data
      if (onUpdate) {
        onUpdate();
      }
    } catch (err) {
      console.error('Failed to unassign client:', err);
      setError(
        err.response?.data?.error || 'Failed to unassign client. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.modalBackdrop} role="dialog" aria-modal="true" aria-labelledby="assign-client-modal-title">
      <div className={styles.modalContent}>
        <button
          className={styles.closeButton}
          onClick={onClose}
          aria-label="Close Assign Client Popup"
        >
          <FaTimes size={20} />
        </button>
        <h2 id="assign-client-modal-title">Assign Client to Users</h2>
        <div className={styles.assignSection}>
          <label htmlFor="userSelect">Select Users:</label>
          <Select
            id="userSelect"
            isMulti
            options={options}
            value={selectedUsers}
            onChange={setSelectedUsers}
            className={styles.selectInput}
            classNamePrefix="react-select"
            placeholder="Select users to assign"
            aria-label="Select Users"
          />
        </div>
        {error && <p className={styles.error}>{error}</p>}
        {success && <p className={styles.success}>{success}</p>}
        <div className={styles.buttons}>
          <button
            className={styles.assignButton}
            onClick={handleAssign}
            disabled={loading}
            aria-disabled={loading}
          >
            {loading ? 'Assigning...' : 'Assign'}
          </button>
          <button
            className={styles.cancelButton}
            onClick={onClose}
            disabled={loading}
            aria-disabled={loading}
          >
            Cancel
          </button>
        </div>
        {/* Section for Users Already Linked */}
        <div className={styles.linkedUsersSection}>
          <h3>Already Linked Users</h3>
          {linkedUsers.length === 0 ? (
            <p>No users have this client assigned.</p>
          ) : (
            <ul className={styles.linkedUsersList}>
              {linkedUsers.map(user => (
                <li key={user.username} className={styles.linkedUserItem}>
                  <span className={styles.username}>{user.username}</span>
                  <button
                    className={styles.unassignButton}
                    onClick={() => handleUnassign(user.username)}
                    disabled={loading}
                    aria-label={`Unassign client from ${user.username}`}
                  >
                    <FaTrashAlt />
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default PopupAssignClient;
