import React, { useEffect, useState, useRef } from 'react';
import { ClipLoader } from 'react-spinners';
import { FaTag, FaInfoCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';
import styles from './css/PopupImport.module.css';

// Define group prefixes and trailing order outside the component to prevent redefinition on every render
const groupPrefixes = [
  'image_link',
  ...Array.from({ length: 10 }, (_, i) => `additional_image_link_${String(i + 1).padStart(2, '0')}`),
  ...Array.from({ length: 10 }, (_, i) => `lifestyle_image_link_${String(i + 1).padStart(2, '0')}`),
  ...Array.from({ length: 10 }, (_, i) => `custom_image_link_${String(i + 1).padStart(2, '0')}`),
];

const trailingOrder = ['primary', 'uncropped', 'background', 'upload', 'import'];

/**
 * Extracts the trailing part of the key after the last underscore.
 * @param {string} key 
 * @returns {string}
 */
const getTrailing = (key) => {
  return key.substring(key.lastIndexOf('_') + 1);
};

/**
 * Formats a group prefix into a human-readable label.
 * @param {string} prefix 
 * @returns {string}
 */
const formatGroupLabel = (prefix) => {
  // Replace underscores with spaces and capitalize words
  const words = prefix.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1));
  // If the prefix includes a number (e.g., additional_image_link_01), separate it
  const numberMatch = prefix.match(/_(\d{2})$/);
  if (numberMatch) {
    const number = numberMatch[1];
    return `${words.slice(0, -1).join(' ')} ${number}`;
  }
  return words.join(' ');
};

const PopupImport = ({ 
  isOpen, 
  onClose, 
  client,    
  id,        
  columnName,       // Add the columnName prop here
  onImportSuccess,
  imageUrl
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null); // Track selected image
  const [inputId, setInputId] = useState(id); // State for input field

  const [importing, setImporting] = useState(false); // New state for import loading
  const [importError, setImportError] = useState(null); // New state for import errors
  const [importSuccess, setImportSuccess] = useState(false); // New state for import success

  const popupRef = useRef(null);
  const inputRef = useRef(null);

  // Retrieve the API base URL from environment variables
  const API_URL_IMAGE = process.env.REACT_APP_API_URL_IMAGE;

  // Fetch data when popup is opened or when id changes
  useEffect(() => {
    if (isOpen) {
      setInputId(id); // Initialize inputId with the passed id
      fetchData(id);   // Fetch data with the original id
      setSelectedImage(null); // Reset selection when popup opens
      setImportError(null);
      setImportSuccess(false);
      // Prevent body from scrolling when popup is open
      document.body.style.overflow = 'hidden';
    } else {
      // Restore body scrolling when popup is closed
      document.body.style.overflow = 'auto';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, id]);

  /**
   * Fetch data from the backend with the provided id.
   * @param {string|number} currentId - The id to use for fetching data.
   */
  const fetchData = async (currentId) => {
    if (!client || !currentId) {
      setError('Client name or ID is missing.');
      setData([]);
      return;
    }

    setLoading(true);
    setError(null);
    setData([]);

    try {
      const response = await fetch(
        `${API_URL_IMAGE}/api/get-image-links?client_name=${encodeURIComponent(client)}&import_id=${encodeURIComponent(id)}&id=${encodeURIComponent(currentId)}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch data');
      }

      const result = await response.json();
      setData(result); // result is an array of objects
    } catch (err) {
      setError(err.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  // Handle popup close and reset inputId
  const handleClose = () => {
    onClose();
    setInputId(id); // Reset inputId to original id when closing
    setImportError(null);
    setImportSuccess(false);
  };

  // Click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen) {
        // If the popupRef exists and the clicked target is not inside it
        if (popupRef.current && !popupRef.current.contains(event.target)) {
          // Additionally, check if the input is focused
          if (inputRef.current && document.activeElement === inputRef.current) {
            // Do not close if the input is focused
            return;
          }
          handleClose();
        }
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  // Handle input field blur event
  const handleInputBlur = () => {
    // Always fetch data, even if inputId === id
    fetchData(inputId);
  };

  /**
   * Function to handle the import action.
   * Sends a POST request to the Flask backend with the required data.
   */
  const handleImport = async () => {
    if (!selectedImage) {
      setImportError('No image selected for import.');
      return;
    }
  
    const payload = {
      item_id: id,                    // Use the original id prop
      column_name: columnName,        // From props
      client: client,                 // From props
      public_url: selectedImage,      // From state
    };
  
    setImporting(true);
    setImportError(null);
    setImportSuccess(false);
  
    try {
      const response = await fetch(`${API_URL_IMAGE}/api/replace-import-image`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Include Authorization header if your backend requires authentication
          'Authorization': `Bearer ${localStorage.getItem('token')}`, // Optional
        },
        body: JSON.stringify(payload),
      });
  
      const result = await response.json();
  
      if (!response.ok) {
        // Handle errors returned by the backend
        setImportError(result.error || 'Failed to import image.');
        console.error('Import Error:', result.error);
        return;
      }
  
      // Successful import
      console.log(result.message);
      setImportSuccess(true);
      onImportSuccess && onImportSuccess(selectedImage);
      handleClose(); // Close the popup
    } catch (error) {
      console.error('Import Error:', error);
      setImportError(error.message || 'An unexpected error occurred.');
    } finally {
      setImporting(false);
    }
  };

  // Don't render the popup if it's not open
  if (!isOpen) return null;

  /**
   * Formats a key by replacing underscores with spaces and capitalizing words.
   * @param {string} key 
   * @returns {string}
   */
  const formatLabel = (key) => {
    return key
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  // Function to handle image selection
  const handleImageSelect = (image) => {
    setSelectedImage(image);
  };

  // Function to render the data with grouping and ordering
  const renderData = () => {
    if (loading) {
      return (
        <div className={styles.loaderContainer}>
          <ClipLoader size={50} color="#123abc" loading={loading} />
        </div>
      );
    }

    if (error) {
      return <p className={styles.errorText}>Error: {error}</p>;
    }

    if (data && data.length > 0) {
      // Collect all images into a single array
      const allImages = [];

      data.forEach((sectionData) => {
        Object.entries(sectionData).forEach(([key, url]) => {
          if (!url) return; // Skip empty URLs
          allImages.push({ key, url });
        });
      });

      return (
        <div className={styles.groupsContainer}>
          {groupPrefixes.map((prefix) => {
            // Filter images that belong to the current group
            const images = allImages.filter(img => img.key.startsWith(`${prefix}_`))
              .sort((a, b) => {
                const trailingA = getTrailing(a.key);
                const trailingB = getTrailing(b.key);
                return trailingOrder.indexOf(trailingA) - trailingOrder.indexOf(trailingB);
              });

            if (images.length === 0) return null; // Skip groups with no images

            return (
              <div key={prefix} className={styles.imageGroup}>
                <h3 className={styles.groupTitle}>{formatGroupLabel(prefix)}</h3>
                <div className={styles.imagesRow}>
                  {images.map(({ key, url }) => {
                    const [mainKey, trailingKey] = key.split(/_(?=[^_]+$)/); // Split only on the last underscore
                    return (
                      <div
                        className={`${styles.imageWrapper} ${selectedImage === url ? styles.selected : ''}`}
                        key={key}
                        onClick={() => handleImageSelect(url)}
                        role="button"
                        tabIndex={0}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            handleImageSelect(url);
                          }
                        }}
                        aria-pressed={selectedImage === url}
                        aria-label={`Select ${formatLabel(mainKey)}${trailingKey ? ` ${formatLabel(trailingKey)}` : ''}`}
                      >
                        <img 
                          src={url} 
                          alt={`${formatLabel(mainKey)}${trailingKey ? ` ${formatLabel(trailingKey)}` : ''}`} 
                          className={styles.image}
                          loading="lazy"
                          onError={(e) => { e.target.onerror = null; e.target.src = '/assets/images/placeholder.png'; }}
                        />
                        <div className={styles.labelsContainer}>
                          <p className={styles.imageMainLabel}>{formatLabel(mainKey)}</p>
                          {trailingKey && <p className={styles.imageSubLabel}>{formatLabel(trailingKey)}</p>}
                        </div>
                        {selectedImage === url && <div className={styles.checkmark} aria-hidden="true">&#10003;</div>}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    return <p>No data available.</p>;
  };

  return (
    <div
      className={styles.popupOverlay}
      role="dialog"
      aria-modal="true"
      aria-labelledby="popup-import-title"
    >
      <div ref={popupRef} className={styles.popupContent}>
        {/* Popup Header */}
        <header className={styles.popupHeader}>
          <h2 id="popup-import-title" className={styles.title}>Import Image</h2>
        </header>

        {/* Image and ID */}
        <div className={styles.imageAndIdContainer}>
          {imageUrl && (
            <div className={styles.currentImageContainer}>
              <img src={imageUrl} alt="Current Image" className={styles.currentImage} />
            </div>
          )}
          <div className={styles.idBadge}>
            <FaTag className={styles.idIcon} aria-hidden="true" />
            <span className={styles.idText}>ID: {id}</span>
            {/* Tooltip for ID Badge */}
            <div className={styles.tooltipContainer}>
              <FaInfoCircle className={styles.infoIcon} aria-label="Information about ID badge" />
              <span className={styles.tooltipText}>
                This is the ID you are importing to.
              </span>
            </div>
          </div>
        </div>

        {/* Input Field */}
        <div className={styles.inputContainer}>
          <label htmlFor="import-id-input" className={styles.inputLabel}>
            Import images from ID:
          </label>
          <input
            ref={inputRef}
            id="import-id-input"
            type="text"
            value={inputId}
            onChange={(e) => setInputId(e.target.value)}
            onBlur={handleInputBlur}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                fetchData(inputId);
                setSelectedImage(null);
                inputRef.current.blur();
              }
            }}
            className={styles.idInput}
            aria-label="Import ID"
          />
          {/* Tooltip for Input Field */}
          <div className={styles.tooltipContainer}>
            <FaInfoCircle className={styles.infoIcon} aria-label="Information about Import ID input" />
            <span className={styles.tooltipText}>
              Enter an ID to fetch and display the corresponding images. Changing this ID will update the image selection.
            </span>
          </div>
        </div>

        {/* Popup Body */}
        <div className={styles.popupBody}>
          {renderData()}
        </div>

        {/* Display import errors or success messages */}
        {importError && <p className={styles.errorText}>Import Error: {importError}</p>}
        {importSuccess && <p className={styles.successText}>Image imported successfully!</p>}

        {/* Popup Footer */}
        <footer className={styles.popupFooter}>
          <button
            className={styles.importButton}
            onClick={handleImport}
            disabled={!selectedImage || importing}
            aria-disabled={!selectedImage || importing}
          >
            {importing ? 'Importing...' : 'Import'}
          </button>
        </footer>
      </div>
    </div>
  );
};

// Define PropTypes for better type checking
PopupImport.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  client: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  columnName: PropTypes.string.isRequired, // Corrected prop name
  onImportSuccess: PropTypes.func,
  imageUrl: PropTypes.string, // Added imageUrl prop
};

export default PopupImport;
