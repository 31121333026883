// src/components/ClientDashboard.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from './css/ClientDashboard.module.css';
import { 
  FaSignOutAlt 
} from 'react-icons/fa';
import { 
  MdTextFields, 
  MdImage, 
  MdVideoLibrary, 
  MdDescription, 
  MdListAlt,
  MdTune
} from 'react-icons/md';

const ClientDashboard = ({ token, clientNameProp }) => {
  const [clientName, setClientName] = useState(clientNameProp || '');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClientName = async () => {
      if (!clientNameProp) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/config`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setClientName(response.data.client_name);
        } catch (err) {
          console.error(err);
        }
      }
    };
    fetchClientName();
  }, [token, clientNameProp]);

  const handleLogout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/logout`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Logout successful');
    } catch (err) {
      console.error('Error logging out', err);
    }
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <div>
      <div className={styles.topBar}>
        <button 
          onClick={handleLogout} 
          className={styles.logoutBtn} 
          title="Logout" 
          aria-label="Logout"
        >
          <FaSignOutAlt size={20} />
        </button>
        <div className={styles.headerTextContainer}>
          <span className={styles.feedViewerVersion}>Quantum Feed Engine - Dashboard</span>
          <span className={styles.headerSubtitle}>Dashboard overview</span>
        </div>
        <img
          src="https://storage.googleapis.com/quantum-feed-engine/workbench/application-images/3_down.png"
          alt="Header"
          className={styles.headerImage}
        />
      </div>

      <div className={styles.dashboardContent}>
        <div className={styles.sectionContainer}>
          <h3>Feed Management</h3>
          <p>
            Feed Management optimizes your product data feeds by refining text, images, and videos. Enhance product visibility and engagement across platforms, leading to better search rankings and increased sales.
          </p>
          <div className={styles.buttonGroup}>
            <button 
              className={styles.dashboardBtn} 
              onClick={() => navigate('/text-generation')}
            >
              <MdTextFields size={20} className={styles.icon} /> Text Attributes Optimization
            </button>
            <button 
              className={styles.dashboardBtn} 
              onClick={() => navigate('/image-generation')}
            >
              <MdImage size={20} className={styles.icon} /> Image Attributes Optimization
            </button>
            <button 
              className={styles.dashboardBtn} 
              onClick={() => navigate('/custom-attributes')}
            >
              <MdTune  size={20} className={styles.icon} /> Custom Attributes
            </button>
            <button
              className={styles.dashboardBtn}
              onClick={() => navigate('/video-generation')}
              disabled
              title="Video Optimization is currently unavailable."
            >
              <MdVideoLibrary size={20} className={styles.icon} /> Video Attributes (In dev)
            </button>
          </div>
        </div>
        <div className={styles.sectionContainer}>
          <h3>Website Management</h3>
          <p>
            Website Management optimizes your core website pages like PDP and PLP. Improve user experience, increase engagement, and boost conversion rates by refining your site’s structure and content.
          </p>
          <div className={styles.buttonGroup}>
            <button
              className={styles.dashboardBtn}
              onClick={() => navigate('/pdp-optimization')}
              disabled
              title="PDP Optimization is currently unavailable."
            >
              <MdDescription size={20} className={styles.icon} /> PDP Optimization
            </button>
            <button
              className={styles.dashboardBtn}
              onClick={() => navigate('/plp-optimization')}
              disabled
              title="PLP Optimization is currently unavailable."
            >
              <MdListAlt size={20} className={styles.icon} /> PLP Optimization
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDashboard;
