import React, { useState, useMemo } from 'react';
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';
import styles from './css/PopupTextBulkActions.module.css';

// Define custom styles for React Select
const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? '#4758EB' : '#E0E1EB',
    boxShadow: state.isFocused ? '0 0 0 1px #4758EB' : 'none',
    '&:hover': {
      borderColor: '#4758EB',
    },
    borderRadius: '4px',
    fontFamily: 'Lexend Deca, sans-serif',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? '#4758EB'
      : state.isFocused
        ? '#E0E1EB'
        : '#fff',
    color: state.isSelected ? '#fff' : '#292B3D',
    cursor: 'pointer',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#292B3D',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#999',
  }),
};

const PopupTextBulkActions = ({
  showBulkActionsPopup,
  handleBulkActionsOverlayClick,
  bulkActionType,
  setBulkActionType,
  qfeColumns,
  fieldSelection,
  handleFieldSelectionChange,
  selectAllFields,
  deselectAllFields,
  generationScope,
  setGenerationScope,
  selectedRowIds,
  totalItems,
  totalItemsAll,
  promptConfigurations,
  selectedPromptConfiguration,
  handlePromptConfigurationChange,
  handleConfirmAndGenerate,
  currentBalance,
  cost,
  stateFieldSelection,
  handleStateFieldSelectionChange,
  stateUpdateChoice,
  setStateUpdateChoice,
  stateUpdateScope,
  setStateUpdateScope,
  selectedRowCount,
  isProcessing,
  handleStateUpdate,
  setShowBulkActionsPopup,
  setStateFieldSelection,
  jobStatus,
  jobProgress, // Added prop for detailed progress data
  handleCancelJob, // Added prop for cancelling job
  isCanceling,
}) => {
  // Initialize Hooks unconditionally
  const [fieldSearch, setFieldSearch] = useState('');
  const [isStartingJob, setIsStartingJob] = useState(false);

  React.useEffect(() => {
    if (jobProgress && jobProgress.status === 'running') {
      setIsStartingJob(false);
    }
  }, [jobProgress]);


  const filteredQfeColumns = useMemo(() => {
    if (!fieldSearch.trim()) return qfeColumns;
    return qfeColumns.filter(col =>
      col.toLowerCase().includes(fieldSearch.toLowerCase().trim())
    );
  }, [fieldSearch, qfeColumns]);

  // Handle Select All toggle for Generate Mode
  const handleToggleSelectAllFields = () => {
    if (fieldSelection.length === qfeColumns.length) {
      deselectAllFields();
    } else {
      selectAllFields();
    }
  };

  // Handle Select All toggle for State Update Mode
  const handleToggleSelectAllStateFields = () => {
    if (stateFieldSelection.length === qfeColumns.length) {
      setStateFieldSelection([]);
    } else {
      setStateFieldSelection(qfeColumns);
    }
  };

  // If the popup should not be shown, return null early
  if (!showBulkActionsPopup) return null;

  const isGenerateMode = bulkActionType === 'generate';
  const isStateMode = bulkActionType === 'state';


  
  const allFieldsSelected = fieldSelection.length === qfeColumns.length;
  const allStateFieldsSelected = stateFieldSelection.length === qfeColumns.length;

  // Prepare options for React Select
  const promptOptions = promptConfigurations.map(config => ({
    value: config.combination_id,
    label: config.combination_name,
  }));

  // Find the selected option
  const selectedPromptOption = promptOptions.find(option => option.value === selectedPromptConfiguration) || null;

  return (
    <div className={styles.popupOverlay} onClick={handleBulkActionsOverlayClick}>
      <div className={styles.popupInner} onClick={e => e.stopPropagation()}>
        <h2 className={styles.title}>Bulk Actions</h2>

        {/* Mode Toggle */}
        <div className={styles.segmentedControl}>
          <button
            className={`${styles.segmentButton} ${isGenerateMode ? styles.activeSegment : ''}`}
            onClick={() => setBulkActionType('generate')}
            disabled={isProcessing}
          >
            Generate QFE Fields
          </button>
          <button
            className={`${styles.segmentButton} ${isStateMode ? styles.activeSegment : ''}`}
            onClick={() => setBulkActionType('state')}
            disabled={isProcessing}
          >
            Change Status
          </button>
        </div>

        {isGenerateMode && (
          <>
            <p className={styles.description}>
              Select the QFE fields you want to generate, choose the scope, and select a prompt configuration.
            </p>

            {/* Field Selection */}
            <div className={styles.fieldSelectionSection}>
              <div className={styles.fieldsHeader}>
                <h3 className={styles.subTitle}>Select QFE Fields</h3>
                <div className={styles.fieldsControls}>
                  <button
                    className={styles.selectAllButton}
                    onClick={handleToggleSelectAllFields}
                    aria-label={allFieldsSelected ? "Deselect all QFE fields" : "Select all QFE fields"}
                  >
                    {allFieldsSelected ? 'Deselect All' : 'Select All'}
                  </button>

                  <span className={styles.selectedCount}>{fieldSelection.length} selected</span>
                </div>
              </div>

              <div className={styles.fieldSearchContainer}>
                <input
                  type="text"
                  placeholder="Search fields..."
                  value={fieldSearch}
                  onChange={e => setFieldSearch(e.target.value)}
                  className={styles.fieldSearchInput}
                  aria-label="Search QFE fields"
                />
              </div>

              <div className={styles.fieldsGrid}>
                {filteredQfeColumns.length > 0 ? (
                  filteredQfeColumns.map(col => (
                    <label key={col} className={styles.fieldItem}>
                      <input
                        type="checkbox"
                        checked={fieldSelection.includes(col)}
                        onChange={() => handleFieldSelectionChange(col)}
                        aria-label={`Select ${col}`}
                      />
                      <span className={styles.fieldName} title={col}>
                        {col}
                      </span>
                    </label>
                  ))
                ) : (
                  <div className={styles.noResults}>No fields match your search.</div>
                )}
              </div>
            </div>

            {/* Scope Selection */}
            <div className={styles.scopeSection}>
              <h3 className={styles.subTitle}>
                Select Scope
              </h3>
              <div className={styles.scopeSelector}>
                <button
                  className={`${styles.scopeButton} ${generationScope === 'selectedIds' ? styles.activeScope : ''}`}
                  onClick={() => setGenerationScope('selectedIds')}
                >
                  Selected IDs ({selectedRowIds.length})
                </button>
                <button
                  className={`${styles.scopeButton} ${generationScope === 'filteredIds' ? styles.activeScope : ''}`}
                  onClick={() => setGenerationScope('filteredIds')}
                >
                  Filtered IDs ({totalItems})
                </button>
                <button
                  className={`${styles.scopeButton} ${generationScope === 'allIds' ? styles.activeScope : ''}`}
                  onClick={() => setGenerationScope('allIds')}
                >
                  All IDs ({totalItemsAll})
                </button>
              </div>
            </div>

            {/* Prompt Configuration */}
            <div className={styles.promptSection}>
              <h3 className={styles.subTitle}>Select Prompt Configuration</h3>
              <Select
                styles={customSelectStyles}
                options={promptOptions}
                value={selectedPromptOption}
                onChange={(selectedOption) => handlePromptConfigurationChange(selectedOption ? selectedOption.value : '')}
                placeholder="Select a configuration"
                isClearable
                aria-label="Select prompt configuration"
              />
            </div>

            {/* Progress Information */}
            {jobProgress && jobProgress.status === 'running' && (
              <div className={styles.progressSection}>
                <button
                  className={styles.cancelJobXButton}
                  onClick={handleCancelJob}   // Use parent's handler
                  disabled={isCanceling}      // Same state
                  aria-label="Cancel Generation Job"
                  title="Cancel Job"
                >
                  {isCanceling ? <ClipLoader size={10} color="#fff" /> : '×'}
                </button>

                <h4 className={styles.progressTitle}>Generation Progress</h4>
                
                {/* Progress Bar */}
                <div className={styles.progressBarContainer} aria-label="Progress Bar">
                  <div
                    className={styles.progressBar}
                    style={{
                      width: jobProgress.total_items > 0
                        ? `${(jobProgress.items_processed / jobProgress.total_items) * 100}%`
                        : '0%',
                    }}
                    aria-valuenow={
                      jobProgress.total_items > 0
                        ? (jobProgress.items_processed / jobProgress.total_items) * 100
                        : 0
                    }
                    aria-valuemin="0"
                    aria-valuemax="100"
                    role="progressbar"
                  ></div>
                </div>
                
                {/* Progress Details */}
                <div className={styles.progressDetails}>
                  <span>{jobProgress.items_processed} / {jobProgress.total_items} items processed</span>
                  <span>Status: {jobProgress.status.charAt(0).toUpperCase() + jobProgress.status.slice(1)}</span>
                  {jobProgress.message && <span>Message: {jobProgress.message}</span>}
                </div>
              </div>
            )}

            {/* Action Buttons */}
            <div className={styles.actionButtons}>
              <button
                className={styles.primaryActionButton}
                onClick={() => {
                  // Immediately show "Starting job..." and disable the button
                  setIsStartingJob(true);
                  handleConfirmAndGenerate();
                }}
                disabled={
                  isStartingJob ||  // Newly added disable condition
                  fieldSelection.length === 0 ||
                  !selectedPromptConfiguration ||
                  (generationScope === 'selectedIds' && selectedRowIds.length === 0) ||
                  (generationScope === 'filteredIds' && totalItems === 0) ||
                  (generationScope === 'allIds' && totalItemsAll === 0) ||
                  currentBalance < cost ||
                  jobStatus === 'running'
                }
              >
              {isStartingJob ? (
                  <span className={styles.buttonContent}>
                    <ClipLoader size={16} color="#fff" />
                    Starting job...
                  </span>
                ) : currentBalance < cost ? (
                  'Not enough credits'
                ) : (
                  'Generate'
                )}
              </button>
            </div>
          </>
        )}

        {isStateMode && (
          <>
            <p className={styles.description}>
              Select the fields whose items you want to activate or deactivate and choose the scope.
            </p>

            <div className={styles.fieldSelectionSection}>
              <div className={styles.fieldsHeader}>
                <h3 className={styles.subTitle}>Select Fields for State Update</h3>
                <div className={styles.fieldsControls}>
                  <button
                    className={styles.selectAllButton}
                    onClick={handleToggleSelectAllStateFields}
                    aria-label={allStateFieldsSelected ? "Deselect all fields for state update" : "Select all fields for state update"}
                    disabled={isProcessing}
                  >
                    {allStateFieldsSelected ? 'Deselect All' : 'Select All'}
                  </button>
                  <span className={styles.selectedCount}>{stateFieldSelection.length} selected</span>
                </div>
              </div>

              <div className={styles.fieldsGrid}>
                {qfeColumns.length > 0 ? (
                  qfeColumns.map(col => (
                    <label key={col} className={styles.fieldItem}>
                      <input
                        type="checkbox"
                        checked={stateFieldSelection.includes(col)}
                        onChange={() => handleStateFieldSelectionChange(col)}
                        disabled={isProcessing}
                        aria-label={`Select ${col} for state update`}
                      />
                      <span className={styles.fieldName} title={col}>
                        {col}
                      </span>
                    </label>
                  ))
                ) : (
                  <div className={styles.noResults}>No fields available.</div>
                )}
              </div>
            </div>

            {/* Scope Selection */}
            <div className={styles.scopeSection}>
              <h3 className={styles.subTitle}>
                Select Scope
              </h3>
              <div className={styles.scopeSelector}>
                <button
                  className={`${styles.scopeButton} ${stateUpdateScope === 'selectedIds' ? styles.activeScope : ''}`}
                  onClick={() => setStateUpdateScope('selectedIds')}
                  disabled={isProcessing}
                >
                  Selected IDs ({selectedRowCount})
                </button>
                <button
                  className={`${styles.scopeButton} ${stateUpdateScope === 'filteredIds' ? styles.activeScope : ''}`}
                  onClick={() => setStateUpdateScope('filteredIds')}
                  disabled={isProcessing}
                >
                  Filtered IDs ({totalItems})
                </button>
                <button
                  className={`${styles.scopeButton} ${stateUpdateScope === 'allIds' ? styles.activeScope : ''}`}
                  onClick={() => setStateUpdateScope('allIds')}
                  disabled={isProcessing}
                >
                  All IDs ({totalItemsAll})
                </button>
              </div>
            </div>

            {/* Enhanced Smaller Toggle for Select State */}
            <div className={styles.stateChoiceSection}>
              <h3 className={styles.subTitle}>Select State</h3>
              <div className={styles.smallToggleGroup}>
                <button
                  className={`${styles.smallToggleButton} ${stateUpdateChoice === 'active' ? styles.activeSmallToggle : ''}`}
                  onClick={() => setStateUpdateChoice('active')}
                  disabled={isProcessing}
                  aria-label="Activate selected fields"
                >
                  Activate
                </button>
                <button
                  className={`${styles.smallToggleButton} ${stateUpdateChoice === 'draft' ? styles.activeSmallToggle : ''}`}
                  onClick={() => setStateUpdateChoice('draft')}
                  disabled={isProcessing}
                  aria-label="Deactivate selected fields"
                >
                  Deactivate
                </button>
              </div>
            </div>

            {/* Action Buttons */}
            <div className={styles.actionButtons}>
              <button
                className={styles.primaryActionButton}
                onClick={handleStateUpdate}
                disabled={
                  stateFieldSelection.length === 0 ||
                  isProcessing ||
                  (stateUpdateScope === 'selectedIds' && selectedRowCount === 0)
                }
              >
                {stateUpdateChoice === 'active' ? 'Activate' : 'Deactivate'}{' '}
                {stateUpdateScope === 'selectedIds'
                  ? `${selectedRowCount} Items`
                  : stateUpdateScope === 'filteredIds'
                    ? `${totalItems} Items`
                    : `${totalItemsAll} Items`}
              </button>
              <button
                className={styles.secondaryActionButton}
                onClick={() => setShowBulkActionsPopup(false)}
                disabled={isProcessing}
              >
                Close
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PopupTextBulkActions;
